/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useCallback, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import AvatarLabel from '@/Components/AvatarLabel';
import ButtonCopyLink from '@/Components/ButtonCopyLink';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import ModalCenter from '@/Components/Modals/Center';
import PopoverRolesInput from '@/Components/PopoverRolesInput';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInputTags from '@/Components/TextInputTags';
import { fetcher } from '@/Services/axios';
import type { ExternalUser, User } from '@/Types';
import type { ProjectProps } from '@/Types/projects';
import { triggerGTMEvent } from '@/Utils/gtm';

import RequestAccessModal from './RequestAccessModal';
import TransferOwnershipModal from './TransferOwnershipModal';
import UnsavedChangesPopup from './UnsavedChangesPopup';

interface UserPayload {
  id: number;
  email: string;
  roles: string[];
}

interface MainForm {
  route: string | undefined;
  routeParams: Record<string, string>;
  members: UserPayload[];
  external_members: ExternalUser[];
}

const UpdateFormModal: React.FC<{
  user: User;
  isOpen: boolean;
  onClose: () => void;
  project: ProjectProps;
  initialUsers: User[];
  appUrl: string;
}> = ({ isOpen, onClose, project, initialUsers, appUrl, user }) => {
  const [isClearTags, setIsClearTags] = useState<boolean>(false);
  const [isClearRoles, setIsClearRoles] = useState<boolean>(false);
  const [validatedUsers, setValidatedUsers] = useState<User[]>(project.users);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [isOpenModalRequestAccess, setIsOpenModalRequestAccess] =
    useState<boolean>(false);
  const [requestAccessRoles, setRequestAccessRoles] = useState<string[]>([]);
  const [validatedExternalUsers, setValidatedExternalUsers] = useState<
    ExternalUser[]
  >(project.requested_users);
  const closeModalRequestAccess = () => setIsOpenModalRequestAccess(false);
  const [openPopupOwnership, setOpenPopupOwnership] = useState(false);
  const [excludedUsers, setExcludedUsers] = useState<User[]>([
    project.owner,
    ...validatedUsers,
    ...validatedExternalUsers.map((user: ExternalUser): User => {
      return {
        id: 0,
        email: user.email,
        avatar: '',
        avatar_url: '',
        email_verified_at: '',
        created_at: '',
        updated_at: '',
        name: '',
        roles: user.roles,
        whatsapp_number: '',
        status: '',
      };
    }),
  ]);
  const [popupUnsave, setPopupUnsave] = useState<boolean>(false);

  // form update project
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<MainForm>({
    defaultValues: {
      route: undefined,
      routeParams: {},
      members: project.users.map((user) => ({
        id: user.id,
        email: user.email,
        roles: user.roles,
      })),
      external_members: [],
    },
  });

  // form check user
  const {
    setValue: setCheckValue,
    getValues: getCheckValues,
    reset: resetCheck,
  } = useForm<{ email: string[]; roles: string[] }>({
    defaultValues: {
      email: [],
      roles: [],
    },
  });

  // form transfer ownership
  const { setValue: setTransferValue, getValues: getTransferValues } = useForm<{
    project_id: number;
    new_owner_id: number;
  }>({
    defaultValues: {
      project_id: project.id,
      new_owner_id: 0,
    },
  });

  const handleClearTags = () => {
    setIsClearTags(true);
    // clear formCheckEmailsUser data
    resetCheck({ email: [], roles: [] });
  };

  const handleClearTagsComplete = () => {
    setIsClearTags(false);
  };

  const handleClearRoles = () => {
    setIsClearRoles(true);
  };

  const handleClearRolesComplete = () => {
    setIsClearRoles(false);
  };

  // handle add user
  const handleAddUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const response = await fetcher.post(`/projects/validate-teammates`, {
        emails: getCheckValues('email'),
      });
      const invalidEmailsData = response.data.invalidEmails;
      const { validatedEmails } = response.data;
      // handle invalid emails
      if (invalidEmailsData.length > 0) {
        setInvalidEmails(invalidEmailsData);
        setIsOpenModalRequestAccess(true);
      }

      // handle validated emails
      if (validatedEmails.length > 0) {
        // append user to validated users list their roles where email is from formCheckEmailsUser.data.email list
        const users = validatedEmails.map((email: string, _index: number) => {
          const user = initialUsers.find((user) => user.email === email);
          return { ...user, roles: getCheckValues('roles') };
        }) as User[];
        setValidatedUsers([...validatedUsers, ...users]);
        setExcludedUsers([...excludedUsers, ...users]);
        // set mainForm members
        setValue('members', [
          ...watch('members'),
          ...users.map((user) => ({
            id: user.id,
            email: user.email,
            roles: user.roles,
          })),
        ]);
        handleClearTags();
        handleClearRoles();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // remove validated user using useCallback
  const removeUser = useCallback(
    (user: User) => {
      setValidatedUsers(validatedUsers.filter((u) => u.email !== user.email));
      setValue(
        'members',
        watch('members').filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedUsers, excludedUsers, setValue, watch],
  );

  const onSubmit: SubmitHandler<MainForm> = async () => {
    setPopupUnsave(false);
    const data = {
      ...getCheckValues(),
      members: validatedUsers.map((user) => ({
        id: user.id,
        email: user.email,
        roles: user.roles,
      })),
      external_members: validatedExternalUsers,
    };
    try {
      triggerGTMEvent({
        event: `Edit Project`,
        eventCategory: `Button Update Project Click`,
        eventAction: 'Click',
        eventLabel: 'update project',
        userId: user.email,
        data,
      });
      await fetcher.put(`/projects/${project.id}`, data);
      onClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const handleCloseModal = () => {
    onClose();
    setValidatedUsers(project.users);
    closeModalRequestAccess();
    setPopupUnsave(false);
  };

  const handleCloseModalRequestAccess = () => {
    if (getCheckValues('email').length > 0) {
      handleClearTags();
    }
    if (getCheckValues('roles').length > 0) {
      handleClearRoles();
    }
    setInvalidEmails([]);
    setRequestAccessRoles([]);
    closeModalRequestAccess();
  };

  const handleRequestAccess = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (getCheckValues('email').length > 0) {
      handleClearTags();
    }
    if (getCheckValues('roles').length > 0) {
      handleClearRoles();
    }
    closeModalRequestAccess();

    // append to validatedExternalUsers with roles
    const externalUsers = invalidEmails.map((email) => {
      return { email, roles: requestAccessRoles };
    });
    setValidatedExternalUsers([...validatedExternalUsers, ...externalUsers]);

    setValue('external_members', [
      ...watch('external_members'),
      ...externalUsers,
    ]);

    setExcludedUsers([
      ...excludedUsers,
      ...externalUsers.map((user: ExternalUser): User => {
        return {
          id: 0,
          email: user.email,
          avatar: '',
          avatar_url: '',
          email_verified_at: '',
          created_at: '',
          updated_at: '',
          name: '',
          roles: user.roles,
          whatsapp_number: '',
          status: '',
        };
      }),
    ]);
  };

  const removeExternalUser = useCallback(
    (user: ExternalUser) => {
      setValidatedExternalUsers(
        validatedExternalUsers.filter((u) => u.email !== user.email),
      );
      setValue(
        'external_members',
        watch('external_members').filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedExternalUsers, excludedUsers, setValue, watch],
  );

  const handleTransferOwnership = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      await fetcher.post(`/projects/transfer-ownership`, getTransferValues());
      setOpenPopupOwnership(false);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  // can delegate ownership show when validatedUsers email is in project.users email
  const canDelegateOwnership = (user: User) => {
    return project.users.some((u) => u.email === user.email);
  };

  useEffect(() => {
    if (!isOpen) {
      resetCheck({ email: [], roles: [] });
      setExcludedUsers([
        project.owner,
        ...validatedUsers,
        ...validatedExternalUsers.map((user: ExternalUser): User => {
          return {
            id: 0,
            email: user.email,
            avatar: '',
            avatar_url: '',
            email_verified_at: '',
            created_at: '',
            updated_at: '',
            name: '',
            roles: user.roles,
            whatsapp_number: '',
            status: '',
          };
        }),
      ]);
      setValue(
        'members',
        project.users.map((user) => ({
          id: user.id,
          email: user.email,
          roles: user.roles,
        })),
      );
    }
  }, [isOpen, resetCheck, setValue, project.owner, project.users]);

  return (
    <ModalCenter isOpen={isOpen} onClose={() => setPopupUnsave(true)}>
      <div
        className="relative w-[56rem] md:max-w-xs"
        onClick={handleStopPropagation}
      >
        <div className="w-full">
          <div className="relative max-h-[80vh] overflow-y-auto rounded-10 p-[36px] md:px-30 md:py-50">
            {popupUnsave && (
              <UnsavedChangesPopup
                onBack={() => setPopupUnsave(false)}
                onClose={handleCloseModal}
              />
            )}
            <div className="flex items-center justify-items-center gap-20">
              <h1 className="text-25 font-medium leading-30 text-black">
                Add your teammates!
              </h1>
            </div>
            <div className="flex flex-col gap-[36px]">
              <div className="mt-24 flex items-start gap-24">
                <div className="flex-1">
                  <div className="flex items-start justify-between gap-24 md:flex-col">
                    <div className="flex-1 md:w-full">
                      <InputLabel
                        className="mb-4 !text-black-redx"
                        htmlFor="emails"
                        value="Email"
                      />
                      <TextInputTags
                        className="!grow px-0"
                        containerClassName="text-black-redx border-b-2 border-soft-purplestroke-redx"
                        exludedUsers={excludedUsers}
                        id="emails"
                        initialTags={[]}
                        initialUsers={initialUsers}
                        isClearTags={isClearTags}
                        onChangeTags={(tags) => {
                          setCheckValue('email', tags);
                        }}
                        onClearTagsComplete={handleClearTagsComplete}
                        placeholder="Input your teammates email here"
                      />
                    </div>
                    <div className="w-7/24 md:w-full">
                      <InputLabel
                        className="!text-black-redx"
                        htmlFor="roles"
                        value="Roles"
                      />
                      <PopoverRolesInput
                        containerClassName="w-full !ps-0 text-black-redx border-b-2 border-soft-purplestroke-redx"
                        initialRoles={['Strategist', 'Creative', 'Content']}
                        isClearRoles={isClearRoles}
                        onChangeRoles={(roles) => {
                          setCheckValue('roles', roles);
                          if (roles.length > 0) {
                            setRequestAccessRoles(roles);
                          }
                        }}
                        onClearRolesComplete={handleClearRolesComplete}
                      />
                    </div>
                  </div>
                </div>
                <PrimaryButton
                  className="!px-22 w-100 shrink-0 border-1 border-blue-redx bg-transparent !py-14 !text-16 !font-semibold !leading-18 !text-blue-redx"
                  disabled={
                    getCheckValues('email').length === 0 ||
                    getCheckValues('roles').length === 0
                  }
                  onClick={(e) => handleAddUser(e)}
                >
                  Add
                </PrimaryButton>
              </div>
              <div className="flex flex-col gap-12">
                <InputLabel
                  className="!text-black-redx"
                  htmlFor="members"
                  value="Members"
                />
                <div className="max-h-5/6 overflow-y-auto">
                  {project.owner && (
                    <div className="flex items-center gap-24 py-5">
                      <div className="flex-1">
                        <div className="flex items-center justify-between gap-20">
                          <div className="flex-1">
                            <AvatarLabel
                              src={project.owner.avatar_url}
                              subtitle={project.owner.email}
                              title={project.owner.name}
                            />
                          </div>
                          <div className="w-8/24">
                            <p className="ps-8 text-14 font-semibold leading-20 text-black-redx/50">
                              Owner
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="flex items-center justify-center">
                          <Icon
                            className="size-24 text-black-redx/50"
                            icon="uil:trash"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {validatedUsers.map((user, i) => (
                    <div
                      key={user.email}
                      className="flex items-center gap-24 py-5"
                    >
                      <div className="flex-1">
                        <div className="flex items-center justify-between gap-20">
                          <div className="flex-1">
                            <AvatarLabel
                              src={user.avatar_url}
                              subtitle={user.email}
                              title={user.name}
                            />
                          </div>
                          <div className="relative w-8/24">
                            <PopoverRolesInput
                              canDelegateOwnership={canDelegateOwnership(user)}
                              className="font-semibold"
                              containerClassName="w-full"
                              error={
                                errors.members?.[i] &&
                                (errors.members[i].roles as any)
                              }
                              initialRoles={[
                                'Strategist',
                                'Creative',
                                'Content',
                              ]}
                              onChangeRoles={(roles) => {
                                // update mainForm members roles
                                setValue(
                                  'members',
                                  watch('members').map((m) => {
                                    if (m.email === user.email) {
                                      return { ...m, roles };
                                    }
                                    return m;
                                  }),
                                );

                                // update validatedUsers roles
                                setValidatedUsers(
                                  validatedUsers.map((u) => {
                                    if (u.email === user.email) {
                                      return { ...u, roles };
                                    }
                                    return u;
                                  }),
                                );
                              }}
                              onChooseOwnership={(status) => {
                                setOpenPopupOwnership(status);
                                setTransferValue('new_owner_id', user.id);
                              }}
                              selectedRoles={user.roles}
                            />
                            <InputError
                              className=""
                              light={false}
                              message={
                                errors.members?.[i] &&
                                (errors.members[i].roles as any)?.message
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="flex items-center justify-center">
                          <Icon
                            className="size-24 cursor-pointer text-black-redx"
                            icon="uil:trash"
                            onClick={() => removeUser(user)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  {validatedExternalUsers &&
                    validatedExternalUsers.length > 0 && (
                      <div className="py-10">
                        <InputLabel
                          className="!text-black-redx"
                          htmlFor="nonmembers"
                          value="Requested Members"
                        />
                      </div>
                    )}
                  {validatedExternalUsers &&
                    validatedExternalUsers.map((user, _i) => {
                      return (
                        <div
                          key={user.email}
                          className="flex items-center justify-between gap-20 py-8"
                        >
                          <div className="flex-1">
                            <div className="flex items-center gap-10">
                              <Icon
                                className="size-32"
                                icon="mdi:user-circle"
                              />
                              <div className="font-medium text-black">
                                <div className="mb-5 text-14 font-semibold leading-16 text-black-redx">
                                  {user.email}
                                </div>
                                <div className="text-12 leading-14 text-grey-redx">
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-6/24">
                            <p className="text-14">{user.roles.join(', ')}</p>
                          </div>
                          <div className="w-100">
                            <div className="flex items-center justify-center">
                              <Icon
                                className="size-24 cursor-pointer text-black-redx"
                                icon="uil:trash"
                                onClick={() => removeExternalUser(user)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='border-soft-purple-redx" flex items-end justify-between border-b-2 py-10'>
                <div className="text-black-redx">
                  <p className="text-12 font-semibold leading-16">
                    Share Project Link
                  </p>
                  <p className="text-14 font-normal leading-18">
                    {appUrl}/{project.slug}
                  </p>
                </div>
                <ButtonCopyLink value={`${appUrl}/${project.slug}`} />
              </div>
              <div className="mt-30 flex items-center justify-end gap-x-10">
                <PrimaryButton
                  className="ms-4  bg-blue-redx px-31 py-16 !text-16 !leading-18 hover:bg-blue-redx active:bg-blue-redx"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          </div>

          <TransferOwnershipModal
            isOpen={openPopupOwnership}
            onClickRequest={handleTransferOwnership}
            onClose={() => setOpenPopupOwnership(false)}
          />

          <RequestAccessModal
            emails={invalidEmails}
            isOpen={isOpenModalRequestAccess}
            onClickRequest={(e) => handleRequestAccess(e)}
            onClose={handleCloseModalRequestAccess}
            roles={requestAccessRoles}
          />
        </div>
      </div>
    </ModalCenter>
  );
};

export default UpdateFormModal;
