import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import MarkdownView from '@/Components/MardownView';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import TextAreaInput from '@/Components/TextAreaInput';
import { useUpdateSelectedOpportunity } from '@/Hooks/react-query/opportunity/useUpdateSelected';
import type { User } from '@/Types';
import type { OpportunityAmongCompetitionProps } from '@/Types/opportunity_among_competition';
import type { Schema } from '@/Types/opportunity_among_competition/schema';
import { OpportunityAmongCompetitionSchema } from '@/Types/opportunity_among_competition/schema';
import { triggerGTMEvent } from '@/Utils/gtm';

const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = '' }) => (
  <div className={`flex flex-col gap-10 ${className}`}>{children}</div>
);
const Label: React.FC<{
  value: string;
}> = ({ value }) => (
  <p className="text-12 font-semibold leading-14 text-black-redx">{value}</p>
);
const Content: React.FC<{
  value: string;
  isFetching: boolean;
}> = ({ value, isFetching }) => {
  const isValueList = value?.split('\n').length > 1;
  if (isValueList) {
    return (
      <ul className="ms-20 list-disc">
        {value.split('\n').map((item, index) => (
          <li
            key={index}
            className="text-14 font-normal leading-20 text-black-redx"
          >
            {!isFetching && <MarkdownView content={item} />}
            {isFetching && (
              <div className="min-h-50 py-4 text-14">
                <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div className="inline text-14 font-normal leading-20 text-black-redx">
      {!isFetching && <MarkdownView content={value} />}
      {isFetching && (
        <div className="min-h-50 py-4 text-14">
          <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
        </div>
      )}
    </div>
  );
};

const Card = forwardRef<
  any, // Define or import CardHandles if needed
  {
    user: User;
    item: OpportunityAmongCompetitionProps;
    index: number;
    totalItem: number;
    isFetching?: boolean;
    isCanEdit?: boolean;
    isEditing?: boolean;
    setIsEditing?: (isEditing: boolean) => void;
    isAdding?: boolean;
    setIsAdding?: (isAdding: boolean) => void;
    onSaveEditedData?: (
      data: OpportunityAmongCompetitionProps,
      index: number,
    ) => void;
    onSuccessSelected?: (isShowPopUp: boolean) => void;
  }
>(
  (
    {
      user,
      item,
      index,
      totalItem,
      isFetching = false,
      isCanEdit = true,
      onSaveEditedData,
      setIsEditing,
      isEditing,
      isAdding,
      setIsAdding,
      onSuccessSelected,
    },
    ref,
  ) => {
    const defaultValues: Schema = useMemo(
      () => ({
        brand_persona: item.brand_persona ?? '',
        creative_communication_angle: item.creative_communication_angle ?? '',
        features_and_benefits: item.features_and_benefits ?? '',
        digital_key_touch_points: item.digital_key_touch_points ?? '',
      }),
      [item],
    );
    const params = useParams<{ project_slug: string }>();
    const [selectLoading, setSelectLoading] = useState(false);
    const { mutate } = useUpdateSelectedOpportunity();

    const { control, handleSubmit, reset, setError } = useForm<Schema>({
      resolver: zodResolver(OpportunityAmongCompetitionSchema),
      defaultValues,
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () =>
          handleSubmit(async (formData) => {
            const result =
              await OpportunityAmongCompetitionSchema.safeParse(formData);
            result.error?.errors.forEach((error) => {
              setError(error.path[0] as keyof Schema, {
                message: error.message,
              });
            });
            if (result.success) {
              onSaveEditedData &&
                onSaveEditedData(
                  {
                    ...formData,
                    is_selected: item.is_selected,
                    id: item.id,
                    opportunity_among_competition_number:
                      item.opportunity_among_competition_number,
                  },
                  index,
                );
            }
          })(),
      }),
      [handleSubmit, onSaveEditedData, index, item.is_selected, item.id],
    );

    useEffect(() => {
      reset(item);
    }, [item, reset]);

    const toggleIsEditing = () => setIsEditing && setIsEditing(!isEditing);
    const toggleIsAdding = () => setIsAdding && setIsAdding(!isAdding);

    const onSubmit = (data: Schema) => {
      setIsEditing && toggleIsEditing();
      setIsAdding && toggleIsAdding();
      if (onSaveEditedData) {
        onSaveEditedData(
          {
            ...data,
            is_selected: item.is_selected,
            id: item.id,
            opportunity_among_competition_number:
              item.opportunity_among_competition_number,
          },
          index,
        );
      }
    };

    const handleSelect = () => {
      setSelectLoading(true);
      mutate(
        {
          projectSlug: params.project_slug ?? '',
          id: item.id,
          state: !item.is_selected,
        },
        {
          onSuccess: (data) => {
            triggerGTMEvent({
              event: `Select Opportunity Among Competition`,
              eventCategory: `Button Select Opportunity Among Competition Click`,
              eventAction: 'Click',
              eventLabel: 'Opportunity Among Competition',
              userId: user.email,
              data: { type: item.is_selected ? 'Unselect' : 'Select', ...item },
            });

            setSelectLoading(false);
            data && onSuccessSelected && onSuccessSelected(data.is_show_pop_up);
          },
          onError: () => {
            setSelectLoading(false);
            toast.error('Something went wrong');
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="py-15 text-center ">
          <h1 className="w-full text-center text-16 font-bold leading-20 text-black-redx">
            {!isFetching && isAdding && 'New Idea'}
            {!isFetching && !isAdding && (
              <h1 className="text-15 font-bold">
                Idea Task {index + 1} of {totalItem}
              </h1>
            )}

            {isFetching && (
              <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
            )}
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`grid grid-cols-2 gap-24 p-24 pb-10 ${
              isEditing && item.is_selected ? '!bg-transparent' : ''
            } ${
              item.is_selected
                ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
                : 'border-t-1 border-stroke-redx'
            }`}
          >
            <Container>
              <Label value="Brand Persona" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="brand_persona"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the brand persona here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content isFetching={isFetching} value={item.brand_persona} />
              )}
            </Container>
            <Container>
              <Label value="Creative Communication Angle" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="creative_communication_angle"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the creative communication angle here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={item.creative_communication_angle}
                />
              )}
            </Container>
            <Container>
              <Label value="Features & Benefits" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="features_and_benefits"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the features & benefits here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={item.features_and_benefits}
                />
              )}
            </Container>
            <Container>
              <Label value="Digital Key Touch Points" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="digital_key_touch_points"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the digital key touch points here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={item.digital_key_touch_points}
                />
              )}
            </Container>
          </div>
          <div className="flex w-full items-center justify-end py-20">
            {!isFetching && isCanEdit && (
              <SelectButton
                hasOne={false}
                index={index}
                isEditing={(isEditing || isAdding)!}
                isLoading={selectLoading}
                isSelected={item.is_selected}
                onSelect={handleSelect}
                section="opportunity-among-competition"
              />
            )}
          </div>
        </form>
      </div>
    );
  },
);

export default Card;
