import type { TextareaHTMLAttributes } from 'react';
import React from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isFocused?: boolean;
  rightElement?: React.ReactNode;
  error?: string;
  containerClassName?: string;
  light?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  className = '',
  containerClassName = '',
  error = '',
  placeholder = '',
  light = false,
  rightElement = null,
  ...props
}) => {
  const placeholderClass = light
    ? 'placeholder-lightplaceholder-redx'
    : 'placeholder-placeholder-redx';

  return (
    <>
      <div
        className={`${
          error ? '!border-red-500 ' : ''
        }flex w-full items-center overflow-hidden bg-transparent ${
          containerClassName
        }`}
      >
        <textarea
          className={`!focus:border-transparent !focus:ring-0 rounded-md !border-transparent bg-transparent px-0 py-2 text-12 shadow-sm ${placeholderClass} ${
            className
          }`}
          placeholder={placeholder}
          {...props}
        />
        {rightElement}
      </div>
      {error && <p className="mt-2 text-12 text-red-500">{error}</p>}
    </>
  );
};

export default TextArea;
