/* eslint-disable import/no-extraneous-dependencies */
import {
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { Icon } from '@iconify/react';
import React, { Fragment } from 'react';

const EditButton: React.FC<{ toggleEditing: () => void }> = ({
  toggleEditing,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 2,
        crossAxis: -10,
      }),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
  return (
    <>
      <button
        ref={refs.setReference}
        className="inline-flex cursor-pointer gap-4 font-semibold text-blue-redx"
        {...getReferenceProps()}
        onClick={toggleEditing}
      >
        Edit <Icon className=" size-20" icon="lucide:pencil-line" />
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="rounded-8 px-15 py-8 text-15"
          style={{
            ...floatingStyles,
            boxShadow: '0px 0px 12px 0px #00000026',
          }}
          {...getFloatingProps()}
        >
          Edit
        </div>
      )}
    </>
  );
};

export default EditButton;
