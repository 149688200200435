/* eslint-disable @typescript-eslint/naming-convention */
import type { PropsWithChildren } from 'react';
import { Toaster } from 'react-hot-toast';

import { ModalProvider } from '@/Context/ModalContext';

export default function Guest({ children }: PropsWithChildren) {
  return (
    <ModalProvider>
      <div className="relative h-screen w-full">
        <div className="absolute left-0 top-0 size-full bg-black">
          <video
            autoPlay
            className="fixed bottom-0 right-0 size-full object-cover"
            loop
            muted
            playsInline
          >
            <source src="assets/video/bg-gradient.webm" type="video/webm" />
            <source src="assets/video/bg-gradient.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="absolute left-0 top-0 ml-50 mt-50 md:inset-x-1/2 md:ml-0">
          <img
            alt="logo"
            className="flex h-45 w-auto justify-center"
            src="assets/img/ainstein-logo.png"
          />
        </div>

        <div className="container-xl flex size-full items-center justify-around">
          {children}
        </div>
      </div>
      <Toaster
        containerStyle={{
          bottom: 120,
        }}
        position="bottom-center"
        toastOptions={{
          duration: 5000,
        }}
      />
    </ModalProvider>
  );
}
