import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ChallengeUpdateSelectedResponse } from '@/Types/challenge_and_task';

const updateSelectedChallengeAndTask = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  const url = `/projects/${projectSlug}/challenge-and-task/update-selected/${id}`;

  try {
    const { data } = await goFetcher.put(url, {});

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedChallengeAndTask = () => {
  return useMutation<
    ChallengeUpdateSelectedResponse,
    Error,
    { id: number; projectSlug: string }
  >({
    mutationFn: updateSelectedChallengeAndTask,
  });
};

export { useUpdateSelectedChallengeAndTask };
