import { Icon } from '@iconify/react';
import React, { useState } from 'react';

import useUser from '@/Hooks/react-query/useUser';
import UpdateFormModal from '@/Pages/Projects/Partials/UpdateFormModal.tsx';
import { fetcher } from '@/Services/axios';
import type { User } from '@/Types';
import type { ProjectProps } from '@/Types/projects';

const ProjectMembers: React.FC<{
  project: ProjectProps;
  initialUsers: User[];
}> = ({ project, initialUsers }) => {
  const { data: user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectProps>();
  const closeModal = () => setIsOpen(false);
  const appUrl = new URL(window.location.href);
  const baseUrl = appUrl.origin;

  const handleClickAddMember = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const response = await fetcher.get(`/${project.slug}`);
    setSelectedProject(response.data);
    setIsOpen(true);
  };

  return (
    <>
      <div className="flex -space-x-8 rtl:space-x-reverse">
        {project.owner && (
          <img
            alt=""
            className="size-32 rounded-full border-2 border-white"
            src={project.owner.avatar_url}
            title={`${project.owner.name} (${project.owner.email})`}
          />
        )}
        {project.users.map((u, i) => (
          <img
            key={i}
            alt=""
            className="size-32 rounded-full border-2 border-white"
            src={u.avatar_url}
            title={`${u.name ?? 'Noname'} (${u.email})`}
          />
        ))}
        {project.owner.id === user.id && (
          <button
            className="flex size-32 items-center justify-center rounded-full border-2 border-white bg-soft-purple-redx text-blue-redx"
            onClick={handleClickAddMember}
          >
            <Icon className="size-24" icon="uil:plus" />
          </button>
        )}
      </div>
      <UpdateFormModal
        appUrl={baseUrl}
        initialUsers={initialUsers}
        isOpen={isOpen}
        onClose={closeModal}
        project={selectedProject ?? project}
        user={user}
      />
    </>
  );
};

export default ProjectMembers;
