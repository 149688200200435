import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';

import EditButton from '@/Components/Projects/EditButton';

interface ActionsProps {
  isEditor: boolean;
  isEditing: boolean;
  onAdd: () => void;
  onCancel: () => void;
  onSave: () => void;
  toggleEditing: () => void;
}

const Actions: React.FC<ActionsProps> = ({
  isEditor,
  isEditing,
  onAdd,
  onCancel,
  onSave,
  toggleEditing,
}) => {
  if (!isEditor) return null;

  return (
    <div className="mb-35 flex w-full items-center justify-between">
      {!isEditing ? (
        <button
          className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
          onClick={onAdd}
        >
          Add Challenge & Communication Task Manually
          <Icon className="size-20" icon="lucide:plus" />
        </button>
      ) : (
        <div />
      )}

      <div className="flex items-center justify-end gap-x-15">
        {isEditing ? (
          <>
            <button
              className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
              onClick={onCancel}
            >
              <span className="text-15 font-semibold">Cancel</span>
              <Icon className="size-20" icon="lucide:x" />
            </button>
            <button
              className={`inline-flex items-center gap-x-4 ${
                false ? 'text-gray-600' : 'text-blue-redx'
              } cursor-pointer`}
              disabled={false}
              onClick={onSave}
            >
              <span className="text-15 font-semibold">Save</span>
              <Icon className="size-20" icon="lucide:save" />
            </button>
          </>
        ) : (
          <EditButton toggleEditing={toggleEditing} />
        )}
      </div>
    </div>
  );
};

export default Actions;
