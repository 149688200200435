import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import useProjectDetail from '@/Hooks/react-query/useProjectDetail';
import ProjectLayout from '@/Layouts/ProjectLayout';

import Index from './Index';

const Wrapper = () => {
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? '',
  );

  return (
    <>
      <Helmet>
        <title>Key Insight</title>
      </Helmet>
      <ProjectLayout>
        {!isLoading && project && <Index project={project} />}
      </ProjectLayout>
    </>
  );
};

export default Wrapper;
