/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import {
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import React, { useEffect, useState } from 'react';

interface SelectButtonProps {
  hasOne: boolean;
  section: string;
  isEditing: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSelected: boolean;
  onSelect: (index: number) => void;
  index: number;
  hasPopUp?: boolean;
  titlePopUp?: string;
  descPopUp?: string;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  hasOne,
  section,
  isEditing,
  isSelected,
  onSelect,
  index,
  isLoading = false,
  isDisabled = false,
  hasPopUp = false,
  titlePopUp,
  descPopUp,
}) => {
  const buttonClasses = `
    mr-1 rounded-4 border-[1px] px-25 py-8 text-15 font-semibold 
    ${isSelected ? 'border-error-redx text-error-redx' : 'border-blue-redx text-blue-redx'} 
    ${isDisabled || hasOne ? 'cursor-not-allowed border-placeholder-redx text-placeholder-redx' : ''}
  `;

  const handleClick = () => {
    if (!isLoading && !isDisabled && !hasOne) {
      onSelect(index);
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 2,
        crossAxis: -10,
      }),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps } = useInteractions([hover]);
  const [anchorPosition, setAnchorPosition] = useState<
    | 'bottom start'
    | 'top end'
    | 'top start'
    | 'right end'
    | 'right start'
    | 'bottom end'
    | 'left end'
    | 'left start'
    | undefined
  >('bottom start');

  useEffect(() => {
    const handlePosition = () => {
      if (!refs.reference.current || !refs.floating.current) return;

      const triggerRect = refs.reference.current.getBoundingClientRect();
      const contentHeight = refs.floating.current.offsetHeight;
      const viewportHeight = window.innerHeight;

      const newPosition: 'bottom start' | 'top start' =
        triggerRect.bottom + contentHeight > viewportHeight
          ? 'top start'
          : 'bottom start';

      setAnchorPosition(newPosition);
    };

    handlePosition();
    window.addEventListener('resize', handlePosition);

    return () => window.removeEventListener('resize', handlePosition);
  }, [refs]);

  if (isEditing) return null;

  if (isSelected && hasPopUp) {
    return (
      <Popover className="relative">
        <PopoverButton
          ref={refs.setReference}
          className={`cursor-pointer ${buttonClasses}`}
          {...getReferenceProps()}
        >
          Unselect
        </PopoverButton>
        <Transition
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <PopoverPanel
            anchor={{ to: anchorPosition, gap: -50, offset: -100 }}
            className=" z-10 !max-w-459 rounded-lg bg-white p-16 shadow-lg"
          >
            {({ close }) => (
              <div className="flex flex-col gap-8">
                <h1 className="text-14 font-semibold leading-16 text-black-redx">
                  {titlePopUp}
                </h1>
                <p className="mb-16 block w-full !text-12 leading-relaxed text-grey-redx">
                  {descPopUp}
                </p>
                <div className="flex w-full justify-end gap-x-16">
                  <button
                    className="w-100  rounded-8 border-1 border-error-redx px-14 py-10 text-15 font-semibold text-error-redx"
                    onClick={() => {
                      handleClick();
                      close();
                    }}
                  >
                    Unselect
                  </button>
                  <button
                    className="w-100 rounded-8  bg-blue-redx px-14 py-10 text-15 font-semibold text-white"
                    onClick={() => {
                      close();
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            )}
          </PopoverPanel>
        </Transition>
      </Popover>
    );
  }

  return (
    <div className="group relative mb-4 flex w-full justify-center">
      <button
        aria-busy={isLoading}
        aria-disabled={isLoading || isDisabled || hasOne}
        className={buttonClasses}
        disabled={isLoading || isDisabled || hasOne}
        id={`btn-select-${section}`}
        onClick={handleClick}
        type="button"
      >
        {isLoading ? (
          <svg
            aria-hidden="true"
            className={`h-20 w-50 animate-spin ${isSelected ? 'fill-error-redx' : 'fill-blue-redx'} text-gray-200`}
            fill="none"
            viewBox="0 0 100 101"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : isSelected ? (
          'Unselect'
        ) : (
          'Select'
        )}
      </button>
      {hasOne && (
        <div
          className="absolute bottom-60 left-1/2 z-30 hidden -translate-x-1/2 whitespace-nowrap rounded-10 bg-white px-16 py-8 text-center text-14 leading-18 text-black-redx group-hover:block sm:min-w-350"
          style={{
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
          }}
        >
          <p>You must choose at least one {section.replace('-', ' ')}.</p>
          <p>
            Select other options first to unselect this{' '}
            {section.replace('-', ' ')}.
          </p>
        </div>
      )}
    </div>
  );
};

export default SelectButton;
