import Wrapper from '@/Pages/DiscoverabilityContent/Wrapper';

const DiscoverabilityContentRoutes = [
  {
    path: '/:project_slug/discoverability-content',
    element: <Wrapper />,
  },
];

export default DiscoverabilityContentRoutes;
