export const saveProgressToLocalStorage = (
  slug: string,
  section: string,
  archetypeId: number,
  progress: number,
) => {
  const savedProgress = JSON.parse(
    localStorage.getItem('progressStates') || '{}',
  );

  // Update struktur data
  const updatedProgress = {
    ...savedProgress,
    [slug]: {
      ...(savedProgress[slug] || {}),
      [section]: {
        ...(savedProgress[slug]?.[section] || {}),
        [archetypeId]: progress,
      },
    },
  };

  localStorage.setItem('progressStates', JSON.stringify(updatedProgress));
};

// Ambil progress dari localStorage berdasarkan identifier menu
export const getProgressFromLocalStorage = (
  slug: string,
  section: string,
  archetypeId: number,
): number | null => {
  const savedProgress = JSON.parse(
    localStorage.getItem('progressStates') || '{}',
  );
  return savedProgress[slug]?.[section]?.[archetypeId] || null;
};

export const removeProgressFromLocalStorage = (
  slug: string,
  section: string,
  archetypeId: number,
) => {
  const savedProgress = JSON.parse(
    localStorage.getItem('progressStates') || '{}',
  );

  if (savedProgress[slug]?.[section]?.[archetypeId]) {
    delete savedProgress[slug][section][archetypeId]; // Hapus archetype tertentu

    // Jika section kosong, hapus section
    if (Object.keys(savedProgress[slug][section]).length === 0) {
      delete savedProgress[slug][section];
    }

    // Jika slug kosong, hapus slug
    if (Object.keys(savedProgress[slug]).length === 0) {
      delete savedProgress[slug];
    }

    // Simpan kembali ke localStorage
    localStorage.setItem('progressStates', JSON.stringify(savedProgress));
  }
};

export const removeAllArchetypesBasedOnSectionFromLocalStorage = (
  slug: string,
  section: string,
) => {
  const savedProgress = JSON.parse(
    localStorage.getItem('progressStates') || '{}',
  );

  // Periksa apakah slug dan section ada di savedProgress
  if (savedProgress[slug]?.[section]) {
    delete savedProgress[slug][section]; // Hapus seluruh section

    // Jika slug kosong setelah penghapusan, hapus slug
    if (Object.keys(savedProgress[slug]).length === 0) {
      delete savedProgress[slug];
    }

    // Simpan kembali ke localStorage
    localStorage.setItem('progressStates', JSON.stringify(savedProgress));
  }
};

export const clearAllProgressFromLocalStorage = () => {
  const savedProgress = JSON.parse(
    localStorage.getItem('progressStates') || '{}',
  );

  // Reset semua data di dalam progressStates
  Object.keys(savedProgress).forEach((slug) => {
    savedProgress[slug] = {}; // Hapus semua section di dalam setiap slug
  });

  // Simpan kembali ke localStorage
  localStorage.setItem('progressStates', JSON.stringify(savedProgress));
};

export const getSectionProgressFromLocalStorage = (
  projectSlug: string,
  section: string,
) => {
  const progressData = JSON.parse(
    localStorage.getItem('progressStates') || '{}',
  );
  // Ambil data hanya untuk section tertentu
  return progressData?.[projectSlug]?.[section] || {};
};
