import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { KeyInsightSchema } from '@/Types/key_insight/schema';

const updateKeyInsight = async ({
  payload,
  id,
  archetypeId,
  projectSlug,
}: {
  payload: KeyInsightSchema;
  id: number;
  archetypeId: number;
  projectSlug: string;
}) => {
  const url = `/projects/${projectSlug}/key-insight/submit/${id}`;

  try {
    const { data } = await goFetcher.post(url, {
      content: payload,
      archetypeId,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateKeyInsight = () => {
  return useMutation<
    any,
    Error,
    {
      payload: KeyInsightSchema;
      id: number;
      archetypeId: number;
      projectSlug: string;
    }
  >({
    mutationFn: updateKeyInsight,
  });
};

export { useUpdateKeyInsight };
