import { TabGroup } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import { useUpdateChallengeAndTask } from '@/Hooks/react-query/challenge-and-task';
import useChallengeAndTasks from '@/Hooks/react-query/challenge-and-task/useChallengeAndTask';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import { useArchetypeProgress } from '@/Hooks/useArchetypeProgress';
import { useArchetypeStateUpdater } from '@/Hooks/useArchetypeStateUpdater';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useSaveProgressToLocalStorage } from '@/Hooks/useSaveProgressToLocalStorage';
import { useSyncProgressFromLocalStorage } from '@/Hooks/useSyncProgressFromLocalStorage';
import { fetcher } from '@/Services/axios';
import type {
  ArchetypeChallenge,
  ArchetypesState,
  SectionChallenge,
} from '@/Types/challenge_and_task';
import type { ChallengeSchema } from '@/Types/challenge_and_task/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/projects/init-data';
import {
  getProgressFromLocalStorage,
  getSectionProgressFromLocalStorage,
  removeAllArchetypesBasedOnSectionFromLocalStorage,
  removeProgressFromLocalStorage,
  saveProgressToLocalStorage,
} from '@/Utils/projects/persist-progress';

import Actions from './Components/Actions';
import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';
import Tabs from './Components/Tabs';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const sectionList: SectionList = {
    title: 'Challenge & Communication Task',
    value: 'challenges',
    section: 'brand',
  };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const [state, dispatch] = useContext(ProjectContext);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } =
    useChallengeAndTasks(project);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate } = useUpdateChallengeAndTask();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype
  const [isRegenerating, setIsReGenerating] = useState(false);
  const archetypesArray = useMemo(
    () => Object.values(archetypes),
    [archetypes],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);
  const { handleGenerateChallengeAndTask, handleGenerateCompetitorAnalysis } =
    useGenerate(dispatch);

  const { data: differentArchetypes } = useDifferentArchetypes(project);

  const {
    isChallengeAndTaskOfTheBrand,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const routeOptionValue: RouteOption = {
    label: 'Generate Challenge & Task',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  };

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(
        data.data, // Inferred type
        archetypes,
      );

      setArchetypes(mappedArchetypes);

      // Initialize generating states
      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      // Update generating states and initialize progress for new archetypes
      const savedProgress = getSectionProgressFromLocalStorage(
        project.slug,
        sectionList.value,
      );
      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));
      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);
    }
  }, [data]);

  // Refetch periodically for active archetype if isGenerating is true
  useArchetypeProgress({
    activeArchetype,
    generatingStates,
    refetch,
    setProgressStates,
  });

  // Update generating state after refetch completes
  useArchetypeStateUpdater({
    data,
    activeArchetype,
    activeTab,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  // Simpan progress ke localStorage setiap kali progressStates berubah
  useSaveProgressToLocalStorage({
    activeArchetype,
    progressStates,
    projectSlug: project.slug,
    section: sectionList.value,
    saveFunction: saveProgressToLocalStorage,
  });

  // get progress bar
  useSyncProgressFromLocalStorage({
    activeArchetype,
    projectSlug: project.slug,
    section: sectionList.value,
    progressStates,
    setProgressStates,
    getProgressFunction: getProgressFromLocalStorage,
  });

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const selectedKeyIndexes = useMemo(() => {
    return activeArchetype?.challenges
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [activeArchetype?.challenges]);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    payload: ChallengeSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeArchetype?.id!, projectSlug },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Challenge And Task`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Challenge And Task  Click`,
            eventAction: 'Click',
            eventLabel: 'Challenge And Task',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    const activeArchetypeId = archetypesArray[activeTab]?.id;
    if (!activeArchetypeId) return;

    setArchetypes((prev) => {
      const current = prev[activeArchetypeId]?.challenges || [];
      const filtered = current.filter((datum) => datum.id !== -1);

      return {
        ...prev,
        [activeArchetypeId]: {
          ...prev[activeArchetypeId],
          challenges: filtered,
        },
      };
    });

    toggleIsAdding();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };
  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddNew = () => {
    if (!activeArchetype) return;

    const newData: SectionChallenge = {
      id: -1, // Gunakan ID sementara
      archetype_id: activeArchetype.id,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: 0,
      challenges_content: {
        challenge: '',
        communication_task: '',
      },
    };

    // Hitung panjang array baru
    const current = archetypes[activeArchetype.id]?.challenges || [];
    const newIndex = current.length;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          challenges: [...current, newData],
        },
      };
      return updatedArchetypes;
    });

    // Setelah state diperbarui, pastikan slider berpindah ke item baru
    setGoToIndex(newIndex);
    toggleIsAdding(); // Aktifkan mode "Adding"
  };

  const handleRegenerate = async () => {
    if (
      differentArchetypes?.is_different_archetype.challenges ||
      isChallengeAndTaskOfTheBrand.isRegenerated
    ) {
      await handleGenerateChallengeAndTask()(project.slug, null);
      refetch();
      return;
    }

    if (isCompetitorAnalysisComplete.isRegenerated) {
      setIsReGenerating(true);
      triggerGTMEvent({
        event: `Generate Competitor Analysis`,
        eventCategory: `Generate Competitor Analysis Click`,
        eventAction: 'Click',
        eventLabel: 'Competitor Analysis',
        userId: user.email,
        data: project.slug,
      });

      await handleGenerateCompetitorAnalysis()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
    }

    navigate(`/${project.slug}/competitor-analysis`);
  };

  const hasSelected = (archetypesArray: ArchetypeChallenge[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelected = archetypesArray
      .map((section, index) => {
        const hasSelectedKey = section.challenges.some(
          (data) => data.is_selected === 1,
        );
        return hasSelectedKey ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelected.length) {
      setErrorTabIndex(indicesWithoutSelected);
      const namesWithoutSelected = indicesWithoutSelected
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Challenge and Task for ${namesWithoutSelected}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ['OnePageStrategy', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    // TODO: Will be changed to use the API provided in ticket REDX-861
    const { data } = await fetcher.get(
      `/${project.slug}/challenge-and-task/check-regenerate`,
    );

    if (
      data?.data.is_show_pop_up ||
      isCompetitorAnalysisComplete.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.is_show_pop_up || isCompetitorAnalysisComplete.isRegenerated,
      );
      return;
    }
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/competitor-analysis`);
  };

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      'key_insights',
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeChallenge>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Challenge And Task`,
      eventCategory: `Generate More Challenge And Task Click`,
      eventAction: 'Click',
      eventLabel: 'Challenge And Task',
      userId: user.email,
      data: prompt,
    });

    await handleGenerateChallengeAndTask()(project.slug, prompt);
    refetch();
    dispatch(setEnableHistoryStatus(true));
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${activeArchetype?.total_selected_challenges ?? 0} from ${activeArchetype?.total_challenges ?? 0} key Insight selected`,
      ),
    );

    if (activeArchetype?.is_querying) {
      dispatch(setEnableHistoryStatus(true));
    } else {
      if (activeArchetype && !activeArchetype.is_querying) {
        removeProgressFromLocalStorage(
          project.slug,
          sectionList.section,
          activeArchetype.id,
        );

        setProgressStates((prev) => ({
          ...prev,
          [activeArchetype.id]: 0,
        }));
      }

      dispatch(setEnableHistoryStatus(!activeArchetype));
    }

    if (isEditor) {
      const isInactive = activeArchetype?.is_querying
        ? true
        : activeArchetype?.total_selected_challenges === 0;

      if (
        isCompetitorAnalysisComplete.status &&
        isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Regenerate Competitor Analysis';
        routeOptionValue.isGenerate = true;
      }

      if (
        !isCompetitorAnalysisComplete.status &&
        !isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Generate Competitor Analysis';
        routeOptionValue.isGenerate = true;
      }

      if (
        isCompetitorAnalysisComplete.status &&
        !isCompetitorAnalysisComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Next to Competitor Analysis';
        routeOptionValue.isGenerate = false;
      }

      routeOptionValue.isInactive =
        !isChallengeAndTaskOfTheBrand.status ||
        isChallengeAndTaskOfTheBrand.isQuerying
          ? true
          : isInactive;
    } else {
      routeOptionValue.label = 'Next to Competitor Analysis';
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isCompetitorAnalysisComplete.status ||
        isCompetitorAnalysisComplete.isQuerying;
    }

    routeOptionValue.onClick = () => {
      if (
        !isKeyInsightsComplete.status ||
        activeArchetype?.is_querying ||
        isKeyInsightsComplete.isQuerying
      ) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait challenge & communication Task  is still generating`,
          ),
        );
        return;
      }
      if (!isEditor && !isCompetitorAnalysisComplete.status) {
        setShowViewInfoModal(true);
        return;
      }

      if (isEditor && isCompetitorAnalysisComplete.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, competitor task is still generating`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && (isAdding || isEditing)) {
        dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && routeOptionValue.isGenerate) {
        const hasError = hasSelected(archetypesArray);

        if (!hasError) {
          handleRefetchSelectedData();
        }

        return;
      }

      navigate(`/${project.slug}/competitor-analysis`);
    };

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    isKeyInsightsComplete,
    isAdding,
    isEditing,
    activeArchetype,
    isChallengeAndTaskOfTheBrand,
    activeTab,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/key-insight`);
        },
      }),
    );
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Challenge & Communication Task of the Brand
            </h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one or more
              </span>{' '}
              Challenge & Communication Task{' '}
              <span className="font-bold text-black-redx">
                for each archetype
              </span>
              , or add a new by clicking &apos;Add New Challenge & Communication
              Task&apos;.
            </span>
          </div>
          {isEditor && (
            <RegenerateButton
              isGenerateMore
              isLoading={isLoading}
              limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
              maxLimit={maxGenerateLimit}
              onSubmit={(form) => handleRegenerateButton(form.prompt)}
              section="key-insight"
            />
          )}
        </div>

        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs
            activeTab={activeTab}
            archetypesArray={archetypesArray}
            errorTabIndex={errorTabIndex}
            isEditing={isEditing || isAdding}
            isLoading={isLoading}
            onActiveTab={setActiveTab}
            user={user}
          />
          {isLoading ? (
            <Skeleton project={project} user={user} />
          ) : (
            <div
              key={activeArchetype?.id || activeTab}
              className="h-full py-24"
            >
              {(() => {
                if (!activeArchetype) {
                  return null; // Jangan tampilkan apa-apa
                }

                if (activeArchetype.is_querying) {
                  return (
                    <GenerateLoading
                      progress={progressPortion({
                        progress: Number(
                          (progressStates[activeArchetype.id] || 0).toFixed(0),
                        ),
                        isQuerying: activeArchetype.is_querying,
                        isContentAvailable:
                          activeArchetype.challenges.length > 0,
                      })}
                      project={project}
                      section={sectionList}
                      showEmailNotification={
                        activeArchetype.is_email_notification
                      }
                    />
                  );
                }

                return (
                  <div className="flex flex-col gap-20 ">
                    <SelectedCard
                      data={[
                        {
                          title: 'Archetype Name',
                          content:
                            activeArchetype.archetype_content.archetype_name,
                        },
                        {
                          title: 'Demographic',
                          content:
                            activeArchetype.archetype_content.demographics,
                        },
                        {
                          title: 'Occupation',
                          content: activeArchetype.archetype_content.occupation,
                        },
                        {
                          title: 'Lifestyle',
                          content: activeArchetype.archetype_content.lifestyle,
                        },
                        {
                          title: 'Behavior',
                          content: activeArchetype.archetype_content.behavior,
                        },
                        {
                          title: 'Need & Challenges',
                          content:
                            activeArchetype.archetype_content
                              .needs_and_challenges,
                        },
                        {
                          title: 'Potential Buying Motives',
                          content:
                            activeArchetype.archetype_content
                              .potential_buying_motives,
                        },
                        {
                          title: 'Current Trends',
                          content:
                            activeArchetype.archetype_content.current_trends,
                        },
                        {
                          title: 'Source of Information',
                          content:
                            activeArchetype.archetype_content
                              .source_of_information,
                        },
                        {
                          title: 'Online Search Behavior',
                          content:
                            activeArchetype.archetype_content
                              .online_search_behavior,
                        },
                        {
                          title: 'Purchase Frequency',
                          content:
                            activeArchetype.archetype_content
                              .purchase_frequency,
                        },
                        {
                          title: 'Preferred Sales Channels',
                          content:
                            activeArchetype.archetype_content
                              .preferred_sales_channels,
                        },
                      ]}
                      title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                    />
                    <SliderContainer
                      goToIndex={goToIndex}
                      isAdding={isAdding}
                      isEditing={isEditing}
                      length={activeArchetype.challenges.length}
                      onActiveIndex={(index) => setActiveIndex(index)}
                      selectedIndexes={selectedKeyIndexes}
                    >
                      {activeArchetype.challenges.map((datum, index) => (
                        <Card
                          key={index}
                          ref={(el) => (cardRefs.current[index] = el)}
                          id={datum.id}
                          index={index}
                          isCanSelect={isEditor}
                          isEditing={isEditing || isAdding}
                          isFetching={isRefetching}
                          isSelected={!!datum.is_selected}
                          item={datum.challenges_content}
                          onSelectSuccess={onSelectSuccess}
                          onSubmitSuccess={handleFetchSubmit}
                          project={project}
                          totalData={activeArchetype.challenges.length}
                          user={user}
                        />
                      ))}
                    </SliderContainer>

                    <Actions
                      isEditing={isAdding || isEditing}
                      isEditor={isEditor}
                      onAdd={handleAddNew}
                      onCancel={isAdding ? handleCancelAdd : handleCancelEdit}
                      onSave={handleSubmit}
                      toggleEditing={toggleIsEditing}
                    />

                    {sources && <SourcesList sources={sources} />}
                  </div>
                );
              })()}
            </div>
          )}
        </TabGroup>
      </div>
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="challenge-and-task"
      />
      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="challenge-and-task"
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
