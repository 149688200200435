/* eslint-disable consistent-return */
import { useEffect } from 'react';

interface UseArchetypeProgressParams {
  activeArchetype: { id: number; is_querying: boolean } | null;
  generatingStates: Record<number, boolean>;
  refetch: () => void;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
}

export const useArchetypeProgress = ({
  activeArchetype,
  generatingStates,
  refetch,
  setProgressStates,
}: UseArchetypeProgressParams) => {
  useEffect(() => {
    if (!activeArchetype) return;

    let progressInterval: NodeJS.Timeout | undefined;

    // Progress updater: stops at 90%
    if (generatingStates[activeArchetype.id]) {
      progressInterval = setInterval(() => {
        setProgressStates((prev) => {
          const currentProgress = prev[activeArchetype.id] || 0;

          if (currentProgress >= 90) {
            clearInterval(progressInterval); // Stop only the progress updater
            return prev; // Return state unchanged
          }

          return {
            ...prev,
            [activeArchetype.id]: Math.min(currentProgress + 10, 90), // Cap at 90%
          };
        });
      }, 3000); // Update progress every 3 seconds
    }

    // Refetch updater: stops when `is_querying` becomes `false`
    const refetchInterval = setInterval(() => {
      if (!activeArchetype.is_querying) {
        clearInterval(refetchInterval); // Stop refetch if no longer querying
      } else {
        refetch();
      }
    }, 3000); // Refetch every 3 seconds

    return () => {
      if (progressInterval) clearInterval(progressInterval);
      if (refetchInterval) clearInterval(refetchInterval);
    };
  }, [activeArchetype, generatingStates, refetch, setProgressStates]);
};
