import { useEffect } from 'react';

interface SyncProgressParams {
  activeArchetype: { id: number } | null;
  projectSlug: string;
  section: string;
  progressStates: Record<number, number>;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  getProgressFunction: (
    slug: string,
    section: string,
    archetypeId: number,
  ) => number | null;
}

export const useSyncProgressFromLocalStorage = ({
  activeArchetype,
  projectSlug,
  section,
  progressStates,
  setProgressStates,
  getProgressFunction,
}: SyncProgressParams) => {
  useEffect(() => {
    if (!activeArchetype) return;

    const activeArchetypeId = activeArchetype.id;

    if (activeArchetypeId) {
      const savedProgress = getProgressFunction(
        projectSlug,
        section,
        activeArchetypeId,
      );

      if (
        savedProgress !== null &&
        progressStates[activeArchetypeId] !== savedProgress // Only update state if the saved progress is different
      ) {
        setProgressStates((prev) => ({
          ...prev,
          [activeArchetypeId]: savedProgress,
        }));
      }
    }
  }, [
    activeArchetype,
    projectSlug,
    section,
    progressStates,
    setProgressStates,
    getProgressFunction,
  ]);
};
