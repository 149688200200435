/* eslint-disable react/no-danger */
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { Fragment, useRef } from 'react';

import InputLabel from '@/Components/InputLabel';

const SectionInformation: React.FC<{
  title?: string;
  description: string;
  hasButton?: boolean;
}> = ({ title, description, hasButton = true }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className="relative">
      <PopoverButton ref={buttonRef} className="flex items-center gap-x-4">
        <Icon className="size-20 text-black-redx" icon="lucide:badge-info" />
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          className="divide-y z-30 !max-w-525 rounded-lg bg-white p-15 shadow-lg md:w-full lg:min-w-525"
          style={{
            position: 'absolute',
            top: '25px',
            left: '0px',
          }}
        >
          {({ close }) => (
            <>
              {title && (
                <InputLabel
                  className="mb-8 text-14 font-semibold !text-black-redx"
                  htmlFor="title"
                  value={title}
                />
              )}
              <p
                className=" block w-full !text-12 font-normal leading-relaxed text-grey-redx"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {hasButton && (
                <div className="mt-15 flex w-full justify-end gap-x-15">
                  <button
                    className="rounded-8 bg-blue-redx px-14 py-10 text-15 font-semibold leading-18 text-white"
                    onClick={() => {
                      close();
                    }}
                  >
                    Understand
                  </button>
                </div>
              )}
            </>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SectionInformation;
