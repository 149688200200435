// Generic function to map archetypes
export const mapArchetypes = <T extends { id: number }>(
  data: T[],
  existingArchetypes: Record<number, T>,
): Record<number, T> => {
  return data.reduce<Record<number, T>>((acc, archetype) => {
    acc[archetype.id] = {
      ...existingArchetypes[archetype.id], // Maintain existing data
      ...archetype,
    };
    return acc;
  }, {});
};

// Generic function to initialize generating states
export const initializeGeneratingStates = (
  data: any[],
): Record<number, boolean> => {
  return data.reduce<Record<number, boolean>>((acc, archetype) => {
    acc[archetype.id] = archetype.is_querying || false;
    return acc;
  }, {});
};

// Generic function to initialize progress states from local storage
export const initializeProgressStates = <T>(
  data: T[],
  getSavedProgress: Record<number, number>,
  previousState: Record<number, number> = {},
): Record<number, number> => {
  return data.reduce<Record<number, number>>((acc, archetype: any) => {
    const archetypeId = archetype.id;

    // Get saved progress for the current archetype
    const savedProgress = getSavedProgress[archetypeId] || 0;

    // Update the accumulator with either the saved progress or the previous state
    acc[archetypeId] = savedProgress || previousState[archetypeId] || 0;

    return acc;
  }, {});
};
