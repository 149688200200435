import _ from 'lodash';
import { type PropsWithChildren, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import ProjectFooter from '@/Components/TopMenu/ProjectFooter';
import ProjectHead from '@/Components/TopMenu/ProjectHead';
import Sidebar from '@/Components/TopMenu/Sidebar';
import { ModalRegenerateProvider } from '@/Context/ModalRegenerate';
import { ProjectProvider } from '@/Context/ProjectContext';
import { SidebarProvider } from '@/Context/SidebarContext';
import useProjectDetail from '@/Hooks/react-query/useProjectDetail';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';

const ProjectLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? '',
  );

  useEffect(() => {
    if (!isLoading && _.isEmpty(project)) {
      navigate('/404');
    }
  }, [project, isLoading]);

  return (
    <ProjectProvider>
      <SidebarProvider>
        <ModalRegenerateProvider>
          <AuthenticatedLayout>
            {!isLoading && project && !_.isEmpty(project) && (
              <>
                <Sidebar project={project} />
                <div className="ml-0 size-full max-h-full lg:pl-[25%]">
                  <div className="z-10 w-full px-20 pb-40  pt-73">
                    <ProjectHead project={project} />
                    <div className="pb-80 lg:pb-0"> {children}</div>
                    <ProjectFooter project={project} />
                  </div>
                </div>
              </>
            )}
          </AuthenticatedLayout>
        </ModalRegenerateProvider>

        <Toaster
          containerStyle={{
            bottom: 120,
          }}
          position="bottom-center"
          toastOptions={{
            duration: 5000,
          }}
        />
      </SidebarProvider>
    </ProjectProvider>
  );
};

export default ProjectLayout;
