/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Checkbox as HCheckbox } from '@headlessui/react';
import React from 'react';

interface CheckboxProps {
  isEditor?: boolean;
  checked: boolean;
  onChange?: (value: boolean) => void; // Callback untuk mengubah state
  label?: string; // Opsional, jika ingin menampilkan label
  className?: string; // Opsional, untuk styling tambahan
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  isEditor = true,
  checked,
  onChange,
  label,
  className = '',
  disabled = false,
}) => {
  return (
    <div
      className={`inline-flex items-center gap-8 ${className} ${
        disabled ? 'cursor-not-allowed opacity-50' : ''
      }`}
    >
      <HCheckbox
        checked={checked}
        className={`group flex size-18 items-center justify-center rounded border-2 border-solid border-gray-200 bg-white text-center data-[checked]:border-none data-[checked]:bg-blue-redx ${
          disabled
            ? 'data-[checked]:bg-soft-purple-redx'
            : 'data-[checked]:bg-blue-redx'
        }`}
        disabled={disabled || !isEditor}
        onChange={onChange}
      >
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.86339 10.5834L3.08339 7.80336L2.13672 8.74336L5.86339 12.47L13.8634 4.47003L12.9234 3.53003L5.86339 10.5834Z"
            fill="white"
          />
        </svg>
      </HCheckbox>

      {label && (
        <span
          className="cursor-pointer text-14 font-normal leading-20"
          onClick={() => !disabled && onChange?.(!checked)}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
