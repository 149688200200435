import { TabGroup } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import { useUpdateKeyInsight } from '@/Hooks/react-query/key-insight';
import useKeyInsights from '@/Hooks/react-query/key-insight/useKeyInsight';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import { useArchetypeProgress } from '@/Hooks/useArchetypeProgress';
import { useArchetypeStateUpdater } from '@/Hooks/useArchetypeStateUpdater';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useSaveProgressToLocalStorage } from '@/Hooks/useSaveProgressToLocalStorage';
import { useSyncProgressFromLocalStorage } from '@/Hooks/useSyncProgressFromLocalStorage';
import { fetcher } from '@/Services/axios';
import type {
  ArchetypeKeyInsight,
  ArchetypesState,
  SectionKeyInsight,
} from '@/Types/key_insight';
import type { KeyInsightSchema } from '@/Types/key_insight/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/projects/init-data';
import {
  getProgressFromLocalStorage,
  getSectionProgressFromLocalStorage,
  removeAllArchetypesBasedOnSectionFromLocalStorage,
  removeProgressFromLocalStorage,
  saveProgressToLocalStorage,
} from '@/Utils/projects/persist-progress';

import KeyInsightActions from './Components/Actions';
import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';
import Tabs from './Components/Tabs';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const sectionList: SectionList = {
    title: 'Key Insight',
    value: '',
    section: 'key_insights',
  };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const [state, dispatch] = useContext(ProjectContext);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } = useKeyInsights(project);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate } = useUpdateKeyInsight();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype
  const [isRegenerating, setIsReGenerating] = useState(false);
  const archetypesArray = useMemo(
    () => Object.values(archetypes),
    [archetypes],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);
  const { handleGenerateKeyInsight, handleGenerateChallengeAndTask } =
    useGenerate(dispatch);

  const { data: differentArchetypes } = useDifferentArchetypes(project);

  const { isKeyInsightsComplete, isChallengeAndTaskOfTheBrand } =
    useHistoryStatus(project, state.isEnabledHistoryStatus);

  const routeOptionValue: RouteOption = {
    label: 'Generate Challenge & Task',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  };

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(
        data.data, // Inferred type
        archetypes,
      );

      setArchetypes(mappedArchetypes);

      // Initialize generating states
      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      // Update generating states and initialize progress for new archetypes
      const savedProgress = getSectionProgressFromLocalStorage(
        project.slug,
        sectionList.value,
      );
      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));
      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);
    }
  }, [data]);

  // Refetch periodically for active archetype if isGenerating is true
  useArchetypeProgress({
    activeArchetype,
    generatingStates,
    refetch,
    setProgressStates,
  });

  // Update generating state after refetch completes
  useArchetypeStateUpdater({
    data,
    activeArchetype,
    activeTab,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  // Simpan progress ke localStorage setiap kali progressStates berubah
  useSaveProgressToLocalStorage({
    activeArchetype,
    progressStates,
    projectSlug: project.slug,
    section: sectionList.section,
    saveFunction: saveProgressToLocalStorage,
  });

  useSyncProgressFromLocalStorage({
    activeArchetype,
    projectSlug: project.slug,
    section: sectionList.section,
    progressStates,
    setProgressStates,
    getProgressFunction: getProgressFromLocalStorage,
  });

  // get progress bar
  useEffect(() => {
    if (!activeArchetype) return;
    const activeArchetypeId = activeArchetype.id;

    if (activeArchetypeId) {
      const savedProgress = getProgressFromLocalStorage(
        project.slug,
        sectionList.section,
        activeArchetypeId,
      );

      if (
        savedProgress !== null &&
        progressStates[activeArchetypeId] !== savedProgress // Hanya set state jika berbeda
      ) {
        setProgressStates((prev) => ({
          ...prev,
          [activeArchetypeId]: savedProgress,
        }));
      }
    }
  }, [activeTab, progressStates]);

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const selectedIndexes = useMemo(() => {
    return activeArchetype?.key_insights
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [activeArchetype?.key_insights]);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    payload: KeyInsightSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeArchetype?.id!, projectSlug },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Key Insight`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Key Insight  Click`,
            eventAction: 'Click',
            eventLabel: 'Key Insight',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    if (!activeArchetype) return;

    setArchetypes((prev) => {
      const currentKeyInsights = prev[activeArchetype.id]?.key_insights || [];
      const filteredKeyInsights = currentKeyInsights.filter(
        (keyInsight) => keyInsight.id !== -1,
      );

      return {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          key_insights: filteredKeyInsights,
        },
      };
    });

    toggleIsAdding();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddKeyInsight = () => {
    if (!activeArchetype) return;

    const newKeyInsight: SectionKeyInsight = {
      id: -1, // Gunakan ID sementara
      archetype_id: activeArchetype.id,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: 0,
      key_insight_content: {
        consumer: '',
        cultural: '',
        category: '',
      },
    };

    // Hitung panjang array baru
    const currentKeyInsights =
      archetypes[activeArchetype.id]?.key_insights || [];
    const newIndex = currentKeyInsights.length;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          key_insights: [...currentKeyInsights, newKeyInsight],
        },
      };
      return updatedArchetypes;
    });

    // Setelah state diperbarui, pastikan slider berpindah ke item baru
    setGoToIndex(newIndex);
    toggleIsAdding(); // Aktifkan mode "Adding"
  };

  const handleRegenerate = async () => {
    if (
      differentArchetypes?.is_different_archetype.key_insight ||
      isKeyInsightsComplete.isRegenerated
    ) {
      await handleGenerateKeyInsight()(project.slug, null);
      refetch();
      return;
    }

    setIsReGenerating(true);
    if (
      isChallengeAndTaskOfTheBrand.status ||
      isKeyInsightsComplete.isRegenerated ||
      differentArchetypes?.is_different_archetype.challenges
    ) {
      triggerGTMEvent({
        event: `Generate Challenge And Task`,
        eventCategory: `Generate Challenge And Task Click`,
        eventAction: 'Click',
        eventLabel: 'Challenge And Task',
        userId: user.email,
        data: project.slug,
      });
      await handleGenerateChallengeAndTask()(project.slug, null);
    }

    dispatch(saveInformation(''));
    navigate(`/${project.slug}/challenge-and-communication-task`);
  };

  const hasSelectedKeyInsight = (archetypesArray: ArchetypeKeyInsight[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedKeyInsight = archetypesArray
      .map((section, index) => {
        const hasSelectedKeyInsight = section.key_insights.some(
          (keyInsight) => keyInsight.is_selected === 1,
        );
        return hasSelectedKeyInsight ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyInsight.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyInsight);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedKeyInsight
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select key insight for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ['challenges', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    // TODO: Will be changed to use the API provided in ticket REDX-861
    const { data } = await fetcher.get(
      `/${project.slug}/key-insight/check-regenerate`,
    );

    if (
      data?.data.is_show_pop_up ||
      isChallengeAndTaskOfTheBrand.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.is_show_pop_up || isChallengeAndTaskOfTheBrand.isRegenerated,
      );
      return;
    }
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    await handleGenerateChallengeAndTask()(project.slug, null);
    navigate(`/${project.slug}/challenge-and-communication-task`);
  };

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      'key_insights',
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeKeyInsight>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Key Insight`,
      eventCategory: `Generate More Key Insight Click`,
      eventAction: 'Click',
      eventLabel: 'Key Insight',
      userId: user.email,
      data: prompt,
    });

    await handleGenerateKeyInsight()(project.slug, prompt);
    refetch();
    dispatch(setEnableHistoryStatus(true));
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${activeArchetype?.total_selected_key_insights ?? 0} from ${activeArchetype?.total_key_insights ?? 0} key Insight selected`,
      ),
    );

    if (activeArchetype?.is_querying) {
      dispatch(setEnableHistoryStatus(true));
    } else {
      if (activeArchetype) {
        removeProgressFromLocalStorage(
          project.slug,
          sectionList.section,
          activeArchetype.id,
        );

        setProgressStates((prev) => ({
          ...prev,
          [activeArchetype.id]: 0,
        }));
      }

      dispatch(setEnableHistoryStatus(!activeArchetype));
    }

    if (isEditor) {
      const isInactive = activeArchetype?.is_querying
        ? true
        : activeArchetype?.total_selected_key_insights === 0;

      if (
        !isChallengeAndTaskOfTheBrand.status &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        !differentArchetypes?.is_different_archetype.challenges
      ) {
        routeOptionValue.label = 'Generate Challenge & Task';
        routeOptionValue.isGenerate = true;
      }

      if (
        isChallengeAndTaskOfTheBrand.status &&
        (isChallengeAndTaskOfTheBrand.isRegenerated ||
          differentArchetypes?.is_different_archetype.challenges)
      ) {
        routeOptionValue.label = 'Regenerate Challenge & Task';
        routeOptionValue.isGenerate = true;
      }

      if (
        isChallengeAndTaskOfTheBrand.status &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        !differentArchetypes?.is_different_archetype.challenges
      ) {
        routeOptionValue.label = 'Next to Challenge & Task';
        routeOptionValue.isGenerate = false;
      }

      routeOptionValue.isInactive =
        !isKeyInsightsComplete.status || isKeyInsightsComplete.isQuerying
          ? true
          : isInactive;
    } else {
      routeOptionValue.label = 'Next to Challenge & Task';
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isChallengeAndTaskOfTheBrand.status ||
        isChallengeAndTaskOfTheBrand.isQuerying ||
        differentArchetypes?.is_different_archetype.challenges;
    }

    routeOptionValue.onClick = () => {
      if (
        !isKeyInsightsComplete.status ||
        activeArchetype?.is_querying ||
        isKeyInsightsComplete.isQuerying
      ) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait key insight is still generating`,
          ),
        );
        return;
      }

      if (
        !isEditor &&
        (!isChallengeAndTaskOfTheBrand.status ||
          differentArchetypes?.is_different_archetype.challenges)
      ) {
        setShowViewInfoModal(true);
        return;
      }

      if (isEditor && isChallengeAndTaskOfTheBrand.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, challenge & communication task is still generating`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && (isAdding || isEditing)) {
        dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && routeOptionValue.isGenerate) {
        const hasError = hasSelectedKeyInsight(archetypesArray);

        if (!hasError) {
          handleRefetchSelectedData();
        }

        return;
      }

      navigate(`/${project.slug}/challenge-and-communication-task`);
    };

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    differentArchetypes?.is_different_archetype.challenges,
    isKeyInsightsComplete.status,
    isKeyInsightsComplete.isQuerying,
    isAdding,
    isEditing,
    activeArchetype,
    isChallengeAndTaskOfTheBrand.status,
    isChallengeAndTaskOfTheBrand.isRegenerated,
    isChallengeAndTaskOfTheBrand.isQuerying,
    isEditor,
    activeTab,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/audience-archetype`);
        },
      }),
    );
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">Key Insight</h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one or more
              </span>{' '}
              key insights{' '}
              <span className="font-bold text-black-redx">
                for each archetype
              </span>
              , or add a new by clicking &apos;Add New Key Insight&apos;.
            </span>
          </div>
          {isEditor && (
            <RegenerateButton
              isGenerateMore
              isLoading={isLoading}
              limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
              maxLimit={maxGenerateLimit}
              onSubmit={(form) => handleRegenerateButton(form.prompt)}
              section="key-insight"
            />
          )}
        </div>

        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs
            activeTab={activeTab}
            archetypesArray={archetypesArray}
            errorTabIndex={errorTabIndex}
            isEditing={isEditing || isAdding}
            isLoading={isLoading}
            onActiveTab={setActiveTab}
            user={user}
          />
          {isLoading ? (
            <Skeleton project={project} user={user} />
          ) : (
            <div
              key={activeArchetype?.id || activeTab}
              className="h-full py-24"
            >
              {(() => {
                if (!activeArchetype) {
                  return null; // Jangan tampilkan apa-apa
                }

                if (activeArchetype.is_querying) {
                  return (
                    <GenerateLoading
                      progress={progressPortion({
                        progress: Number(
                          (progressStates[activeArchetype.id] || 0).toFixed(0),
                        ),
                        isQuerying: activeArchetype.is_querying,
                        isContentAvailable:
                          activeArchetype.key_insights.length > 0,
                      })}
                      project={project}
                      section={sectionList}
                      showEmailNotification={
                        activeArchetype.is_email_notification
                      }
                    />
                  );
                }

                return (
                  <div className="flex flex-col gap-20 ">
                    <SelectedCard
                      data={[
                        {
                          title: 'Archetype Name',
                          content:
                            activeArchetype.archetype_content.archetype_name,
                        },
                        {
                          title: 'Demographic',
                          content:
                            activeArchetype.archetype_content.demographics,
                        },
                        {
                          title: 'Occupation',
                          content: activeArchetype.archetype_content.occupation,
                        },
                        {
                          title: 'Lifestyle',
                          content: activeArchetype.archetype_content.lifestyle,
                        },
                        {
                          title: 'Behavior',
                          content: activeArchetype.archetype_content.behavior,
                        },
                        {
                          title: 'Need & Challenges',
                          content:
                            activeArchetype.archetype_content
                              .needs_and_challenges,
                        },
                        {
                          title: 'Potential Buying Motives',
                          content:
                            activeArchetype.archetype_content
                              .potential_buying_motives,
                        },
                        {
                          title: 'Current Trends',
                          content:
                            activeArchetype.archetype_content.current_trends,
                        },
                        {
                          title: 'Source of Information',
                          content:
                            activeArchetype.archetype_content
                              .source_of_information,
                        },
                        {
                          title: 'Online Search Behavior',
                          content:
                            activeArchetype.archetype_content
                              .online_search_behavior,
                        },
                        {
                          title: 'Purchase Frequency',
                          content:
                            activeArchetype.archetype_content
                              .purchase_frequency,
                        },
                        {
                          title: 'Preferred Sales Channels',
                          content:
                            activeArchetype.archetype_content
                              .preferred_sales_channels,
                        },
                      ]}
                      title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                    />
                    <SliderContainer
                      goToIndex={goToIndex}
                      isAdding={isAdding}
                      isEditing={isEditing}
                      length={activeArchetype.key_insights.length}
                      onActiveIndex={(index) => setActiveIndex(index)}
                      selectedIndexes={selectedIndexes}
                    >
                      {activeArchetype.key_insights.map((keyInsight, index) => (
                        <Card
                          key={index}
                          ref={(el) => (cardRefs.current[index] = el)}
                          id={keyInsight.id}
                          index={index}
                          isCanSelect={isEditor}
                          isEditing={isEditing || isAdding}
                          isFetching={isRefetching}
                          isSelected={!!keyInsight.is_selected}
                          item={keyInsight.key_insight_content}
                          onSelectSuccess={onSelectSuccess}
                          onSubmitSuccess={handleFetchSubmit}
                          project={project}
                          totalData={activeArchetype.key_insights.length}
                          user={user}
                        />
                      ))}
                    </SliderContainer>

                    <KeyInsightActions
                      isEditing={isAdding || isEditing}
                      isEditor={isEditor}
                      onAdd={handleAddKeyInsight}
                      onCancel={isAdding ? handleCancelAdd : handleCancelEdit}
                      onSave={handleSubmit}
                      toggleEditing={toggleIsEditing}
                    />

                    {sources && <SourcesList sources={sources} />}
                  </div>
                );
              })()}
            </div>
          )}
        </TabGroup>
      </div>
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="key-insight"
      />
      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="key-insight"
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
