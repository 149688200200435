import Wrapper from '@/Pages/SelectedContentIdeas/Wrapper';

const SelectedContentIdeasRoutes = [
  {
    path: '/:project_slug/selected-content-ideas',
    element: <Wrapper />,
  },
];

export default SelectedContentIdeasRoutes;
