/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Icon } from '@iconify/react';
import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import TextInput from '@/Components/TextInput';
import { ProjectContext } from '@/Context/ProjectContext';
import type { User } from '@/Types';
import type { SubmissionFormPayload } from '@/Types/form';
import { triggerGTMEvent } from '@/Utils/gtm';

interface Competitor {
  name: string;
  website: string;
}

interface CompetitorInformationProps {
  user: User;
  data: {
    competitors: Competitor[];
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isEditor: boolean;
  isFetching: boolean;
  isEditingCount: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const CompetitorInformation = forwardRef<
  HTMLDivElement,
  CompetitorInformationProps
>(
  (
    {
      user,
      data,
      setData,
      errors,
      setError,
      isActive,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
      isEditingCount,
      setIsEditingCount,
    },
    ref,
  ) => {
    const location = useLocation();
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [competitorCount, setCompetitorCount] = useState(
      data.competitors.length === 0 ? 1 : data.competitors.length,
    );
    const maxCompetitorCount = 4;
    const perseError = errors.websites?.message
      ? JSON.parse(errors.websites?.message)
      : {};

    const changeCompetitorName = (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      changeIndex: number,
    ) => {
      const { value } = e.target;
      const updatedCompetitors = [...data.competitors];
      if (updatedCompetitors[changeIndex]) {
        updatedCompetitors[changeIndex].name = value;
      } else {
        updatedCompetitors[changeIndex] = { name: value, website: '' };
      }
      setData('competitors', updatedCompetitors);
      setError('competitors', { message: '' });
    };

    const changeCompetitorWebsite = (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      changeIndex: number,
    ) => {
      const { value } = e.target;
      const updatedCompetitors = [...data.competitors];
      if (updatedCompetitors[changeIndex]) {
        updatedCompetitors[changeIndex].website = value;
      } else {
        updatedCompetitors[changeIndex] = { name: '', website: value };
      }
      setData('competitors', updatedCompetitors);
      setError('competitors', { message: '' });
    };

    const toggleIsEditing = (isEdit: boolean) => {
      setIsEditing(isEdit);
      if (setIsEditingCount) {
        const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
        setIsEditingCount(newCount);
      }
    };

    const submitForm = async () => {
      triggerGTMEvent({
        event: 'Edit Submission Competitor Information',
        eventCategory: 'Button Submission Competitor Information Click',
        eventAction: 'Click',
        eventLabel: 'Submission Competitor Information',
        userId: user.email,
        data,
      });

      setIsModalOpen(false);
      const isNotError = await onEditSection(3);

      if (!isNotError) {
        toggleIsEditing(true);
        return;
      }
      toggleIsEditing(false);
    };

    const handleAddCompetitor = () => {
      if (competitorCount + 1 <= maxCompetitorCount) {
        setCompetitorCount(competitorCount + 1);
      }
    };

    useEffect(() => {
      setCompetitorCount(
        data.competitors.length === 0 ? 1 : data.competitors.length,
      );
    }, [data.competitors]);

    useEffect(() => {
      const query = new URLSearchParams(location.search);
      const sectionParam = query.get('section');
      const sectionIndex = sectionParam ? parseInt(sectionParam, 10) : 0;

      if (!Number.isNaN(sectionIndex) && sectionIndex === 3) {
        toggleIsEditing(true);
      }
    }, []);

    return (
      <div
        ref={ref}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Competitor Information
          </h1>
          {!isFetching &&
            state.submission.is_competitor_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <button
                className=" inline-flex cursor-pointer items-center justify-center gap-8"
                id="btn-edit-submission-competitor-information"
                onClick={() => toggleIsEditing(true)}
              >
                <span className="text-14 font-semibold text-blue-redx">
                  Edit
                </span>
                <Icon
                  className="size-16 text-blue-redx"
                  icon="lucide:pencil-line"
                />
              </button>
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className="inline-flex gap-8">
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                id="btn-cancel-edit-submission-competitor-information"
                onClick={() => {
                  fetchData();
                  toggleIsEditing(false);
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-16" icon="lucide:x" />
              </button>

              <button
                className="inline-flex cursor-pointer items-center gap-x-4"
                id="btn-edit-submission-competitor-information"
                onClick={() => setIsModalOpen(true)}
              >
                <p className="text-14 font-semibold text-blue-redx">Save</p>
                <Icon className="size-16 text-blue-redx" icon="lucide:save" />
              </button>
            </div>
          )}
        </div>
        <div className="grid w-full grid-cols-2 gap-24 ">
          {isFetching
            ? Array.from({ length: 3 }).map((_, index) => (
                <Fragment key={index}>
                  <div className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
                  <div
                    key={index}
                    className="mt-8 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx"
                  />
                </Fragment>
              ))
            : [...Array(competitorCount)].map((_, index) => (
                <Fragment key={index}>
                  <div className="">
                    <InputLabel
                      className="!text-black-redx"
                      htmlFor={`competitor_name${index + 1}`}
                      value={`Competitor Name ${index + 1} (Optional)`}
                    />
                    {(!state.submission.is_competitor_information_complete ||
                      isEditing) &&
                    isEditor ? (
                      <TextInput
                        className="mt-1 block w-full !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        disabled={!isActive}
                        id={`competitor_name${index + 1}`}
                        isFocused
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >,
                        ) => changeCompetitorName(e, index)}
                        placeholder="Input your competitor name here"
                        type="text"
                        value={data.competitors[index]?.name || ''}
                      />
                    ) : (
                      <p className="px-0 py-6 !text-14">
                        {data.competitors[index]?.name || '-'}
                      </p>
                    )}
                  </div>
                  <div className="">
                    <InputLabel
                      className="!text-black-redx"
                      htmlFor={`competitor_website${index + 1}`}
                      value={`Competitor Website ${index + 1} (Optional)`}
                    />
                    {(!state.submission.is_competitor_information_complete ||
                      isEditing) &&
                    isEditor ? (
                      <TextInput
                        className="mt-1 block w-full !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        disabled={!isActive}
                        error={perseError?.[index] ?? ''}
                        id={`competitor_website${index + 1}`}
                        isFocused
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >,
                        ) => changeCompetitorWebsite(e, index)}
                        placeholder="Input your competitor website here"
                        type="text"
                        value={data.competitors[index]?.website || ''}
                      />
                    ) : (
                      <p className="px-0 py-6 !text-14">
                        {data.competitors[index]?.website || '-'}
                      </p>
                    )}
                    {perseError?.[index] && (
                      <InputError
                        className="mt-2"
                        message={perseError?.[index]}
                      />
                    )}
                  </div>
                </Fragment>
              ))}
        </div>
        {(!state.submission.is_competitor_information_complete || isEditing) &&
          isActive &&
          !isFetching && (
            <div className="mb-24 flex w-full items-center justify-end gap-x-15">
              {competitorCount < maxCompetitorCount ? (
                <button
                  className="mt-10 inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                  onClick={() => handleAddCompetitor()}
                >
                  <p className="text-14 font-semibold text-blue-redx">
                    Add Competitor
                  </p>
                  <Icon
                    className="size-16 text-blue-redx"
                    icon="material-symbols:add"
                  />
                </button>
              ) : (
                <div className="mt-10 inline-flex cursor-not-allowed items-center gap-x-4 text-grey-redx">
                  <p className="text-grey text-14 font-semibold">
                    Add Competitor
                  </p>
                  <Icon
                    className="size-16 text-grey-redx"
                    icon="material-symbols:add"
                  />
                </div>
              )}
            </div>
          )}
        <RegenerateModal
          handleSubmit={submitForm}
          isOpen={isModalOpen}
          isRegenerating={false}
          onClose={() => {
            fetchData();
            toggleIsEditing(false);
            setIsModalOpen(false);
          }}
          section="competitor-analysis"
        />
      </div>
    );
  },
);

export default CompetitorInformation;
