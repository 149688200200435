/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import Breadcrumb from '@/Components/TopMenu/Breadcrumb';
import {
  saveInformation,
  saveProjectData,
  saveRouteNext,
  saveRoutePrev,
  saveSubmissionData,
  setMarketResearchLoading,
  setProductResearchLoading,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import { useMounted } from '@/Hooks/useMounted';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type { SubmissionFormPayload } from '@/Types/form';
import type { SubmissionResponse } from '@/Types/submission';
import { triggerGTMEvent } from '@/Utils/gtm';

import { validateForm } from './Helpers/validation';
import AudienceArchetype from './Partials/AudienceArchetype';
import CompetitorInformation from './Partials/CompetitorInformation';
import ErrorToast from './Partials/ErrorToast';
import MarketInformation from './Partials/MarketInformation';
import ProductInformation from './Partials/ProductInformation';
import ProjectObjective from './Partials/ProjectObjective';

const Index = ({ data }: { data: SubmissionResponse }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditingCount, setIsEditingCount] = useState(0);
  const navigate = useNavigate();
  const { data: user } = useUser();
  const [isFetching, setIsFetching] = useState(true);
  const [fetchingSection, setFetchingSection] = useState('');
  const { roles } = useRolesBadge(data.project, user);
  const location = useLocation();
  const isEditor = roles.includes('Strategist') || roles.includes('Owner');
  const [activeSection, setActiveSection] = useState(0);
  const {
    handleGenerateCompetitorAnalysis,
    handleGenerateProductResearchProductDescription,
    handleGenerateProductResearchMainFeatures,
    handleGenerateProductResearchMarcommHighlights,
    handleGenerateMarketDemand,
    handleGenerateMarketTrends,
    handleGenerateTargetAudienceAudienceInsight,
    handleGenerateTargetAudienceBehavioralTrend,
    handleGenerateTargetAudienceMediaConsumptionPatterns,
    handleGenerateTargetAudiencePerceptionAnalysis,
  } = useGenerate(dispatch);
  const {
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
    isCompetitorAnalysisComplete,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isPending: isPendingHistory,
    isFetched: isFetchedHistory,
  } = useHistoryStatus(data.project);
  const isMounted = useMounted();
  const formSections = [
    'Product Information',
    'Market Information',
    'Target Audience',
    'Competitor Information',
    'Campaign Objective',
  ];

  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useMemo(
    () => formSections.map(() => createRef<HTMLDivElement>()),
    [],
  );

  const {
    setValue,
    getValues,
    formState: { errors },
    reset,
    setError,
    watch,
  } = useForm<SubmissionFormPayload>({
    defaultValues: {
      product_name: '',
      brand_name: '',
      product_links: '',
      product_category: '',
      product_subcategory: '',
      age_market_start: '',
      age_market_end: '',
      gender: '',
      competitors: [],
      objective: '',
      second_objective: '',
      kpi: '',
    },
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sectionParam = query.get('section');

    if (sectionParam && !Number.isNaN(sectionParam) && isMounted) {
      const sectionIndex = +sectionParam;

      if (sectionRefs[sectionIndex]?.current && activeSection > 3) {
        sectionRefs[sectionIndex].current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [sectionRefs, isMounted, activeSection]);

  useEffect(() => {
    // Set the loading state to true before setting values
    setIsFetching(true);

    // Simulate setting values (e.g., from an API call)
    setValue('product_name', state.submission.product_name ?? '');
    setValue('brand_name', state.submission.brand_name ?? '');
    setValue('product_links', state.submission.product_website ?? '');
    setValue(
      'product_category',
      state.submission.category?.parent
        ? state.submission.category.parent.id.toString()
        : '',
    );
    setValue(
      'product_subcategory',
      state.submission.category ? state.submission.category.id.toString() : '',
    );
    setValue(
      'age_market_start',
      state.submission.age_market_start
        ? state.submission.age_market_start.toString()
        : '',
    );
    setValue(
      'age_market_end',
      state.submission.age_market_end
        ? state.submission.age_market_end.toString()
        : '',
    );
    setValue('gender', state.submission.gender ?? '');
    setValue('competitors', state.submission.competitors ?? []);
    setValue('objective', state.submission.objective ?? '');
    setValue('second_objective', state.submission.second_objective ?? '');
    setValue('kpi', state.submission.kpi ?? '');

    setTimeout(() => {
      setIsFetching(false);
    }, 1000);
  }, []);

  watch();

  const [categoriesList] = useState<{ title: string; value: number }[]>(
    data.categories.map((item: any) => {
      return {
        title: item.name,
        value: item.id,
      };
    }),
  );
  const [subcategoriesList, setSubcategoriesList] = useState<
    { title: string; value: number }[]
  >([]);
  const [genderList] = useState([
    {
      title: 'Male',
      value: 'Male',
      isChecked: false,
    },
    {
      title: 'Female',
      value: 'Female',
      isChecked: false,
    },
  ]);

  const filterSubCategoriesList = (category: string) => {
    if (category === '') {
      return [];
    }

    const subcategoriesList = data.subcategories
      .filter((item: any) => +item.parent_id === +category)
      .map((item: any) => {
        return {
          title: item.name,
          value: item.id,
        };
      });

    return subcategoriesList;
  };

  const handleRouteNext = () => {
    const isIncomplete = [
      !state.submission.is_product_information_complete,
      !state.submission.is_market_information_complete,
      !state.submission.is_audience_information_complete,
      !state.submission.is_competitor_information_complete,
      !state.submission.is_objective_information_complete,
    ][activeSection];

    const onClick = isIncomplete
      ? async () => handleNextSection()
      : () => {
          sectionRefs[activeSection + 1].current?.scrollIntoView({
            behavior: 'smooth',
          });
          setActiveSection(activeSection + 1);
        };

    const label =
      activeSection === formSections.length - 1
        ? 'Next to Product Research'
        : 'Next';

    const onClickRoute =
      activeSection === formSections.length - 1 &&
      state.submission.is_objective_information_complete
        ? () => navigate(`/${data.project.slug}/product-research`)
        : onClick;

    let isDisabled = false;
    if (isEditingCount > 0) {
      isDisabled = true;
    }

    return dispatch(
      saveRouteNext({
        id:
          label === 'Next to Product Research'
            ? 'btn-next-to-product-research'
            : 'btn-submission',
        label,
        isActive: true,
        isDisabled,
        onClick: onClickRoute,
      }),
    );
  };

  const handleRoutePrev = () => {
    if (activeSection !== 0) {
      return dispatch(
        saveRoutePrev({
          id: 'btn=back',
          label: 'Back',
          isActive: true,
          onClick: () => {
            sectionRefs[activeSection - 1].current?.scrollIntoView({
              behavior: 'smooth',
            });
            setActiveSection(activeSection - 1);
          },
        }),
      );
    }
    return dispatch(
      saveRoutePrev({
        id: 'btn=back',
        label: 'Back',
        isActive: false,
        onClick: () => {},
      }),
    );
  };

  const changeInformation = () => {
    if (activeSection < formSections.length - 1) {
      return dispatch(
        saveInformation(
          `Click next to continue to ${formSections[
            activeSection + 1
          ].toLowerCase()}`,
        ),
      );
    }
    return dispatch(saveInformation(''));
  };

  const checkActiveSection = () => {
    let activateSection = 0;
    if (state.submission.is_product_information_complete) {
      activateSection = 1;
    }
    if (state.submission.is_market_information_complete) {
      activateSection = 2;
    }
    if (state.submission.is_audience_information_complete) {
      activateSection = 3;
    }
    if (state.submission.is_competitor_information_complete) {
      activateSection = 4;
    }
    if (state.submission.is_objective_information_complete) {
      activateSection = 4;
    }
    return setActiveSection(activateSection);
  };

  useEffect(() => {
    dispatch(
      setProductResearchLoading({
        status: false,
        progress: 0,
      }),
    );
    dispatch(
      setMarketResearchLoading({
        status: false,
        progress: 0,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setSubcategoriesList(
      filterSubCategoriesList(getValues('product_category')),
    );
  }, [getValues('product_category')]);

  useEffect(() => {
    handleRouteNext();
    handleRoutePrev();
    changeInformation();
  }, [activeSection, errors, dispatch, isEditingCount]);

  useEffect(() => {
    if (sectionContainerRef) sectionContainerRef.current?.scrollTo(0, 0);
  }, [activeSection]);

  useEffect(() => {
    fetchData();
    checkActiveSection();
  }, [data.project, dispatch]);

  useEffect(() => {
    checkActiveSection();
  }, [state.submission]);

  const fetchData = async () => {
    dispatch(saveProjectData(data.project));
    const submissionStatus = await fetcher
      .get(`/${data.project.slug}/submission/status`)
      .then((response) => response.data);

    const updatedSubmissionData = {
      ...data.project.submissions[0],
      ...submissionStatus,
      competitors: submissionStatus.submission.competitors,
    };
    dispatch(saveSubmissionData(updatedSubmissionData));

    reset({
      product_name: updatedSubmissionData.product_name ?? '',
      brand_name: updatedSubmissionData.brand_name ?? '',
      product_links: updatedSubmissionData.product_website ?? '',
      product_category:
        updatedSubmissionData.category?.parent?.id.toString() ?? '',
      product_subcategory: updatedSubmissionData.category?.id.toString() ?? '',
      age_market_start:
        updatedSubmissionData.age_market_start?.toString() ?? '',
      age_market_end: updatedSubmissionData.age_market_end?.toString() ?? '',
      gender: updatedSubmissionData.gender ?? '',
      competitors: updatedSubmissionData.competitors ?? [],
      objective: updatedSubmissionData.objective ?? '',
      second_objective: updatedSubmissionData.second_objective ?? '',
      kpi: updatedSubmissionData.kpi ?? '',
    });
  };

  const handleNextSection = async () => {
    try {
      const isNotError = await handleSubmitSection(activeSection);
      if (!isNotError) {
        return;
      }

      if (activeSection === formSections.length - 1) {
        navigate(`/${data.project.slug}/product-research`);
        return;
      }

      setActiveSection(activeSection + 1);
      sectionRefs[activeSection + 1].current?.scrollIntoView({
        behavior: 'smooth',
      });
    } catch (error) {
      toast.custom((t) => (
        <ErrorToast
          handleClick={() => {
            toast.remove();
            handleSubmitSection(activeSection);
          }}
          t={t}
        />
      ));
    }
  };

  const handleSubmitSection = async (sectionIndex: number) => {
    const isError = await validateForm(sectionIndex, setError, getValues());
    if (isError) {
      return false;
    }
    const routeMap: Record<number, string> = {
      0: '/submission/product-information',
      1: '/submission/market-information',
      2: '/submission/audience-archetype',
      3: '/submission/competitors',
      4: '/submission/project-objective',
    };
    const routeKey = routeMap[sectionIndex];
    submissionSave(routeKey, sectionIndex);
    return true;
  };

  const submissionSave = async (routeKey: string, sectionIndex: number) => {
    try {
      const submissionData = await fetcher
        .put(`/${data.project.slug}${routeKey}`, getValues())
        .then((response) => response.data);
      const submissionStatus = await fetcher
        .get(`/${data.project.slug}/submission/status`)
        .then((response) => response.data);

      const updatedSubmissionData = {
        ...submissionData,
        ...submissionStatus,
      };
      dispatch(saveSubmissionData(updatedSubmissionData));
      if (sectionIndex === 0) {
        triggerGTMEvent({
          event: 'Generate Product Research',
          eventCategory: 'Button Generate Product Research Click',
          eventAction: 'Click',
          eventLabel: 'Generate Product Research',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateProductResearchProductDescription()(data.project.slug);

        triggerGTMEvent({
          event: 'Generate Main Features',
          eventCategory: 'Button Generate Main Features Click',
          eventAction: 'Click',
          eventLabel: 'Generate Main Features',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateProductResearchMainFeatures()(data.project.slug);

        triggerGTMEvent({
          event: 'Generate Marcomm Highlight',
          eventCategory: 'Button Generate Marcomm Highlight Click',
          eventAction: 'Click',
          eventLabel: 'Generate Marcomm Highlight',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateProductResearchMarcommHighlights()(data.project.slug);
      }
      if (sectionIndex === 1) {
        triggerGTMEvent({
          event: 'Generate Market Demand',
          eventCategory: 'Button Generate Market Demand Click',
          eventAction: 'Click',
          eventLabel: 'Generate Market Demand',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateMarketDemand()(data.project.slug);

        triggerGTMEvent({
          event: 'Generate Market Trend',
          eventCategory: 'Button Generate Market Trend Click',
          eventAction: 'Click',
          eventLabel: 'Generate Market Trend',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateMarketTrends()(data.project.slug);
      }
      if (sectionIndex === 2) {
        triggerGTMEvent({
          event: 'Generate Audience Insight',
          eventCategory: 'Button Generate Audience Insight Click',
          eventAction: 'Click',
          eventLabel: 'Generate Audience Insight',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateTargetAudienceAudienceInsight()(data.project.slug);

        triggerGTMEvent({
          event: 'Generate Behavioral Trend',
          eventCategory: 'Button Generate Behavioral Trend Click',
          eventAction: 'Click',
          eventLabel: 'Generate Behavioral Trend',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateTargetAudienceBehavioralTrend()(data.project.slug);

        triggerGTMEvent({
          event: 'Generate Media Consumption Pattern',
          eventCategory: 'Button Generate Media Consumption Pattern Click',
          eventAction: 'Click',
          eventLabel: 'Generate Media Consumption Pattern',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateTargetAudienceMediaConsumptionPatterns()(
          data.project.slug,
        );

        triggerGTMEvent({
          event: 'Generate Perception Analysis',
          eventCategory: 'Button Generate Perception Analysis Click',
          eventAction: 'Click',
          eventLabel: 'Generate Perception Analysis',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateTargetAudiencePerceptionAnalysis()(data.project.slug);
      }
      if (sectionIndex === 3) {
        triggerGTMEvent({
          event: 'Generate Competitor Analysis',
          eventCategory: 'Button Generate Competitor Analysis Click',
          eventAction: 'Click',
          eventLabel: 'Generate Competitor Analysis',
          userId: user.email,
          data: data.project.slug,
        });

        handleGenerateCompetitorAnalysis()(data.project.slug);
      }
      return true;
    } catch (error: any) {
      setActiveSection(activeSection);
      toast.custom((t) => {
        return (
          <ErrorToast
            handleClick={() => {
              toast.remove();
              handleSubmitSection(activeSection);
            }}
            message={error?.response?.data?.message}
            t={t}
          />
        );
      });
      return false; // Return false saat terjadi error
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    sectionRefs[index].current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!isPendingHistory) {
      if (isFetchedHistory) {
        !isTargetAudienceAudienceInsight.isQuerying &&
          isTargetAudienceAudienceInsight.isRegenerated &&
          handleGenerateTargetAudienceAudienceInsight()(data.project.slug);

        !isTargetAudienceMediaConsumptionPattern.isQuerying &&
          isTargetAudienceMediaConsumptionPattern.isRegenerated &&
          handleGenerateTargetAudienceMediaConsumptionPatterns()(
            data.project.slug,
          );

        !isTargetAudienceBehavioralTrend.isQuerying &&
          isTargetAudienceBehavioralTrend.isRegenerated &&
          handleGenerateTargetAudienceBehavioralTrend()(data.project.slug);

        !isTargetAudiencePerceptionAnalysis.isQuerying &&
          isTargetAudiencePerceptionAnalysis.isRegenerated &&
          handleGenerateTargetAudiencePerceptionAnalysis()(data.project.slug);

        !isCompetitorAnalysisComplete.isQuerying &&
          isCompetitorAnalysisComplete.isRegenerated &&
          handleGenerateCompetitorAnalysis()(data.project.slug);

        !isMarketResearchDemand.isQuerying &&
          isMarketResearchDemand.isRegenerated &&
          handleGenerateMarketDemand()(data.project.slug);

        !isMarketResearchTrend.isQuerying &&
          isMarketResearchTrend.isRegenerated &&
          handleGenerateMarketTrends()(data.project.slug);
      }
    }
  }, [
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
    isCompetitorAnalysisComplete,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isPendingHistory,
    isFetchedHistory,
  ]);
  return (
    <>
      <title title="Submission" />

      <div className="flex size-full flex-col py-24">
        <Breadcrumb
          index={activeSection}
          list={formSections}
          onBreadCrumbClick={handleBreadcrumbClick}
          sectionContainerRef={sectionContainerRef}
          sectionRefs={sectionRefs}
        />
        <div
          ref={sectionContainerRef}
          className="overflow-custom relative max-h-538 flex-1 overflow-y-auto pb-80 pr-20"
        >
          <ProductInformation
            ref={sectionRefs[0]}
            data={getValues()}
            errors={errors}
            fetchData={async () => {
              setFetchingSection('product_information');
              await fetchData();
              setFetchingSection('');
            }}
            isActive={!(activeSection < 0)}
            isEditingCount={isEditingCount}
            isEditor={isEditor}
            isFetching={isFetching || fetchingSection === 'product_information'}
            onEditSection={handleSubmitSection}
            setData={setValue}
            setError={setError}
            setIsEditingCount={setIsEditingCount}
            user={user}
          />
          <MarketInformation
            ref={sectionRefs[1]}
            categories={categoriesList}
            data={getValues()}
            errors={errors}
            fetchData={async () => {
              setFetchingSection('market_information');
              await fetchData();
              setFetchingSection('');
            }}
            isActive={!(activeSection < 1)}
            isEditingCount={isEditingCount}
            isEditor={isEditor}
            isFetching={isFetching || fetchingSection === 'market_information'}
            onEditSection={handleSubmitSection}
            setData={setValue}
            setError={setError}
            setIsEditingCount={setIsEditingCount}
            subcategories={subcategoriesList}
            user={user}
          />
          <AudienceArchetype
            ref={sectionRefs[2]}
            data={getValues()}
            errors={errors}
            fetchData={async () => {
              setFetchingSection('audience_archetype');
              await fetchData();
              setFetchingSection('');
            }}
            genders={genderList}
            isActive={!(activeSection < 2)}
            isEditingCount={isEditingCount}
            isEditor={isEditor}
            isFetching={isFetching || fetchingSection === 'audience_archetype'}
            onEditSection={handleSubmitSection}
            setData={setValue}
            setError={setError}
            setIsEditingCount={setIsEditingCount}
            user={user}
          />
          <CompetitorInformation
            ref={sectionRefs[3]}
            data={getValues()}
            errors={errors}
            fetchData={async () => {
              setFetchingSection('competitor');
              await fetchData();
              setFetchingSection('');
            }}
            isActive={!(activeSection < 3)}
            isEditingCount={isEditingCount}
            isEditor={isEditor}
            isFetching={isFetching || fetchingSection === 'competitor'}
            onEditSection={handleSubmitSection}
            setData={setValue}
            setError={setError}
            setIsEditingCount={setIsEditingCount}
            user={user}
          />
          <ProjectObjective
            ref={sectionRefs[4]}
            data={getValues()}
            errors={errors}
            fetchData={async () => {
              setFetchingSection('objective');
              await fetchData();
              setFetchingSection('');
            }}
            isActive={!(activeSection < 4)}
            isEditingCount={isEditingCount}
            isEditor={isEditor}
            isFetching={isFetching || fetchingSection === 'objective'}
            onEditSection={handleSubmitSection}
            setData={setValue}
            setError={setError}
            setIsEditingCount={setIsEditingCount}
            user={user}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
