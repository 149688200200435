import { Icon } from '@iconify/react';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SectionInformation from '@/Components/Projects/SectionInformation';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useIdeaAlignments from '@/Hooks/react-query/idea-alignment/useIdeaAlignments';
import { useSubmitIdeaAlignment } from '@/Hooks/react-query/idea-alignment/useSubmit';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { IdeaAlignmentList } from '@/Types/creative-idea-alignment';
import type { Schema } from '@/Types/creative-idea-alignment/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [state, dispatch] = useContext(ProjectContext);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [parsedData, setParsedData] = useState<IdeaAlignmentList[]>([]);
  const [editedData, setEditedData] = useState<IdeaAlignmentList[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [isGenerate, setIsGenerate] = useState(false);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);

  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [totalSelectedIdeaAlignment, setTotalSelectedIdeaAlignment] =
    useState<number>(0);
  const [totalIdeaAlignment, setTotalIdeaAlignment] = useState<number>(0);

  const { data: user } = useUser();

  const { roles } = useRolesBadge(project, user);
  const { handleGenerateIdeaAlignment, handleGenerateIdeaPersonalization } =
    useGenerate(dispatch);
  const { data, isLoading, refetch, isRefetching } = useIdeaAlignments(
    project.slug,
  );

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const { mutate, isPending } = useSubmitIdeaAlignment();

  const {
    isIdeaAlignment,
    isIdeaPersonalization,
    isUpdateState: isPendingHistoryStatus,
    refetch: refetchHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const isEditor = roles.includes('Owner') || roles.includes('Creative');

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const routeOptionValue: RouteOption = {
    label: 'Generate Idea Personalization',
    isActive: true,
    isGenerate: true,
    isDisabled: true,
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();
  };

  const handleFetchSubmit = async (
    payload: Schema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, projectSlug, id },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Idea Alignment`,
            eventCategory: `Button ${id === -1 ? 'add' : 'update'} Idea Alignment Click`,
            eventAction: 'Click',
            eventLabel: 'Idea Alignment',
            userId: user.email,
            data: payload,
          });

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);
        },
      },
    );
  };

  const handleRegenerateButton = (data: { prompt: string }) => {
    triggerGTMEvent({
      event: `Generate More Idea Alignment`,
      eventCategory: `Generate More Idea Alignment Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Alignment',
      userId: user.email,
      data: { slug: project.slug, prompt },
    });

    dispatch(saveSubInformation(''));
    setParsedData([]);
    setEditedData([]);
    setIsGenerate(true);
    handleGenerateIdeaAlignment()(project.slug, data.prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const onSelectSuccess = async () => {
    refetch().then((data) => {
      const idea = data.data;
      if (idea) {
        refetchHistory().then((data) => {
          const histories = data.data;
          if (histories) {
            const { data: history } = histories;
            if (
              history.idea_personalization.isRegenerated &&
              idea.data.total_selected_idea_alignment
            ) {
              setShowAlertGenerateModal(true);
            }
          }
        });
      }
    });
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleRegenerate = async () => {
    setIsReGenerating(true);
    await handleGenerateIdeaPersonalization()(project.slug, null);
    setIsReGenerating(false);
    navigate(`/${project.slug}/idea-personalization`);
  };

  useEffect(() => {
    if (data?.data) {
      const {
        lists,
        sources,
        total_regenerate: totalRegenerate,
        is_querying: isQuerying,
      } = data.data;
      sources && setSources(sources);
      totalRegenerate && setTotalRegenerate(totalRegenerate);
      isQuerying && setIsGenerate(true);

      if (lists?.length && !isQuerying) {
        setParsedData(lists);
        setEditedData(lists);
        setIsGenerate(false);
      } else {
        setParsedData([]);
        setEditedData([]);
      }
    }

    const totalIdea = data?.data?.total_idea_alignment || 0;
    const totalSelected = data?.data?.total_selected_idea_alignment || 0;

    setTotalIdeaAlignment(totalIdea);
    setTotalSelectedIdeaAlignment(totalSelected);

    dispatch(saveSubInformation(''));
  }, [data, isIdeaAlignment]);

  useEffect(() => {
    if (isIdeaAlignment.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (parsedData?.length) {
      const selectedDataWithIndex = parsedData
        .map((item, index) => ({ item, index })) // Map to array of objects with item and index
        .filter(({ item }) => item.is_selected);

      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);
    }

    if (!isGenerate && !isIdeaAlignment.status && !isIdeaAlignment.isQuerying) {
      setIsGenerate(true);
      triggerGTMEvent({
        event: `Generate Idea Alignment`,
        eventCategory: `Button Generate Idea Alignment Click`,
        eventAction: 'Click',
        eventLabel: 'Idea Alignment',
        userId: user.email,
        data: project.slug,
      });
      handleGenerateIdeaAlignment()(project.slug);
    }
  }, [isIdeaAlignment, isGenerate, parsedData]);

  useEffect(() => {
    !isIdeaAlignment.isQuerying && !isPendingHistoryStatus && refetch();
    isIdeaAlignment.isQuerying &&
      !isPendingHistoryStatus &&
      setIsGenerate(true);
  }, [isGenerate, isIdeaAlignment, isGenerate, isPendingHistoryStatus]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/creative-idea`),
      }),
    );
  }, []);

  useEffect(() => {
    if (!isEditor) {
      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive:
            isGenerate ||
            !isIdeaPersonalization.status ||
            isIdeaPersonalization.isQuerying ||
            (isIdeaPersonalization.status &&
              differentArchetypes?.is_different_archetype
                .idea_personalizations),
          isDisabled: false,
          label: 'Next to Idea Personalization',
          isGenerate: false,
          onClick: () => {
            if (isIdeaAlignment.isQuerying || isGenerate) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea alignment is generating`,
                ),
              );
              return;
            }

            if (
              !isIdeaPersonalization.status ||
              (isIdeaPersonalization.status &&
                differentArchetypes?.is_different_archetype
                  .idea_personalizations)
            ) {
              setShowViewInfoModal(true);
              return;
            }

            navigate(`/${project.slug}/idea-personalization`);
          },
        }),
      );
      return;
    }
    const count = totalSelectedIdeaAlignment || 0;

    if (count === 0) {
      dispatch(saveInformation(`No idea alignment is selected`));
      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive: true,
          isDisabled: false,
          label:
            isIdeaPersonalization.status &&
            (differentArchetypes?.is_different_archetype
              .idea_personalizations ||
              isIdeaPersonalization.isRegenerated)
              ? 'Regenerate Idea Personalization'
              : 'Generate Idea Personalization',
          onClick: () => {
            if (
              isIdeaAlignment.isQuerying ||
              isGenerate ||
              !isIdeaAlignment.status
            ) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea alignment is generating`,
                ),
              );
              return;
            }
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please select 1 idea alignment`,
              ),
            );
          },
        }),
      );
      return;
    }
    dispatch(saveInformation(`Idea alignment is selected`));
    dispatch(saveSubInformation(``));

    routeOptionValue.isInactive = !!(
      isGenerate ||
      isIdeaAlignment.isQuerying ||
      !isIdeaAlignment.status
    );

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    if (!isPendingHistoryStatus && !isPendingDiffArch) {
      if (
        isIdeaPersonalization.status &&
        !isIdeaPersonalization.isRegenerated &&
        !differentArchetypes?.is_different_archetype.idea_personalizations
      ) {
        dispatch(
          saveRouteNext({
            ...routeOptionValue,
            isGenerate: false,
            label: 'Next to Idea Personalization',
            isDisabled: count === 0,
            onClick: () => {
              if (
                isIdeaAlignment.isQuerying ||
                isGenerate ||
                !isIdeaAlignment.status
              ) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea alignment is generating`,
                  ),
                );
                return;
              }

              if (isIdeaPersonalization.isQuerying) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea personalization is still generating`,
                  ),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(''));
                }, 3000);
                return;
              }

              if (isEditor && (isAdding || isEditing)) {
                dispatch(
                  saveSubInformation(`{text-error-redx} Please do save first`),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(''));
                }, 3000);
                return;
              }

              queryClient.resetQueries({
                queryKey: ['ideaPersonalization', project.slug],
                exact: true,
              });
              navigate(`/${project.slug}/idea-personalization`);
            },
          }),
        );
        return;
      }
      if (
        isIdeaPersonalization.status &&
        (differentArchetypes?.is_different_archetype.idea_personalizations ||
          isIdeaPersonalization.isRegenerated)
      ) {
        dispatch(
          saveRouteNext({
            ...routeOptionValue,
            isInactive: !!(
              isGenerate ||
              isIdeaAlignment.isQuerying ||
              !isIdeaAlignment.status
            ),
            isGenerate: true,
            isDisabled: count === 0,
            label: 'Regenerate Idea Personalization',
            onClick: () => {
              if (
                isIdeaAlignment.isQuerying ||
                isGenerate ||
                !isIdeaAlignment.status
              ) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea alignment is generating`,
                  ),
                );
                return;
              }

              if (isIdeaPersonalization.isQuerying) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea personalization is still generating`,
                  ),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(''));
                }, 3000);
                return;
              }

              setShowRegenerateModal(true);
            },
          }),
        );
        return;
      }

      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isGenerate: true,
          isDisabled: count === 0,
          label: 'Generate Idea Personalization',
          onClick: async () => {
            if (isIdeaAlignment.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea alignment is generating`,
                ),
              );
              return;
            }

            if (isIdeaPersonalization.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea personalization is still generating`,
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (isAdding || isEditing) {
              dispatch(
                saveSubInformation(`{text-error-redx} Please do save first`),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            navigate(`/${project.slug}/idea-personalization`);
          },
        }),
      );
    }
  }, [
    isAdding,
    isEditing,
    isIdeaAlignment,
    isIdeaPersonalization,
    isPendingHistoryStatus,
    selectedIndex,
    totalSelectedIdeaAlignment,
    totalIdeaAlignment,
    isPendingDiffArch,
    differentArchetypes,
    isGenerate,
    isEditor,
    isAdding,
    isEditing,
  ]);

  const sectionList: SectionList = {
    title: 'idea alignment',
    value: 'alignment',
    section: 'idea',
  };

  useEffect(() => {
    if (parsedData.length === 0 || isIdeaAlignment.isQuerying) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [isAdding, isEditing, parsedData, isIdeaAlignment.isQuerying]);

  return (
    <>
      <Helmet>
        <title>Idea Alignment</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.ideaAlignment.progress / 2).toFixed(0)),
            isQuerying: isIdeaAlignment.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isIdeaAlignment.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
            <div className="flex grow flex-col">
              <h1 className="mb-8 inline-flex items-center gap-12 text-25 font-bold leading-none">
                Idea Alignment
                <SectionInformation
                  description="Ainstein will align your initial idea with Target Audience Insight, Cultural Insight, Project Objective, and Competitor Analysis."
                  title="What did Ainstein do with Idea Alignment?"
                />
              </h1>
              <span className="text-1620 font-normal text-grey-redx">
                Please{' '}
                <span className="font-bold text-black-redx">
                  select one idea{' '}
                </span>{' '}
                , edit, or add by clicking &apos;Add Idea Manually&apos;.
              </span>
            </div>
            {isEditor && (
              <div className="flex items-center">
                <RegenerateButton
                  isGenerateMore
                  limit={totalRegenerate}
                  maxLimit={project.max_generated_data} // to do ganti ke max limit
                  onSubmit={handleRegenerateButton}
                  section="idea-alignment"
                />
              </div>
            )}
          </div>
          {!isLoading && !isIdeaAlignment.isQuerying ? (
            <SliderContainer
              goToIndex={goToIndex}
              isAdding={isAdding}
              isEditing={isEditing}
              length={parsedData.length}
              onActiveIndex={(index) => setActiveIndex(index)}
              selectedIndexes={selectedIndex}
            >
              {parsedData.map((ideaAlignment, index) => (
                <Card
                  key={index}
                  ref={(el) => (cardRefs.current[index] = el)}
                  id={ideaAlignment.id ?? 0}
                  ideaAlignment={
                    ideaAlignment?.idea_alignment_content ?? {
                      idea_title: '',
                      idea_description: '',
                      reason: '',
                    }
                  }
                  index={index}
                  isEdited={ideaAlignment?.is_edit ?? false}
                  isEditing={isEditing && isEditor}
                  isEditor={isEditor}
                  isFetching={isRefetching}
                  isSelected={!!ideaAlignment.is_selected}
                  onSelectSuccess={onSelectSuccess}
                  onSubmitSuccess={handleFetchSubmit}
                  project={project}
                  totalIdeaAlignment={parsedData.length}
                  user={user}
                />
              ))}

              {/* Add a new card for adding new archetype if the user is in editor mode */}
              {isEditor && isAdding && (
                <Card
                  ref={(el) => (cardRefs.current[parsedData.length] = el)}
                  id={-1}
                  ideaAlignment={{
                    idea_title: '', // Initialize empty fields for the new card
                    idea_description: '',
                    reason: '',
                  }}
                  index={parsedData.length}
                  isEdited={false}
                  isEditing={isAdding}
                  isEditor={isEditor}
                  isSelected={false}
                  onSelectSuccess={onSelectSuccess}
                  onSubmitSuccess={handleFetchSubmit}
                  project={project}
                  totalIdeaAlignment={parsedData.length + 1}
                  user={user}
                />
              )}
            </SliderContainer>
          ) : (
            <Card
              ref={(el) => (cardRefs.current[parsedData.length] = el)}
              id={-1}
              ideaAlignment={{
                idea_title: '', // Provide initial values for the fallback card
                idea_description: '',
                reason: '',
              }}
              index={-1}
              isEdited={false}
              isEditing={isAdding}
              isEditor={isEditor}
              isFetching
              isSelected={false}
              onSelectSuccess={onSelectSuccess}
              onSubmitSuccess={handleFetchSubmit}
              project={project}
              totalIdeaAlignment={parsedData.length + 1}
              user={user}
            />
          )}

          <div className="mb-35 flex w-full items-center justify-between pt-12">
            {isEditor &&
              (!isAdding && !isEditing ? (
                <button
                  className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                  onClick={toggleIsAdding}
                >
                  Add Idea Alignment Manually
                  <Icon className="size-20" icon="lucide:plus" />
                </button>
              ) : (
                <div />
              ))}
            <div className="flex items-center justify-end gap-x-15">
              {isEditor &&
                (isEditing || isAdding ? (
                  <>
                    <button
                      className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                      onClick={isAdding ? toggleIsAdding : handleCancelEdit}
                    >
                      <span className="text-15 font-semibold">Cancel</span>
                      <Icon className="size-20" icon="lucide:x" />
                    </button>
                    <button
                      className={`inline-flex items-center gap-x-4 ${
                        isPending ? 'text-gray-600' : 'text-blue-redx'
                      } cursor-pointer`}
                      disabled={isPending}
                      id="btn-edit-idea-alignment"
                      onClick={handleSubmit}
                    >
                      <span className="text-15 font-semibold">Save</span>
                      <Icon className="size-20" icon="lucide:save" />
                    </button>
                  </>
                ) : (
                  <EditButton
                    toggleEditing={() => {
                      const currData = editedData[activeIndex];
                      if (currData) {
                        toggleIsEditing();
                      }
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="mr-1 flex w-full pb-60">
            {!isLoading && sources && <SourcesList sources={sources} />}
            {isLoading && (
              <div className="inline-flex gap-10">
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="idea-alignment"
      />

      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="idea-alignment"
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
