/* eslint-disable import/no-extraneous-dependencies */
import {
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';

const DeleteButton: React.FC<{ onHandleDelete: () => void }> = ({
  onHandleDelete,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 2,
        crossAxis: -10,
      }),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
  const [anchorPosition, setAnchorPosition] = useState<
    | 'bottom start'
    | 'top end'
    | 'top start'
    | 'right end'
    | 'right start'
    | 'bottom end'
    | 'left end'
    | 'left start'
    | undefined
  >('bottom start');

  useEffect(() => {
    const handlePosition = () => {
      if (!refs.reference.current || !refs.floating.current) return;

      const triggerRect = refs.reference.current.getBoundingClientRect();
      const contentHeight = refs.floating.current.offsetHeight;
      const viewportHeight = window.innerHeight;

      const newPosition: 'bottom start' | 'top start' =
        triggerRect.bottom + contentHeight > viewportHeight
          ? 'top start'
          : 'bottom start';

      setAnchorPosition(newPosition);
    };

    handlePosition();
    window.addEventListener('resize', handlePosition);

    return () => window.removeEventListener('resize', handlePosition);
  }, [refs]);

  return (
    <Popover className="relative">
      <PopoverButton
        ref={refs.setReference}
        className="cursor-pointer"
        {...getReferenceProps()}
      >
        <p className="inline-flex gap-4 font-semibold text-error-redx">
          Delete <Icon className=" size-20" icon="lucide:trash-2" />
        </p>

        {isOpen && (
          <div
            ref={refs.setFloating}
            className="rounded-8 px-15 py-8 text-15"
            style={{
              ...floatingStyles,
              boxShadow: '0px 0px 12px 0px #00000026',
            }}
            {...getFloatingProps()}
          >
            Delete
          </div>
        )}
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor={{ to: anchorPosition, gap: -50, offset: 0 }}
          className=" !max-w-525 rounded-lg bg-white p-16 shadow-lg"
        >
          {({ close }) => (
            <div className="flex flex-col gap-8">
              <h1 className="text-14 font-semibold leading-16 text-black-redx">
                Is this competitor not relevant?
              </h1>
              <p className="mb-16 block w-full !text-12 leading-relaxed text-grey-redx">
                Deleting it means you can&apos;t recover it. We&apos;ll also
                remove this competitor name and link from the Submission page.
              </p>
              <div className="flex w-full justify-end gap-x-16">
                <button
                  className="w-100 rounded-8 border-1 border-blue-redx px-14 py-10 text-15 font-semibold text-blue-redx"
                  onClick={() => {
                    close();
                  }}
                >
                  Back
                </button>
                <button
                  className="w-100 rounded-8 bg-error-redx px-14 py-10 text-15 font-semibold text-white"
                  onClick={() => {
                    onHandleDelete();
                    close();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default DeleteButton;
