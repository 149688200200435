/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

const SliderContainer: React.FC<{
  children: React.ReactNode;
  isEditing?: boolean;
  isAdding?: boolean;
  length?: number;
  goToIndex?: number;
  selectedIndexes?: number[];
  onActiveIndex?: (activeIndex: number) => void;
}> = ({
  children,
  isAdding = false,
  selectedIndexes = [],
  isEditing = false,
  length = 1,
  onActiveIndex,
  goToIndex,
}) => {
  const sliderRef = React.useRef<null | Slider>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(length - 1 === 0);

  const NextArrow: React.FC<{
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }> = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={`${className}  relative text-black-redx${
          isAdding || isEditing
            ? ' !cursor-not-allowed !text-placeholder-redx'
            : ''
        }`}
        onClick={isAdding || isEditing || isAtEnd ? () => {} : onClick}
        style={{
          ...style,
          top: '1.5rem',
          right: 0,
          zIndex: 10,
          backgroundColor:
            isAdding || isEditing || isAtEnd ? '#D7D7D7' : '#330DCC',
        }}
      >
        <Icon
          className={`absolute size-4/5 text-white${
            isAdding || isEditing || isAtEnd
              ? ' !cursor-not-allowed !text-white'
              : ''
          }`}
          icon="lucide:arrow-right"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
    );
  };

  const PrevArrow: React.FC<{
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }> = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={`${className} relative text-black-redx${
          isAdding || isEditing
            ? ' !cursor-not-allowed !text-placeholder-redx'
            : ''
        }`}
        onClick={isAdding || isEditing || isAtStart ? () => {} : onClick}
        style={{
          ...style,
          top: '1.5rem',
          left: 0,
          zIndex: 10,
          backgroundColor:
            isAdding || isEditing || isAtStart ? '#D7D7D7' : '#330DCC',
        }}
      >
        <Icon
          className={`absolute size-4/5 !text-white${
            isAdding || isEditing || isAtStart
              ? ' !cursor-not-allowed !text-white'
              : ''
          }`}
          icon="lucide:arrow-left"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
    );
  };

  const settings = {
    customPaging: (i: number) => {
      const isActive = selectedIndexes.includes(i);
      if (isActive)
        return (
          <div className="size-20 rounded-full border-1 border-blue-purple-redx">
            <span
              className="absolute text-1218 font-semibold text-blue-purple-redx"
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {i + 1}
            </span>
          </div>
        );
      return (
        <div className="size-20 rounded-full">
          <span
            className="absolute text-1218 font-semibold text-blue-purple-redx"
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {i + 1}
          </span>
        </div>
      );
    },
    dotsClass: `slick-dots slick-thumb ${[
      isAdding ? 'slick-dots-disabled' : '',
    ]} `,
    dots: !(isAdding || isEditing),
    infinite: false,
    swipe: !(isAdding || isEditing),
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_: any, next: number) => {
      setActiveIndex(next);
      setIsAtStart(next === 0);
      setIsAtEnd(next === length - 1);
    },
  };

  useEffect(() => {
    if (isAdding) {
      sliderRef.current?.slickGoTo(length + 1, true);
    }
  }, [isAdding]);

  useEffect(() => {
    if (goToIndex !== undefined && goToIndex <= length) {
      sliderRef.current?.slickGoTo(goToIndex + 1, true);
    }
  }, [goToIndex, length]);

  useEffect(() => {
    if (onActiveIndex) {
      onActiveIndex(activeIndex);
    }
  }, [activeIndex]);

  return (
    <div
      className={`slider-container relative  ${
        selectedIndexes.includes(activeIndex)
          ? 'border-b-2 border-blue-redx'
          : 'border-b-1 border-stroke-redx'
      }`}
    >
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default SliderContainer;
