/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unused-imports/no-unused-vars */

import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NotDataFound from '@/Components/NotDataFound';
import SectionInformation from '@/Components/Projects/SectionInformation';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
} from '@/Context/actions/projectActions';
import { ProjectContext } from '@/Context/ProjectContext';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { ProjectProps } from '@/Types/projects';
import type {
  Framework,
  RightSidebarSection,
  SelectedContentIdeasData,
} from '@/Types/selected-content-ideas';
import { triggerGTMEvent } from '@/Utils/gtm';
import { capitalizeWords } from '@/Utils/string';

import CategoryTabs from './components/CategoryTabs';
import SidebarNavigation from './components/RightSidebar';
import { Skeleton } from './components/Skeleton';
import dummyData from './data-dummy.json';

interface IndexProps {
  project: ProjectProps;
}

const Index = ({ project }: IndexProps) => {
  const tabsRef = useRef([]);
  const [_, dispatch] = useContext(ProjectContext);
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const [isFetching, setIsFetching] = useState(false);
  const [isAdding, setIsAdding] = useState<number>(0);
  const [sidebarData, setSidebarData] = useState<RightSidebarSection[]>([]);
  const isEditor = roles.includes('Owner') || roles.includes('Content');
  const [parsedData, setParsedData] = useState<SelectedContentIdeasData | null>(
    null,
  );
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const tabsCategoryTypeRef = useRef<
    Record<number, Record<string, (HTMLElement | null)[]>>
  >({});

  const [activeCategoryTabState, setActiveCategoryTabState] = useState<
    Record<number, Record<string, number>>
  >({});

  const [selectedTitleState, setSelectedTitleState] = useState<
    Record<number, Record<string, string>>
  >({});

  const transformSidebarData = (data: SelectedContentIdeasData) => {
    const categories: Record<string, string> = {
      searchability: 'Searchability',
      discoverability: 'Discoverability',
      credibility: 'Credibility',
    };

    const sidebar: RightSidebarSection[] = [];

    data.forEach((archetype) => {
      archetype.contents.forEach((content) => {
        const [categoryKey, , , touchPointNumber] =
          content.framework.split(' ');

        const lowerKey = categoryKey.toLowerCase();

        if (lowerKey in categories) {
          const category = categories[lowerKey];
          const item = {
            id: content.framework, // Generate unique ID
            label: `${category} ${touchPointNumber}`,
          };

          const categoryIndex = sidebar.findIndex(
            (item) => item.title === category,
          );

          if (categoryIndex === -1) {
            sidebar.push({
              title: category,
              items: [item],
            });
          } else {
            sidebar[categoryIndex].items.push(item);
          }
        }
      });
    });

    return sidebar;
  };

  useEffect(() => {
    const data = dummyData.data as SelectedContentIdeasData;
    const sideBar = transformSidebarData(data);
    setSidebarData(sideBar);
    setParsedData(data);
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: false,
        onClick: () => null,
      }),
    );
    dispatch(
      saveRouteNext({
        label: 'Back',
        isActive: false,
        onClick: () => null,
      }),
    );
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  const handleNext = () => {
    if (parsedData && activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleTabClicked = (title: string) => {
    triggerGTMEvent({
      event: `Tab Discoverability content`,
      eventCategory: `Tab Discoverability content Click`,
      eventAction: 'Click',
      eventLabel: 'Discoverability content',
      userId: user.email,
      data: { title },
    });
  };

  const getActiveCategoryTab = (archetypeId: number, framework: string) => {
    return activeCategoryTabState[archetypeId]?.[framework] || 0;
  };

  const updateActiveCategoryTab = (
    archetypeId: number,
    framework: string,
    value: number,
  ) => {
    setActiveCategoryTabState((prevState) => ({
      ...prevState,
      [archetypeId]: {
        ...(prevState[archetypeId] || {}),
        [framework]: value,
      },
    }));
  };

  const addTabCategoryRef = (
    archetypeId: number,
    framework: string,
    index: number,
    element: HTMLElement | null,
  ) => {
    if (!tabsCategoryTypeRef.current[archetypeId]) {
      tabsCategoryTypeRef.current[archetypeId] = {};
    }
    if (!tabsCategoryTypeRef.current[archetypeId][framework]) {
      tabsCategoryTypeRef.current[archetypeId][framework] = [];
    }
    tabsCategoryTypeRef.current[archetypeId][framework][index] = element;
  };

  const updateSelectedTitle = (
    archetypeId: number,
    framework: string,
    title: string,
  ) => {
    setSelectedTitleState((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: title,
      },
    }));
  };

  const getSelectedTitle = (archetypeId: number, framework: string) => {
    return selectedTitleState[archetypeId]?.[framework] || '';
  };

  const getDisplayTitle = (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => {
    const selectedTitle = getSelectedTitle(archetypeId, framework);
    return selectedTitle || data[0]?.title || ''; // Gunakan title pertama sebagai fallback
  };

  const memoizedData = useMemo(() => {
    if (!parsedData) return [];

    return parsedData
      .filter((_, index) => index === activeTab)
      .map((section) => {
        // Transform `stepDataSection.data`
        const transformedData: Framework[] = section.contents.map(
          (stepDatum) => ({
            ...stepDatum,
            data: stepDatum.data.map((dataEntry) => ({
              ...dataEntry,
              ideas: dataEntry.ideas?.map((idea) => ({
                ...idea,
                id: idea.id.toString(),
              })),
            })),
          }),
        );

        return { section, transformedData };
      })
      .filter((item): item is NonNullable<typeof item> => !!item);
  }, [parsedData, activeTab]);

  return (
    <div className="size-full">
      <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
        <div className="flex grow flex-col">
          <h1 className="mb-8 inline-flex items-center gap-12 text-25 font-bold leading-none">
            Selected Content Ideas
          </h1>
          <span className="text-1620 font-normal text-grey-redx ">
            Here is your list of selected content ideas.
          </span>
        </div>
      </div>
      <TabGroup
        className=" pb-10"
        onChange={setActiveTab}
        selectedIndex={activeTab}
      >
        <div className="relative flex items-center">
          {!isFetching && parsedData && parsedData.length > 3 && (
            <button
              className="p-2 text-gray-500 hover:text-black"
              disabled={activeTab === 0}
              onClick={handlePrev}
            >
              <Icon className="text-25" icon="mingcute:left-line" />
            </button>
          )}
          <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
            {!isFetching && parsedData
              ? parsedData.map((section, index) => (
                  <Tab
                    key={index}
                    ref={(el: HTMLElement | null) => {
                      if (el) {
                        (tabsRef.current as HTMLElement[])[index] = el;
                      }
                    }}
                    className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx 
                 data-[selected]:border-b-3 data-[selected]:border-blue-redx 
                 data-[selected]:font-bold data-[selected]:text-blue-redx 
                 data-[focus]:outline-0 data-[focus]:outline-transparent 
                 md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                    disabled={isAdding > 0}
                    onClick={() => handleTabClicked(section.archetype_name)}
                  >
                    <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                      <div className="flex flex-col gap-8">
                        {`Archetype ${index + 1}`}
                        <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                          {section.archetype_name}
                        </div>
                      </div>
                    </div>
                  </Tab>
                ))
              : Array.from({ length: 3 }).map((_, index) => (
                  <button
                    key={`dummy-${index}`}
                    className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                  >
                    <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                    <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                  </button>
                ))}
          </TabList>
          {!isFetching && parsedData && parsedData.length > 3 && (
            <button
              className="p-2 text-gray-500 hover:text-black"
              disabled={activeTab === parsedData.length - 1}
              onClick={handleNext}
            >
              <Icon className="text-25" icon="mingcute:right-line" />
            </button>
          )}
        </div>

        {memoizedData?.length ? (
          <TabPanels className="content-section min-h-screen overflow-visible py-24">
            {memoizedData.map(({ section, transformedData }) => {
              return (
                <div key={section.archetype_id} className="pt-5 ">
                  <div className="mt-20" />
                  <div className="flex flex-col gap-37">
                    {transformedData.length ? (
                      <div className="grid min-h-screen grid-cols-12 gap-24 overflow-visible">
                        <div className="col-span-10 ">
                          {transformedData.map((stepDatum) => (
                            <div
                              key={stepDatum.framework}
                              className="content-section mb-12 flex w-full flex-col"
                              id={stepDatum.framework}
                            >
                              <div className="inline-flex w-full gap-12 border-b-1 border-stroke-redx pb-12">
                                <h2 className="text-16 font-bold leading-20 text-black-redx">
                                  {capitalizeWords(stepDatum.framework)}
                                </h2>
                                <SectionInformation
                                  description={stepDatum.description}
                                  hasButton={false}
                                />
                              </div>
                              <div className="relative mt-12 w-full max-w-full ">
                                <CategoryTabs
                                  addTabCategoryRef={addTabCategoryRef}
                                  archetypeId={section.archetype_id}
                                  getActiveCategoryTab={getActiveCategoryTab}
                                  getDisplayTitle={getDisplayTitle}
                                  isEditor={isEditor}
                                  project={project}
                                  stepDatum={stepDatum}
                                  updateActiveCategoryTab={
                                    updateActiveCategoryTab
                                  }
                                  updateSelectedTitle={updateSelectedTitle}
                                  user={user}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="relative z-10">
                          <SidebarNavigation
                            onActiveItem={(item) => console.log(item)}
                            sections={sidebarData}
                          />
                        </div>
                      </div>
                    ) : (
                      <NotDataFound
                        description="Please select a content idea for this archetype on the Searchability, Discoverability, or Credibility Content pages."
                        title="You have not selected any content ideas for this archetype."
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </TabPanels>
        ) : (
          <Skeleton />
        )}
      </TabGroup>
    </div>
  );
};

export default Index;
