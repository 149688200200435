/* eslint-disable no-nested-ternary */
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import type { User } from '@/Types';
import type { ProjectProps } from '@/Types/projects';
import type { Idea } from '@/Types/searchability-content';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const schema = z.object({
  who_should_say_it: z.string().min(4, 'Who Should Say it is required'),
  category: z.string().min(4, 'Category is required'),
  content_idea: z.string().min(4, 'Content Idea is required'),
  format: z.string().min(4, 'Format is required'),
});

const Card = forwardRef<
  CardHandles,
  {
    user: User;
    id: number | string;
    archetypeId: number;
    framework: string;
    title: string;
    index: number;
    project: ProjectProps;
    item?: Omit<Idea, 'value'>;
    isSelected?: boolean;
    isEditing?: boolean;
    isFetching?: boolean;
    isEditor: boolean;
    totalData?: number;
    onItemChange?: (
      framework: string,
      title: string,
      id: string,
      checked: boolean,
    ) => void;
    onHandleAddOrUpdateIdea: (
      archetypeId: number,
      framework: string,
      title: string,
      newItem: Omit<Idea, 'value'>,
      index?: number,
    ) => void;
  }
>(
  (
    {
      title,
      archetypeId,
      framework,
      onItemChange,
      user,
      id,
      index,
      project,
      item,
      onHandleAddOrUpdateIdea,
      isEditing = false,
      isEditor,
      isFetching = false,
      isSelected = false,
      totalData = 0,
    },
    ref,
  ) => {
    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm<Omit<Idea, 'value'>>({
      resolver: zodResolver(schema),
      defaultValues: {
        who_should_say_it: item?.who_should_say_it ?? '',
        category: item?.category ?? '',
        content_idea: item?.content_idea ?? '',
        format: item?.format ?? '',
      },
    });

    useEffect(() => {
      setValue('who_should_say_it', item?.who_should_say_it ?? '');
      setValue('category', item?.category ?? '');
      setValue('content_idea', item?.content_idea ?? '');
      setValue('format', item?.format ?? '');
      clearErrors();
    }, [item, setValue, isEditing]);

    const onSubmit = (data: Omit<Idea, 'value'>) => {
      if (!item) return;
      onHandleAddOrUpdateIdea(
        archetypeId,
        framework,
        title,
        {
          id: item.id,
          who_should_say_it: data.who_should_say_it,
          category: data.category,
          content_idea: data.content_idea,
          format: data.format,
          is_checked: item.is_checked,
        },
        index,
      );
    };

    const handleSelected = (id: number) => {
      if (!item) return;

      onItemChange?.(framework, title, id.toString(), !item.is_checked);

      triggerGTMEvent({
        event: `Select searchability content`,
        eventCategory: `Button Select searchability content Click`,
        eventAction: 'Click',
        eventLabel: 'Searchability content',
        userId: user.email,
        data: {
          slug: project.slug,
          archetypeId,
          framework,
          title,
          isSelected: !item.is_checked ? 'Unselect' : 'Select',
          ...item,
        },
      });

      // TODO: integration with be
      // const projectSlug = project.slug;
      // mutateSelected(
      //   { id, projectSlug },
      //   {
      //     onSuccess: () => {
      //       triggerGTMEvent({
      //         event: `Select Searchability Content`,
      //         eventCategory: `Button Select Searchability Content Click`,
      //         eventAction: 'Click',
      //         eventLabel: 'Searchability Content',
      //         userId: user.email,
      //         data: { type: isSelected ? 'Unselect' : 'Select', ...item },
      //       });
      //       onSuccessSelected && onSuccessSelected();
      //     },
      //   },
      // );
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    return (
      <>
        {!isFetching ? (
          id === -1 ? (
            <div className=" w-full text-center font-semibold">
              Content Idea {totalData + 1} of {totalData}
            </div>
          ) : (
            <div className=" w-full  text-center font-semibold">
              Content Idea {index + 1} of {totalData}
            </div>
          )
        ) : (
          <div className="inline-flex w-full items-center justify-center gap-8">
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
            <div className="h-20 w-1 border-1 border-stroke-redx" />
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
          </div>
        )}

        <div
          className={`mt-10 flex w-full flex-col gap-y-8 px-18 py-20 text-black-redx ${
            isEditing && isSelected ? '!bg-transparent' : ''
          }
        ${
          isSelected && !isFetching
            ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
            : 'border-t-1 border-stroke-redx'
        }`}
        >
          <div className="grid grid-cols-2 gap-x-24 gap-y-15 pb-8">
            <Controller
              control={control}
              name="who_should_say_it"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.who_should_say_it?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input who should say it here"
                  showTitle
                  title="Who Should Say It"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.category?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input category here"
                  showTitle
                  title="Category"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="content_idea"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.content_idea?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input content Idea here"
                  showTitle
                  title="Content Idea"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="format"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.format?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input format here"
                  showTitle
                  title="Format"
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="flex w-full justify-end px-1 py-15">
          {id !== -1 && isEditor && !isEditing && (
            <SelectButton
              hasOne={false}
              index={index}
              isEditing={isEditing}
              isLoading={false} // to do change with state
              isSelected={isSelected}
              onSelect={() => handleSelected(+id)}
              section="searchability-content"
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
