/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ChecklistSection from '@/Components/ChecklistSectionProps';
import GenerateLoading from '@/Components/GenerateLoading';
import NotDataFound from '@/Components/NotDataFound';
import ProgressLine from '@/Components/ProgressLine';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SectionInformation from '@/Components/Projects/SectionInformation';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
} from '@/Context/actions/projectActions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type {
  ArchetypeContents,
  DiscoverabilityContentData,
  Framework,
  Idea,
  Section,
} from '@/Types/discoverability-content';
import type { ProjectProps } from '@/Types/projects';
import type { Step } from '@/Types/step';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { capitalizeWords } from '@/Utils/string';

import type { CardHandles } from './components/Card';
import CategoryTabs from './components/CategoryTabs';
import { Skeleton } from './components/Skeleton';
import StepHeader from './components/StepHeader';
import dummyData from './data-dummy.json';

interface IndexProps {
  project: ProjectProps;
}

enum StepId {
  GOALS_SUCCESS_METRICS_MAIN_FEATURES = 'goals_success_metrics_main_features',
  TREND_TO_RIDE = 'trend_to_ride',
  CONTENT_TOPICS = 'content_topics',
  CONTENT_IDEAS = 'content_ideas',
}

const stepPropertyMap: Record<StepId, keyof ArchetypeContents> = {
  [StepId.GOALS_SUCCESS_METRICS_MAIN_FEATURES]:
    'goals_success_metrics_main_features',
  [StepId.TREND_TO_RIDE]: 'trend_to_ride',
  [StepId.CONTENT_TOPICS]: 'content_topics',
  [StepId.CONTENT_IDEAS]: 'content_ideas',
};

const Index = ({ project }: IndexProps) => {
  const previousActiveTab = useRef<number | null>(null);
  const maxGenerateLimit = project.max_generated_data;
  const tabsRef = useRef([]);
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const [currentStep, setCurrentStep] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isAdding, setIsAdding] = useState<number>(0);
  const [progressData, setProgressData] = useState<
    Record<number, Record<string, number>>
  >({});
  const isEditor = roles.includes('Owner') || roles.includes('Content');
  const [parsedData, setParsedData] =
    useState<DiscoverabilityContentData | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [_, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const [archetypeSteps, setArchetypeSteps] = useState<Record<number, Step[]>>(
    {},
  );
  const tabsCategoryTypeRef = useRef<
    Record<number, Record<string, (HTMLElement | null)[]>>
  >({});

  const [activeCategoryTabState, setActiveCategoryTabState] = useState<
    Record<number, Record<string, number>>
  >({});

  const [selectedTitleState, setSelectedTitleState] = useState<
    Record<number, Record<string, string>>
  >({});

  const cardRefs = useRef<
    Record<
      number, // archetype_id
      Record<
        string, // framework
        Record<string, (CardHandles | null)[]> // title
      >
    >
  >({});

  const steps: Step[] = [
    {
      id: StepId.GOALS_SUCCESS_METRICS_MAIN_FEATURES,
      label: 'Goals, Success Metrics, & Main Features',
      icon: <Icon icon="lucide:land-plot" />,
      status: true,
      isGenerating: false,
    },
    {
      id: StepId.TREND_TO_RIDE,
      label: 'Trend to ride',
      icon: <Icon icon="lucide:trending-up" />,
      status: false,
      isGenerating: false,
    },
    {
      id: StepId.CONTENT_TOPICS,
      label: 'Content Topics',
      icon: <Icon icon="lucide:messages-square" />,
      status: false,
      isGenerating: false,
    },
    {
      id: StepId.CONTENT_IDEAS,
      label: 'Content Ideas',
      icon: <Icon icon="lucide:sparkles" />,
      status: false,
      isGenerating: false,
    },
  ];

  const updateStepsStatus = (
    parsedData: DiscoverabilityContentData,
    activeArchetypeId: number,
  ) => {
    const activeArchetype = parsedData.find(
      (item) => item.archetype_id === activeArchetypeId,
    );

    if (!activeArchetype) return;

    const updatedSteps = steps.map((step) => {
      const stepKey = stepPropertyMap[step.id as StepId];
      const section = activeArchetype.contents[stepKey];

      return {
        ...step,
        status:
          section && typeof section === 'object' && 'status' in section
            ? section.status
            : false,
        isGenerating:
          section && typeof section === 'object' && 'is_generating' in section
            ? section.is_generating
            : false, // Tetapkan isGenerating
      };
    });

    setArchetypeSteps((prev) => ({
      ...prev,
      [activeArchetypeId]: updatedSteps,
    }));
  };

  const handleStepClick = (stepIndex: number) => {
    setCurrentStep(stepIndex);

    // to do integrastion with api
    setParsedData((prevParsedData) => {
      if (!prevParsedData) return prevParsedData;

      return prevParsedData.map((item, index) => {
        if (index === activeTab) {
          return {
            ...item,
            contents: {
              ...item.contents,
              last_stepper_status:
                steps[stepIndex]?.id || item.contents.last_stepper_status,
            },
          };
        }
        return item;
      });
    });
  };

  const updateCtaButton = (
    archetypeId: number,
    section: string | Section | undefined,
    activeTab: number,
    totalArchetypes: number,
    lastStepperStatus: string | undefined,
  ) => {
    const archStep = archetypeSteps[archetypeId] || steps;

    let labelStep;
    let labelBack;
    let onClick;
    let onClickBack;
    let label = 'Next to Credibility Content';
    let isGenerate = true;
    let isDisabled = false;

    switch (currentStep) {
      case 0:
      case -1:
        labelStep = 'Trends';
        onClick = () => handleStepClick(1);
        break;

      case 1:
        labelStep = 'Topics';
        onClick = () => handleStepClick(2);
        break;

      case 2:
        labelStep = 'Ideas';
        onClick = () => handleStepClick(3);
        break;

      default:
        if (activeTab < totalArchetypes - 1) {
          isGenerate = false;
          labelStep = `Next to Discoverability Content (Archetype ${activeTab + 2})`;
          label = `Next to Discoverability Content (Archetype ${activeTab + 2})`;
          onClick = () => setActiveTab((oldVal) => oldVal + 1);
        } else {
          onClick = () => navigate(`/${project.slug}/credibility-content`);
        }
    }

    const isAnyChecked = (frameworks: Framework[]): boolean => {
      return frameworks.every((framework) =>
        framework.data.some((entry) =>
          entry.ideas.some((idea) => idea.is_checked),
        ),
      );
    };

    const isChecklistExists =
      section &&
      typeof section === 'object' &&
      'data' in section &&
      section.data.length
        ? isAnyChecked(section.data)
        : false;

    const lastStepperIndex = steps.findIndex(
      (step) => step.id === lastStepperStatus,
    );

    switch (lastStepperIndex) {
      case 0:
      case -1:
        if (!isEditor) {
          isGenerate = false;
          label = `Next to ${labelStep} (Archetype ${activeTab + 1})`;
          isDisabled = !archStep[1].status;
          return;
        }

        if (archStep[1].status && isEditor) {
          label = `Next to ${labelStep}`;
          isGenerate = false;
        } else {
          label = `Generate ${labelStep} (Archetype ${activeTab + 1})`;
        }

        break;
      case 1:
        labelBack = 'Back to Goals';
        onClickBack = () => handleStepClick(0);

        if (!isEditor) {
          isGenerate = false;
          label = `Next to ${labelStep} (Archetype ${activeTab + 1})`;
          isDisabled = !archStep[2].status;
          return;
        }

        if (archStep[2].status && isEditor) {
          label = `Next to ${labelStep}`;
          isGenerate = false;
        } else {
          label = `Generate ${labelStep} (Archetype ${activeTab + 1})`;
        }

        break;

      case 2:
        labelBack = 'Back to Trends';
        onClickBack = () => handleStepClick(1);

        if (!isEditor) {
          isGenerate = false;
          label = `Next to ${labelStep} (Archetype ${activeTab + 1})`;
          isDisabled = !archStep[3].status;
          return;
        }

        if (archStep[3].status && isEditor) {
          label = `Next to ${labelStep}`;
          isGenerate = false;
        } else {
          label = `Generate ${labelStep} (Archetype ${activeTab + 1})`;
        }

        break;

      default:
        labelStep = 'Back to Topics';
        onClickBack = () => handleStepClick(2);
        isGenerate = false;
    }

    if (!isEditor) {
      const routePrevOptionValue: RouteOption = {
        label: 'Back',
        isActive: false,
      };

      const routeOptionValue: RouteOption = {
        label,
        isActive: true,
        isGenerate: false,
        isDisabled,
        onClick,
      };

      dispatch(saveRoutePrev(routePrevOptionValue));
      dispatch(saveRouteNext(routeOptionValue));

      return;
    }

    const routePrevOptionValue: RouteOption = {
      label: labelBack ?? 'Back',
      isActive: lastStepperIndex > 0,
      onClick: onClickBack,
    };

    const routeOptionValue: RouteOption = {
      label,
      isActive: true,
      isGenerate,
      isInactive: !isChecklistExists,
      isDisabled: !isChecklistExists,
      onClick,
    };

    dispatch(saveRoutePrev(routePrevOptionValue));
    dispatch(saveRouteNext(routeOptionValue));
  };

  useEffect(() => {
    // TODO: integration with be
    setIsFetching(true);
    const timeout = setTimeout(() => {
      const data = dummyData.data as DiscoverabilityContentData;
      setParsedData(data);

      const initialProgress = data.reduce<
        Record<number, Record<string, number>>
      >((acc, item) => {
        acc[item.archetype_id] = Object.keys(stepPropertyMap).reduce<
          Record<string, number>
        >((stepAcc, stepId) => {
          const stepKey = stepPropertyMap[
            stepId as StepId
          ] as keyof ArchetypeContents;
          const stepContent = item.contents[stepKey];

          if (
            typeof stepContent === 'object' &&
            'is_generating' in stepContent
          ) {
            stepAcc[stepId] = stepContent.is_generating ? 0 : 100;
          } else {
            stepAcc[stepId] = 100; // Default jika bukan objek atau tidak memiliki `is_generating`
          }

          return stepAcc;
        }, {});
        return acc;
      }, {});

      setProgressData(initialProgress);
      setIsFetching(false);
    }, 2000);

    dispatch(saveInformation(''));
    return () => clearTimeout(timeout); // Bersihkan timeout jika komponen unmounted
  }, []);

  useEffect(() => {
    // to do change with integration
    if (!parsedData) return;

    const section = parsedData[activeTab];

    const activeArchetypeId = section?.archetype_id;
    const initialStepKey = section.contents.last_stepper_status;
    const initialStepIndex = steps.findIndex(
      (step) => step.id === initialStepKey,
    );

    if (previousActiveTab.current !== activeTab) {
      setCurrentStep(initialStepIndex >= 0 ? initialStepIndex : 0);
      previousActiveTab.current = activeTab; // Perbarui tab yang dilacak
    }

    const currentStepKey =
      stepPropertyMap[(steps[currentStep]?.id as StepId) || initialStepKey];
    const currentSection = section.contents[currentStepKey];
    updateCtaButton(
      activeArchetypeId,
      currentSection,
      activeTab,
      parsedData.length,
      section.contents.last_stepper_status,
    );

    if (
      !currentSection ||
      typeof currentSection !== 'object' ||
      !('is_generating' in currentSection)
    ) {
      return;
    }

    if (
      currentSection &&
      typeof currentSection === 'object' &&
      'is_generating' in currentSection
    ) {
      setArchetypeSteps((prev) => ({
        ...prev,
        [activeArchetypeId]: (prev[activeArchetypeId] || steps).map((step) => ({
          ...step,
          isGenerating:
            step.id === steps[currentStep]?.id && currentSection.is_generating,
        })),
      }));
    }

    if (!activeArchetypeId || !currentSection.is_generating) {
      return;
    }

    const interval = setInterval(() => {
      setProgressData((prevProgress) => {
        const archetypeProgress = prevProgress[activeArchetypeId] || {};
        const currentStepProgress = archetypeProgress[currentStepKey] || 0;

        if (currentStepProgress >= 100) {
          clearInterval(interval);

          setParsedData((prevData) => {
            if (!prevData) return prevData;

            const updatedData = prevData.map((item) => {
              if (item.archetype_id === activeArchetypeId) {
                const section = item.contents[currentStepKey];
                if (
                  !section ||
                  typeof section !== 'object' ||
                  !('is_generating' in section)
                ) {
                  return item;
                }

                return {
                  ...item,
                  contents: {
                    ...item.contents,
                    [currentStepKey]: {
                      ...section,
                      is_generating: false,
                      status: true,
                    },
                  },
                };
              }
              return item;
            });

            updateStepsStatus(updatedData, activeArchetypeId); // Update steps status

            return updatedData;
          });

          return prevProgress;
        }

        return {
          ...prevProgress,
          [activeArchetypeId]: {
            ...archetypeProgress,
            [currentStepKey]: currentStepProgress + 10,
          },
        };
      });
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [parsedData, activeTab, currentStep]);

  const handleItemChange = (
    framework: string,
    title: string,
    id: string,
    checked: boolean,
  ) => {
    // Update data berdasarkan checklist perubahan
    setParsedData((prevData) => {
      if (!prevData) return prevData;

      const updatedData = prevData.map((section, index) => {
        if (index === activeTab) {
          const currentStepId = steps[currentStep]?.id as StepId;
          const propertyName = stepPropertyMap[currentStepId];

          if (propertyName in section.contents) {
            const stepDataSection = section.contents[propertyName] as Section;

            if (!stepDataSection?.data) return section;

            const updatedFrameworks = stepDataSection.data.map((fw) => {
              if (fw.framework.toLowerCase() === framework.toLowerCase()) {
                const updatedFrameworkData = fw.data.map((entry) => {
                  if (entry.title.toLowerCase() === title.toLowerCase()) {
                    const updatedIdeas = entry.ideas.map((idea) => {
                      if (idea.id.toString() === id) {
                        return { ...idea, is_checked: checked };
                      }
                      return idea;
                    });
                    return { ...entry, ideas: updatedIdeas };
                  }
                  return entry;
                });

                return { ...fw, data: updatedFrameworkData };
              }

              return fw;
            });

            return {
              ...section,
              contents: {
                ...section.contents,
                [propertyName]: {
                  ...stepDataSection,
                  data: updatedFrameworks,
                },
              },
            };
          }
        }
        return section; // Tidak ada perubahan pada tab lainnya
      });

      // Setelah data diperbarui, pastikan untuk mengevaluasi ulang tombol CTA
      const currentStepId = steps[currentStep]?.id as StepId;
      const propertyName = stepPropertyMap[currentStepId];

      const activeTabData = updatedData[activeTab];
      if (activeTabData?.contents && propertyName in activeTabData.contents) {
        const currentSection = activeTabData.contents[propertyName] as Section;

        if (currentSection) {
          updateCtaButton(
            activeTabData.archetype_id,
            currentSection,
            activeTab,
            prevData.length,
            activeTabData.contents.last_stepper_status,
          );
        }
      }

      return updatedData;
    });
  };

  const handleAdd = (
    framework: string,
    title: string,
    updatedItems: Pick<Idea, 'id' | 'value' | 'is_checked'>[],
  ) => {
    // to do change with integration
    setParsedData((prevData) => {
      if (!prevData) return prevData;

      return prevData.map((section, index) => {
        if (index === activeTab) {
          const currentStepId = steps[currentStep].id as StepId;
          const propertyName = stepPropertyMap[currentStepId];
          const stepDataSection = section.contents[propertyName] as Section;

          const newData = stepDataSection.data.map((fw) => {
            if (fw.framework.toLowerCase() === framework.toLowerCase()) {
              // Perbarui hanya kategori dengan judul yang cocok
              const updatedFrameworkData = fw.data.map((entry) => {
                if (entry.title.toLowerCase() === title.toLowerCase()) {
                  const existingIdeasMap = new Map(
                    entry.ideas.map((idea) => [idea.id, idea]),
                  );

                  // Update atau tambahkan `updatedItems`
                  updatedItems.forEach((item) => {
                    const itemId = parseInt(item.id.toString(), 10); // Pastikan ID menjadi angka
                    if (existingIdeasMap.has(itemId)) {
                      // Update nilai yang sudah ada
                      existingIdeasMap.set(itemId, {
                        ...existingIdeasMap.get(itemId),
                        value: item.value,
                        is_checked: item.is_checked,
                        id: itemId, // Pastikan `id` didefinisikan
                      });
                    } else {
                      // Tambahkan item baru
                      existingIdeasMap.set(itemId, {
                        id: itemId,
                        value: item.value,
                        is_checked: item.is_checked,
                      });
                    }
                  });

                  // Konversi kembali ke array
                  const updatedIdeas = Array.from(existingIdeasMap.values());

                  return { ...entry, ideas: updatedIdeas };
                }
                return entry; // Kategori lain tetap sama
              });

              return { ...fw, data: updatedFrameworkData }; // Framework diperbarui
            }
            return fw; // Framework lain tetap sama
          });

          return {
            ...section,
            contents: {
              ...section.contents,
              [propertyName]: {
                ...stepDataSection,
                data: newData,
              },
            },
          };
        }

        return section;
      });
    });
  };

  const handleAddOrUpdateIdea = (
    archetypeId: number,
    framework: string,
    title: string,
    newItem: Omit<Idea, 'value'>,
    index?: number,
    callback?: () => void,
  ) => {
    setParsedData((prevData) => {
      if (!prevData) return prevData;

      return prevData.map((section) => {
        if (section.archetype_id === archetypeId) {
          const currentStepId = steps[currentStep].id as StepId;
          const propertyName = stepPropertyMap[currentStepId];
          const stepDataSection = section.contents[propertyName] as Section;

          const newData = stepDataSection.data.map((fw) => {
            if (fw.framework.toLowerCase() === framework.toLowerCase()) {
              const updatedFrameworkData = fw.data.map((entry) => {
                if (entry.title.toLowerCase() === title.toLowerCase()) {
                  const ideas = [...entry.ideas];

                  if (index !== undefined) {
                    if (ideas[index]) {
                      ideas[index] = { ...ideas[index], ...newItem };
                    }
                  } else {
                    // Cek apakah ID sudah ada
                    const existingIndex = ideas.findIndex(
                      (idea) => idea.id && idea.id === newItem.id,
                    );

                    if (existingIndex !== -1) {
                      // Update jika ID ditemukan
                      ideas[existingIndex] = {
                        ...ideas[existingIndex],
                        ...newItem,
                      };
                    } else {
                      // Insert jika ID tidak ditemukan
                      ideas.push(newItem);
                    }
                  }

                  return {
                    ...entry,
                    ideas,
                  };
                }
                return entry;
              });

              return { ...fw, data: updatedFrameworkData };
            }
            return fw;
          });

          return {
            ...section,
            contents: {
              ...section.contents,
              [propertyName]: {
                ...stepDataSection,
                data: newData,
              },
            },
          };
        }

        return section;
      });
    });

    if (callback) {
      callback();
    }
  };

  const handleRegenerateButton = (prompt: string) => {
    // TODO: integration with be
    triggerGTMEvent({
      event: `Generate More Discoverability Content`,
      eventCategory: `Generate More Discoverability Content`,
      eventAction: 'Click',
      eventLabel: 'Discoverability Content',
      userId: user.email,
      data: prompt,
    });
  };

  const handleNext = () => {
    if (parsedData && activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleTabClicked = (title: string) => {
    triggerGTMEvent({
      event: `Tab Discoverability content`,
      eventCategory: `Tab Discoverability content Click`,
      eventAction: 'Click',
      eventLabel: 'Discoverability content',
      userId: user.email,
      data: { title },
    });
  };

  const handleStatusChange = (status: 'idle' | 'adding' | 'editing') => {
    setIsAdding((prevCount) => {
      if (status === 'adding' || status === 'editing') {
        return prevCount + 1;
      }
      if (status === 'idle') {
        return Math.max(0, prevCount - 1); // Pastikan tidak negatif
      }
      return prevCount;
    });
  };

  const handleNoDataFound = (archetype: string) => {
    navigate(
      `/${project.slug}/key-touch-point?archetype=${archetype}&funnel=discoverable`,
    );
  };

  const getActiveCategoryTab = (archetypeId: number, framework: string) => {
    return activeCategoryTabState[archetypeId]?.[framework] || 0;
  };

  const updateActiveCategoryTab = (
    archetypeId: number,
    framework: string,
    value: number,
  ) => {
    setActiveCategoryTabState((prevState) => ({
      ...prevState,
      [archetypeId]: {
        ...(prevState[archetypeId] || {}),
        [framework]: value,
      },
    }));
  };

  const addTabCategoryRef = (
    archetypeId: number,
    framework: string,
    index: number,
    element: HTMLElement | null,
  ) => {
    if (!tabsCategoryTypeRef.current[archetypeId]) {
      tabsCategoryTypeRef.current[archetypeId] = {};
    }
    if (!tabsCategoryTypeRef.current[archetypeId][framework]) {
      tabsCategoryTypeRef.current[archetypeId][framework] = [];
    }
    tabsCategoryTypeRef.current[archetypeId][framework][index] = element;
  };

  const updateSelectedTitle = (
    archetypeId: number,
    framework: string,
    title: string,
  ) => {
    setSelectedTitleState((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: title,
      },
    }));
  };

  const getSelectedTitle = (archetypeId: number, framework: string) => {
    return selectedTitleState[archetypeId]?.[framework] || '';
  };

  const getDisplayTitle = (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => {
    const selectedTitle = getSelectedTitle(archetypeId, framework);
    return selectedTitle || data[0]?.title || ''; // Gunakan title pertama sebagai fallback
  };

  const addCardRef = (
    archetypeId: number,
    framework: string,
    title: string,
    index: number,
    ref: CardHandles | null,
  ) => {
    if (!cardRefs.current[archetypeId]) {
      cardRefs.current[archetypeId] = {};
    }
    if (!cardRefs.current[archetypeId][framework]) {
      cardRefs.current[archetypeId][framework] = {};
    }
    if (!cardRefs.current[archetypeId][framework][title]) {
      cardRefs.current[archetypeId][framework][title] = [];
    }

    cardRefs.current[archetypeId][framework][title][index] = ref;
  };

  const memoizedData = useMemo(() => {
    if (!parsedData) return [];

    return parsedData
      .filter((_, index) => index === activeTab)
      .map((section) => {
        const currentStepId = steps[currentStep]?.id as StepId;
        const propertyName = stepPropertyMap[currentStepId];
        const stepDataSection = section.contents[propertyName] as Section;

        if (!stepDataSection?.data) return null;

        // Transform `stepDataSection.data`
        const transformedData: Framework[] = stepDataSection.data.map(
          (stepDatum) => ({
            ...stepDatum,
            data: stepDatum.data.map((dataEntry) => ({
              ...dataEntry,
              ideas: dataEntry.ideas?.map((idea) => ({
                ...idea,
                id: idea.id.toString(),
              })),
            })),
          }),
        );

        return { section, transformedData };
      })
      .filter((item): item is NonNullable<typeof item> => !!item); // Type guard
  }, [parsedData, activeTab, currentStep]);

  const handleSaveIdeas = (
    archetypeId: number,
    framework: string,
    title: string,
    index?: number,
  ) => {
    const cards = cardRefs.current[archetypeId]?.[framework]?.[title] || [];
    if (index !== undefined) {
      const card = cards[index];
      if (card) {
        card.submitForm();
      }
    } else {
      cards.forEach((card) => card?.submitForm());
    }
  };

  return (
    <div className="size-full">
      <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
        <div className="flex grow flex-col">
          <h1 className="mb-8 inline-flex items-center gap-12 text-25 font-bold leading-none">
            Discoverability Content
            <SectionInformation
              description="<b class='text-black-redx'>Discoverability</b> — the quality of being easy to find on a website, using a search engine, etc. It drives business goals by furthering your research, engaging your audience, and diversifying revenue."
              hasButton={false}
              title="What is Discoverability Content?"
            />
          </h1>
          <span className="text-1620 font-normal text-grey-redx ">
            Please follow these steps and input the information for each
            archetype to generate content ideas
          </span>
        </div>
      </div>
      <TabGroup
        className="pb-80"
        onChange={setActiveTab}
        selectedIndex={activeTab}
      >
        <div className="relative flex items-center">
          {!isFetching && parsedData && parsedData.length > 3 && (
            <button
              className="p-2 text-gray-500 hover:text-black"
              disabled={activeTab === 0}
              onClick={handlePrev}
            >
              <Icon className="text-25" icon="mingcute:left-line" />
            </button>
          )}
          <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
            {!isFetching && parsedData
              ? parsedData.map((section, index) => (
                  <Tab
                    key={index}
                    ref={(el: HTMLElement | null) => {
                      if (el) {
                        (tabsRef.current as HTMLElement[])[index] = el;
                      }
                    }}
                    className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx 
                 data-[selected]:border-b-3 data-[selected]:border-blue-redx 
                 data-[selected]:font-bold data-[selected]:text-blue-redx 
                 data-[focus]:outline-0 data-[focus]:outline-transparent 
                 md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                    disabled={isAdding > 0}
                    onClick={() => handleTabClicked(section.archetype_name)}
                  >
                    <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                      <div className="flex flex-col gap-8">
                        {`Archetype ${index + 1}`}
                        <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                          {section.archetype_name}
                        </div>
                      </div>
                    </div>
                  </Tab>
                ))
              : Array.from({ length: 3 }).map((_, index) => (
                  <button
                    key={`dummy-${index}`}
                    className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                  >
                    <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                    <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                  </button>
                ))}
          </TabList>
          {!isFetching && parsedData && parsedData.length > 3 && (
            <button
              className="p-2 text-gray-500 hover:text-black"
              disabled={activeTab === parsedData.length - 1}
              onClick={handleNext}
            >
              <Icon className="text-25" icon="mingcute:right-line" />
            </button>
          )}
        </div>

        {!isFetching && memoizedData?.length ? (
          <TabPanels className="py-24">
            {memoizedData.map(({ section, transformedData }) => {
              const currSection = section.contents[
                stepPropertyMap[steps[currentStep]?.id as StepId]
              ] as Section;

              const currentSteps =
                archetypeSteps[section.archetype_id] || steps; // Default ke langkah awal jika tidak ada

              return (
                <div key={section.archetype_id} className="pt-5">
                  <div className="mt-20" />
                  <ProgressLine
                    currentStep={currentStep}
                    onStepClick={handleStepClick}
                    steps={currentSteps}
                  />
                  {currSection.is_generating ? (
                    (() => {
                      const progress =
                        progressData[section.archetype_id]?.[
                          stepPropertyMap[steps[currentStep]?.id as StepId]
                        ] || 0;

                      return (
                        <GenerateLoading
                          key={section.archetype_id}
                          progress={progressPortion({
                            progress: Number((progress / 2).toFixed(0)),
                            isQuerying: currSection.is_generating,
                            isContentAvailable: currSection.status,
                          })}
                          project={project}
                          section={{
                            title: 'searchability content',
                            value: `searchability content_${section.archetype_name}`,
                            section: section.archetype_name,
                          }}
                          showEmailNotification={currSection.is_show_email}
                        />
                      );
                    })()
                  ) : (
                    <>
                      <div className="inline-flex w-full flex-wrap items-center justify-between">
                        <StepHeader currentStep={currentStep} />
                        {isEditor && currentStep > 0 && (
                          <div className="shrink-0">
                            <RegenerateButton
                              isGenerateMore
                              limit={currSection.total_generate_more ?? 0}
                              maxLimit={maxGenerateLimit}
                              onSubmit={(form) =>
                                handleRegenerateButton(form.prompt)
                              }
                              section={`generate_more_${
                                stepPropertyMap[
                                  steps[currentStep]?.id as StepId
                                ]
                              }`}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col gap-37">
                        {transformedData.length ? (
                          transformedData.map((stepDatum) => (
                            <div
                              key={stepDatum.framework}
                              className="mb-12 flex flex-col"
                            >
                              <div className="inline-flex gap-12 border-b-1 border-stroke-redx pb-12">
                                <h2 className="text-16 font-bold leading-20 text-black-redx">
                                  {capitalizeWords(stepDatum.framework)}
                                </h2>
                                <SectionInformation
                                  description={stepDatum.description}
                                  hasButton={false}
                                />
                              </div>
                              <div className="mt-12 flex flex-col gap-37">
                                {section.contents.last_stepper_status ===
                                StepId.CONTENT_IDEAS ? (
                                  <CategoryTabs
                                    addCardRef={addCardRef}
                                    addTabCategoryRef={addTabCategoryRef}
                                    getActiveCategoryTab={getActiveCategoryTab}
                                    getDisplayTitle={getDisplayTitle}
                                    isEditor={isEditor}
                                    onHandleAddOrUpdateIdea={
                                      handleAddOrUpdateIdea
                                    }
                                    onHandleSaveIdeas={handleSaveIdeas}
                                    onItemChange={handleItemChange}
                                    project={project}
                                    section={section}
                                    stepDatum={stepDatum}
                                    updateActiveCategoryTab={
                                      updateActiveCategoryTab
                                    }
                                    updateSelectedTitle={updateSelectedTitle}
                                    user={user}
                                  />
                                ) : (
                                  stepDatum.data.map((dataEntry, index) => (
                                    <ChecklistSection
                                      key={`${stepDatum.framework}_${dataEntry.title}_${index}`}
                                      framework={stepDatum.framework}
                                      isEditor={isEditor}
                                      items={dataEntry.ideas}
                                      onAddItem={handleAdd}
                                      onItemChange={handleItemChange}
                                      onStatusChange={handleStatusChange}
                                      title={dataEntry.title}
                                    />
                                  ))
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <NotDataFound
                            buttonLabel="Go to Key Touch Point"
                            description="Click the button below to choose a searchability touch point in Key Touch Points page."
                            onClick={() =>
                              handleNoDataFound(section.archetype_id.toString())
                            }
                            title="No searchability touch point selected for this archetype."
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </TabPanels>
        ) : (
          <Skeleton currentStep={currentStep} steps={steps} />
        )}
      </TabGroup>
    </div>
  );
};

export default Index;
