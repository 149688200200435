import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import MarkdownView from '@/Components/MardownView';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import TextAreaInput from '@/Components/TextAreaInput';
import { useUpdateSelected } from '@/Hooks/react-query/IdeaPersonalization/useUpdateSelected';
import type { User } from '@/Types';
import type {
  IdeaPersonalization,
  IdeaPersonalizationContent,
} from '@/Types/idea_personalization';
import type { Schema } from '@/Types/idea_personalization/schema';
import { IdeaPersonalizationSchema } from '@/Types/idea_personalization/schema';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = '' }) => (
  <div className={`flex flex-col gap-10 ${className}`}>{children}</div>
);
const Label: React.FC<{
  value: string;
}> = ({ value }) => (
  <p className="text-12 font-semibold leading-14 text-black-redx">{value}</p>
);
const Content: React.FC<{
  value: string;
  isFetching: boolean;
}> = ({ value, isFetching }) => {
  const isValueList = value?.split('\n').length > 1;
  if (isValueList) {
    return (
      <ul className="ms-20 list-disc">
        {value.split('\n').map((item, index) => (
          <li
            key={index}
            className="text-14 font-normal leading-20 text-black-redx"
          >
            {!isFetching && <MarkdownView content={item} />}
            {isFetching && (
              <div className="min-h-50 py-4 text-14">
                <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div className="inline text-14 font-normal leading-20 text-black-redx">
      {!isFetching && <MarkdownView content={value} />}
      {isFetching && (
        <div className="min-h-50 py-4 text-14">
          <div className="mb-14 h-20 w-full animate-pulse rounded-full  bg-soft-purple-redx" />
        </div>
      )}
    </div>
  );
};

const Card = forwardRef<
  any,
  {
    user: User;
    item?: IdeaPersonalization;
    archetypeId?: number;
    index: number;
    totalItem: number;
    isFetching?: boolean;
    isCanEdit?: boolean;
    isEditing?: boolean;
    isAdding?: boolean;
    onSaveEditedData?: (
      data: Omit<IdeaPersonalizationContent, 'idea_personalization_number'>,
      index: number,
      id: number,
      archetypeId: number,
    ) => void;
    onSuccessSelected?: () => void;
  }
>(
  (
    {
      user,
      item,
      index,
      archetypeId,
      totalItem,
      isFetching = false,
      isCanEdit = true,
      onSaveEditedData,
      isEditing,
      isAdding,
      onSuccessSelected,
    },
    ref,
  ) => {
    const defaultValues: Schema = useMemo(
      () => ({
        idea_title: item?.idea_personalization_content?.idea_title ?? '',
        idea_description:
          item?.idea_personalization_content?.idea_description ?? '',
        reason_product_brand:
          item?.idea_personalization_content?.reason_product_brand ?? '',
        reason_target_audience:
          item?.idea_personalization_content?.reason_target_audience ?? '',
      }),
      [item],
    );
    const params = useParams<{ project_slug: string }>();
    const [selectLoading, setSelectLoading] = useState(false);
    const { mutate } = useUpdateSelected();

    const { control, handleSubmit, reset, setError } = useForm<Schema>({
      resolver: zodResolver(IdeaPersonalizationSchema),
      defaultValues,
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () =>
          handleSubmit(async (formData) => {
            const result = await IdeaPersonalizationSchema.safeParse(formData);
            result.error?.errors.forEach((error) => {
              setError(error.path[0] as keyof Schema, {
                message: error.message,
              });
            });
            if (result.success) {
              onSaveEditedData &&
                onSaveEditedData(formData, index, item?.id ?? -1, archetypeId!);
            }
          })(),
      }),
      [handleSubmit, onSaveEditedData, index],
    );

    useEffect(() => {
      reset(item?.idea_personalization_content);
    }, [item, reset, isEditing]);

    const handleSelect = () => {
      setSelectLoading(true);
      mutate(
        {
          projectSlug: params.project_slug ?? '',
          id: item!.id,
        },
        {
          onSuccess: () => {
            triggerGTMEvent({
              event: `Select Idea Personalization`,
              eventCategory: `Button Select Idea Personalization Click`,
              eventAction: 'Click',
              eventLabel: 'Idea Personalization',
              userId: user.email,
              data: {
                type: item!.is_manual ? 'Unselect' : 'Select',
                ...item,
              },
            });
            setSelectLoading(false);
            onSuccessSelected && onSuccessSelected();
          },
          onError: () => {
            setSelectLoading(false);
            toast.error('Something went wrong');
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="py-15 text-center ">
          <h1 className="w-full text-center text-16 font-bold leading-20 text-black-redx">
            {!isFetching && (
              <div className="inline-flex items-center justify-center gap-8 px-35 py-15 text-center">
                <h1 className="text-1620 font-bold">
                  Idea Personalization {index + 1} of {totalItem}
                </h1>
                {(item?.is_edit || isAdding) && (
                  <div className="rounded-8 bg-soft-grey-redx px-8 py-4 text-12 leading-14 text-grey-redx">
                    Edited
                  </div>
                )}
              </div>
            )}

            {isFetching && (
              <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
            )}
          </h1>
        </div>
        <form>
          <div
            className={`grid max-h-full min-h-280 grid-cols-2 gap-24 p-24 pb-10  ${
              isEditing && item?.is_selected ? '!bg-transparent' : ''
            } ${
              item?.is_selected
                ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
                : 'border-t-1 border-stroke-redx'
            }`}
          >
            <Container>
              <Label value="Idea Title" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="idea_title"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input Idea Title here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={item?.idea_personalization_content?.idea_title ?? ''}
                />
              )}
            </Container>
            <Container>
              <Label value="Idea Description" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="idea_description"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the creative communication angle here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.idea_personalization_content?.idea_description ?? ''
                  }
                />
              )}
            </Container>
            <Container>
              <Label value="Reason Product Brand" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="reason_product_brand"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the features & benefits here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.idea_personalization_content?.reason_product_brand ??
                    ''
                  }
                />
              )}
            </Container>
            <Container>
              <Label value="Reason Target Audience" />
              {isEditing || isAdding ? (
                <Controller
                  control={control}
                  name="reason_target_audience"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input the digital key touch points here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.idea_personalization_content
                      ?.reason_target_audience ?? ''
                  }
                />
              )}
            </Container>
          </div>
          <div className="flex w-full items-center justify-end py-20">
            {!isFetching && isCanEdit && item && (
              <SelectButton
                hasOne={false}
                index={index}
                isEditing={(isEditing || isAdding)!}
                isLoading={selectLoading}
                isSelected={item.is_selected}
                onSelect={handleSelect}
                section="idea-personalization"
              />
            )}
          </div>
        </form>
      </div>
    );
  },
);

export default Card;
