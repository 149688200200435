import { useEffect, useState } from 'react';

import type { RightSidebarSection } from '@/Types/selected-content-ideas';

const SidebarNavigation = ({
  sections,
  onActiveItem,
}: {
  sections: RightSidebarSection[];
  onActiveItem: (selectedFramework: string) => void;
}) => {
  const [activeItem, setActiveItem] = useState<string>(sections[0].items[0].id);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isScrolling) return; // Jika sedang scrolling dengan handleActiveItem, abaikan event scroll

      const sections = document.querySelectorAll('.content-section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          setActiveItem(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling]);

  const handleActiveItem = (selectedFramework: string) => {
    setActiveItem(selectedFramework);
    onActiveItem(selectedFramework);

    const targetSection = document.getElementById(selectedFramework);
    if (targetSection) {
      const yOffset = -180; // Sesuaikan offset untuk header atau padding
      const yPosition =
        targetSection.getBoundingClientRect().top + window.scrollY + yOffset;

      setIsScrolling(true);

      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
      });

      setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    }
  };

  return (
    <div className="min-280 sticky top-1/5 col-span-2 -mt-20 flex max-h-screen w-155 flex-col gap-8 overflow-auto">
      <h2 className="text-12 font-semibold leading-14 text-black-redx">
        Jump to section
      </h2>
      <div className="w-64 border-l-1 border-l-placeholder-redx">
        <ul>
          {sections.map((section, index) => (
            <li key={index} className="py-6">
              <h3 className="mb-6 pl-16 text-12 font-normal leading-14">
                {section.title}
              </h3>
              <ul className="border-l mt-2 border-gray-300">
                {section.items.map((item, itemIndex) => {
                  return (
                    <li
                      key={itemIndex}
                      className={`h-26 mb-6  py-6 pl-[15%] ${activeItem === item.id && 'border-l-2 border-l-blue-purple-redx bg-softer-purple-redx'}`}
                    >
                      <button
                        className={`block w-full text-left text-12 font-normal leading-14 ${
                          activeItem === item.id
                            ? '!font-bold text-blue-purple-redx'
                            : 'text-black-redx hover:font-medium'
                        }`}
                        onClick={() => handleActiveItem(item.id)}
                      >
                        {item.label}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarNavigation;
