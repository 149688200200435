/* eslint-disable @typescript-eslint/naming-convention */
import type { HTMLAttributes } from 'react';

export default function InputError({
  message,
  className = '',
  light = false,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & {
  message?: string;
  light?: boolean;
}) {
  const errorClassName = light ? 'text-red-200' : 'text-red-500';

  return message ? (
    <p
      {...props}
      className={`text-12 font-normal leading-14 ${errorClassName} ${className}`}
    >
      {message}
    </p>
  ) : null;
}
