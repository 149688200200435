/* eslint-disable @typescript-eslint/naming-convention */
import type { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import Checkbox from './Checkbox';

type Props = {
  isFocused?: boolean;
  rightElement?: React.ReactNode;
  containerClassName?: string;
  error?: string;
  light?: boolean;
  hasCheckBox?: boolean;
  isCheckboxChecked?: boolean;
  isMultiline?: boolean; // Tambahkan untuk menentukan penggunaan textarea
} & (
  | InputHTMLAttributes<HTMLInputElement>
  | TextareaHTMLAttributes<HTMLTextAreaElement>
);

export default forwardRef(function TextInput(
  {
    isFocused = false,
    className = '',
    containerClassName = '',
    placeholder = '',
    light = false,
    error = '',
    rightElement = null,
    hasCheckBox = false,
    isCheckboxChecked = false,
    isMultiline = false, // Default menggunakan input
    ...props
  }: Props,
  ref,
) {
  const localRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }));

  useEffect(() => {
    if (isFocused) {
      localRef.current?.focus();
    }
  }, [isFocused]);

  const placeholderClass = light
    ? 'placeholder-lightplaceholder-redx'
    : 'placeholder-placeholder-redx';

  return (
    <div
      className={`flex w-full items-center overflow-hidden border-b-1 bg-transparent transition-all duration-300 focus-within:!border-b-2 ${
        error ? '!border-red-500' : 'border-soft-purplestroke-redx'
      } ${containerClassName}`}
    >
      {hasCheckBox && (
        <Checkbox checked={isCheckboxChecked} className="pb-20" disabled />
      )}

      {isMultiline ? (
        <textarea
          ref={localRef as React.RefObject<HTMLTextAreaElement>} // Casting ref ke textarea
          className={`resize-none overflow-auto break-words rounded-md border-transparent bg-transparent  px-0   text-12 shadow-sm ${placeholderClass} ${className}`}
          placeholder={placeholder}
          {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)} // Pastikan props sesuai dengan textarea
        />
      ) : (
        <input
          ref={localRef as React.RefObject<HTMLInputElement>} // Casting ref ke input
          className={`!focus:border-transparent !focus:ring-0 rounded-md !border-transparent bg-transparent px-0 py-2 text-12 shadow-sm ${placeholderClass} ${className}`}
          placeholder={placeholder}
          {...(props as InputHTMLAttributes<HTMLInputElement>)} // Pastikan props sesuai dengan input
        />
      )}
      {rightElement}
    </div>
  );
});
