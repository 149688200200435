import { useMutation } from '@tanstack/react-query';

import { fetcher } from '@/Services/axios';

const deleteCompetitor = async ({
  brandName,
  projectSlug,
}: {
  brandName: string;
  projectSlug: string;
}) => {
  try {
    await fetcher.delete(`/${projectSlug}/competitor-analysis`, {
      brand_name: brandName,
    });
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useDeleteCompetitor = () => {
  return useMutation<
    any,
    Error,
    {
      brandName: string;
      projectSlug: string;
    }
  >({
    mutationFn: deleteCompetitor,
  });
};

export { useDeleteCompetitor };
