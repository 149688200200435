import { useEffect } from 'react';

interface Archetype {
  id: number;
  is_querying: boolean;
  [key: string]: any; // To allow other fields dynamically
}

interface UseArchetypeStateUpdaterParams<T> {
  data: { data: T[] } | null | undefined;
  activeArchetype: T | null;
  activeTab: number;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setArchetypes: React.Dispatch<React.SetStateAction<Record<number, T>>>;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
}

export const useArchetypeStateUpdater = <T extends Archetype>({
  data,
  activeArchetype,
  activeTab,
  setGeneratingStates,
  setArchetypes,
  setProgressStates,
}: UseArchetypeStateUpdaterParams<T>) => {
  useEffect(() => {
    if (!activeArchetype || !data?.data) return;
    const activeArchetypeId = activeArchetype.id;

    const updatedArchetype = data.data.find(
      (archetype) => archetype.id === activeArchetypeId,
    );

    if (updatedArchetype && !updatedArchetype.is_querying) {
      // Update generating states only if needed
      setGeneratingStates((prev) => {
        if (prev[activeArchetypeId] === false) return prev; // Avoid redundant updates
        return {
          ...prev,
          [activeArchetypeId]: false,
        };
      });

      // Update archetypes only if needed
      setArchetypes((prev) => {
        const existingArchetype = prev[activeArchetypeId];
        if (
          JSON.stringify(existingArchetype) === JSON.stringify(updatedArchetype)
        )
          return prev; // Avoid redundant updates
        return {
          ...prev,
          [activeArchetypeId]: {
            ...existingArchetype,
            ...updatedArchetype,
          },
        };
      });

      // Update progress states
      setProgressStates((prev) => {
        if (prev[activeArchetypeId] === 100) {
          return prev;
        } // Avoid redundant updates
        return {
          ...prev,
          [activeArchetypeId]: 100,
        };
      });
    }
  }, [
    data,
    activeArchetype,
    activeTab,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  ]);
};
