import Wrapper from '@/Pages/CredibilityContent/Wrapper';

const CredibilityContentRoutes = [
  {
    path: '/:project_slug/credibility-content',
    element: <Wrapper />,
  },
];

export default CredibilityContentRoutes;
