import Wrapper from '@/Pages/ChallengeAndTask/Wrapper';

const ChallengeAndTaskRoutes = [
  {
    path: '/:project_slug/challenge-and-communication-task',
    element: <Wrapper />,
  },
];

export default ChallengeAndTaskRoutes;
