import { Tab, TabList } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useRef } from 'react';

import type { User } from '@/Types';
import type {
  ArchetypeChallenge,
  SectionChallenge,
} from '@/Types/challenge_and_task';
import { triggerGTMEvent } from '@/Utils/gtm';

interface TabsProps {
  user: User;
  archetypesArray: ArchetypeChallenge[];
  activeTab: number;
  isLoading: boolean;
  isEditing: boolean;
  errorTabIndex?: number[];
  onActiveTab: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({
  archetypesArray,
  user,
  activeTab,
  isLoading = true,
  isEditing = false,
  errorTabIndex = [],
  onActiveTab,
}) => {
  const tabsRef = useRef([]);
  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      onActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleNext = () => {
    if (activeTab < archetypesArray.length - 1) {
      const nextIndex = activeTab + 1;
      onActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleTabClicked = (title: string, challenges: SectionChallenge[]) => {
    triggerGTMEvent({
      event: `Tab Challenge And Task`,
      eventCategory: `Tab Challenge And Task Click`,
      eventAction: 'Click',
      eventLabel: 'Challenge And Task',
      userId: user.email,
      data: { title, challenges },
    });
  };

  return (
    <div className="relative flex items-center">
      {archetypesArray.length > 3 && (
        <button
          className="p-2 text-gray-500 hover:text-black"
          disabled={activeTab === 0}
          onClick={handlePrev}
        >
          <Icon className="text-25" icon="mingcute:left-line" />
        </button>
      )}
      <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
        {!isLoading
          ? archetypesArray.map((section, index) => (
              <Tab
                key={index}
                ref={(el: HTMLElement | null) => {
                  if (el) {
                    (tabsRef.current as HTMLElement[])[index] = el;
                  }
                }}
                className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                disabled={isEditing}
                onClick={() =>
                  handleTabClicked(
                    section.archetype_content.archetype_name,
                    section.challenges,
                  )
                }
              >
                <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                  <div className="flex flex-col gap-8">
                    {`Archetype ${index + 1}`}
                    <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                      {section.archetype_content.archetype_name}
                    </div>
                  </div>
                  {errorTabIndex?.includes(index) && (
                    <Icon
                      className="text-25 text-error-redx"
                      icon="mingcute:information-line"
                    />
                  )}
                </div>
              </Tab>
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <button
                key={`dummy-${index}`}
                className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
              >
                <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
              </button>
            ))}
      </TabList>
      {archetypesArray.length > 3 && (
        <button
          className="p-2 text-gray-500 hover:text-black"
          disabled={activeTab === archetypesArray.length - 1}
          onClick={handleNext}
        >
          <Icon className="text-25" icon="mingcute:right-line" />
        </button>
      )}
    </div>
  );
};

export default Tabs;
