import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SectionInformation from '@/Components/Projects/SectionInformation';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useIdeaPersonalization from '@/Hooks/react-query/IdeaPersonalization/useGetList';
import { useUpdateIdeaPersonalization } from '@/Hooks/react-query/IdeaPersonalization/useUpdate';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type {
  IdeaPersonalization,
  IdeaPersonalizationContent,
  IdeaPersonalizationList,
} from '@/Types/idea_personalization';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [parsedData, setParsedData] = useState<IdeaPersonalizationList[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [selectedIdeaIndex, setSelectedIdeaIndex] = useState<
    number[] | undefined
  >();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const {
    isIdeaPersonalization,
    isIdeaExpansion,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    isPending: isePendingRefetch,
  } = useIdeaPersonalization(project.slug);
  const { mutate, isPending: isSubmitting } = useUpdateIdeaPersonalization();
  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Idea Expansion',
    isActive: true,
    isGenerate: true,
    isInactive: true,
  });

  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const refreshData = () => {
    isAdding && toggleIsAdding();
    isEditing && toggleIsEditing();

    setTimeout(() => {
      refetch();
    }, 700);
  };

  const handleCancelEdit = async () => {
    toggleIsEditing();
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const { handleGenerateIdeaPersonalization, handleGenerateIdeaExpansion } =
    useGenerate(dispatch);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const handleSaveEditedData = (
    data: Omit<IdeaPersonalizationContent, 'idea_personalization_number'>,
    index: number,
    id: number,
    archetypeId: number,
  ) => {
    mutate(
      {
        payload: data,
        projectSlug: project.slug,
        id,
        archetypeId,
      },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Idea Personalization`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Idea Personalization  Click`,
            eventAction: 'Click',
            eventLabel: 'Idea Personalization',
            userId: user.email,
            data,
          });

          refreshData();
          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
        onError: (error) => {
          toast.error(error.message);
          refreshData();
        },
      },
    );
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ['ideaExpansion', project.slug],
      exact: true,
    });

    navigate(`/${project.slug}/idea-expansion`);
  };

  const hasSelectedData = (parseData: IdeaPersonalizationList[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedIdea = parseData
      .map((section, index) => {
        const hasSelectedIdea = section.idea_personalizations?.some(
          (ideaPersonalization) => ideaPersonalization.is_selected,
        );
        return hasSelectedIdea ? -1 : index;
      })
      .filter((index) => index !== -1);
    if (indicesWithoutSelectedIdea.length) {
      setErrorTabIndex(indicesWithoutSelectedIdea);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedIdea
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Idea Personalization for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateButton = (prompt: string) => {
    triggerGTMEvent({
      event: `Generate More Idea Personalization`,
      eventCategory: `Button Generate More Idea Personalization  Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Personalization',
      userId: user.email,
      data: { slug: project.slug, prompt },
    });

    setParsedData([]);
    setErrorTabIndex([]);
    setIsGenerated(true);
    dispatch(saveSubInformation(``));
    handleGenerateIdeaPersonalization()(project.slug, prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const handleRegenerateNextProcess = async () => {
    setIsReGenerating(true);
    dispatch(saveSubInformation(''));
    handleGenerateIdeaExpansion()(project.slug, null);
    setIsReGenerating(false);
    navigate(`/${project.slug}/idea-expansion`);
  };

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (selectedIndex < parsedData.length - 1) {
      const nextIndex = selectedIndex + 1;
      setSelectedIndex(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1;
      setSelectedIndex(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/idea-alignment`);
        },
      }),
    );
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedIndex]);

  const countSelectedData = (data: IdeaPersonalizationList[]) => {
    const isArchetypeAlreadySelected = data.every((item) =>
      item.idea_personalizations.some(
        (personalization) => personalization.is_selected,
      ),
    );

    let newIsInactive =
      !isIdeaPersonalization.status ||
      isIdeaPersonalization.isQuerying ||
      isGenerated
        ? true
        : !isArchetypeAlreadySelected;

    if (isAdding || isEditing) {
      newIsInactive = true;
    }

    if (!isEditor) {
      newIsInactive =
        !!(
          isIdeaExpansion.status &&
          differentArchetypes?.is_different_archetype.idea_expansions
        ) ||
        !isIdeaExpansion.status ||
        isIdeaExpansion.isQuerying;
    }

    setRouteOptionValue((oldValue) => ({
      ...oldValue,
      isInactive: newIsInactive,
      onClick: () => {
        if (
          !isIdeaPersonalization.status ||
          isIdeaPersonalization.isQuerying ||
          isGenerated
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, idea personalization is still generating`,
            ),
          );
          return;
        }

        if (isEditor) {
          if (isIdeaExpansion.isQuerying) {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, idea expansion is still generating`,
              ),
            );

            setTimeout(() => {
              dispatch(saveSubInformation(''));
            }, 3000);
            return;
          }

          if (isAdding || isEditing) {
            dispatch(
              saveSubInformation(`{text-error-redx} Please do save first`),
            );

            setTimeout(() => {
              dispatch(saveSubInformation(''));
            }, 3000);
            return;
          }

          const hasError = hasSelectedData(parsedData);

          if (!hasError) {
            if (isIdeaExpansion.isRegenerated) {
              setShowRegenerateModal(true);
              return;
            }

            if (routeOptionValue.isGenerate) {
              handleRefetchSelectedData();
              return;
            }

            navigate(`/${project.slug}/idea-expansion`);
          }
        } else {
          if (
            !isIdeaExpansion.status ||
            (isIdeaExpansion.status &&
              differentArchetypes?.is_different_archetype.idea_expansions)
          ) {
            setShowViewInfoModal(true);
            return;
          }

          navigate(`/${project.slug}/idea-expansion`);
        }
      },
    }));
  };

  useEffect(() => {
    const totalSelected = data?.data?.total_selected || 0;
    const totalData = data?.data?.total_data || 0;

    setTotalData(totalData);
    setTotalSelected(totalSelected);

    if (data?.data) {
      const {
        lists,
        sources,
        total_regenerate: totalRegenerate,
        is_querying: isQuerying,
      } = data.data;

      if (isQuerying) {
        if (lists?.length === 0) {
          setParsedData([]);
          setSources([]);
          setTotalRegenerate(0);
        }

        setIsGenerated(true);

        setRouteOptionValue((oldValue) => ({
          ...oldValue,
          onClick: () => {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, idea personalization is still generating`,
              ),
            );
          },
        }));

        return;
      }

      setParsedData(lists ?? []);
      setTotalRegenerate(totalRegenerate ?? 0);
      setSources(sources ?? []);
      setIsGenerated(false);

      dispatch(saveSubInformation(''));
    }
  }, [
    data,
    isIdeaPersonalization,
    differentArchetypes?.is_different_archetype.idea_personalizations,
  ]);

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totalSelected} Idea Personalization in ${totalData} Archetype is selected`,
      ),
    );
  }, [totalData, totalSelected]);

  useEffect(() => {
    if (!isIdeaPersonalization.status) {
      dispatch(
        saveInformation(`0 Idea Personalization in 0 Archetype is selected`),
      );
    }

    if (isIdeaPersonalization.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isIdeaPersonalization, parsedData, isePendingRefetch, isPendingHistory]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.idea_personalizations
    ) {
      if (
        !isGenerated &&
        !isPendingHistory &&
        !isIdeaPersonalization.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        dispatch(saveSubInformation(''));
        triggerGTMEvent({
          event: `Generate Idea Personalization`,
          eventCategory: `Button Generate Idea Personalization  Click`,
          eventAction: 'Click',
          eventLabel: 'Idea Personalization',
          userId: user.email,
          data: { slug: project.slug },
        });
        handleGenerateIdeaPersonalization()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isIdeaPersonalization,
    isPendingHistory,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    countSelectedData(parsedData);
    if (!parsedData.length) return;

    setSelectedIdeaIndex([]);
    const activeArchetype = parsedData[selectedIndex];
    const selectedDataWithIndex = activeArchetype?.idea_personalizations
      ?.map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIdeaIndex(indices);

      if (errorTabIndex?.length) {
        const updatedErrorTabIndex =
          errorTabIndex.filter((tabIndex) => tabIndex !== selectedIndex) ?? [];

        const namesWithoutSelectedKeyInsight = updatedErrorTabIndex
          .map((index) => `Archetype ${index + 1}`)
          .join(', ');

        dispatch(saveSubInformation(''));

        namesWithoutSelectedKeyInsight &&
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please select Idea Personalization for ${namesWithoutSelectedKeyInsight}`,
            ),
          );

        setErrorTabIndex(updatedErrorTabIndex);
      }
    }
  }, [
    selectedIndex,
    parsedData,
    isIdeaPersonalization,
    isIdeaExpansion,
    isIdeaPersonalization.isQuerying,
    routeOptionValue.isGenerate,
    differentArchetypes?.is_different_archetype.idea_expansions,
    isAdding,
    isEditing,
    isGenerated,
  ]);

  useEffect(() => {
    if (!isEditor) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: 'Next to idea Expansion',
        isGenerate: false,
        isDisabled: isIdeaPersonalization.isQuerying,
      }));
      return;
    }

    if (
      isIdeaExpansion.status &&
      !isIdeaExpansion.isRegenerated &&
      !differentArchetypes?.is_different_archetype.idea_expansions
    ) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: 'Next to idea Expansion',
        isGenerate: false,
        isDisabled: isIdeaPersonalization.isQuerying,
      }));
    }

    if (!isIdeaExpansion.status && !isIdeaExpansion.isRegenerated) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: 'Generate idea Expansion',
        isGenerate: true,
        isDisabled: isIdeaPersonalization.isQuerying,
      }));
    }

    if (
      isIdeaExpansion.status &&
      (isIdeaExpansion.isRegenerated ||
        differentArchetypes?.is_different_archetype.idea_expansions)
    ) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: 'Regenerate idea Expansion',
        isGenerate: true,
        isDisabled: isIdeaPersonalization.isQuerying,
      }));
    }
  }, [
    isIdeaExpansion,
    isEditor,
    isGenerated,
    isIdeaPersonalization.isQuerying,
  ]);

  useEffect(() => {
    if (
      differentArchetypes?.is_different_archetype.idea_personalizations ||
      parsedData.length === 0 ||
      isIdeaPersonalization.isQuerying
    ) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [
    isAdding,
    isEditing,
    differentArchetypes?.is_different_archetype.idea_personalizations,
    parsedData,
    isIdeaPersonalization.isQuerying,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
  }, [routeOptionValue]);

  useEffect(() => {
    !isIdeaPersonalization.isQuerying && !isPendingHistory && refetch();
    isIdeaPersonalization.isQuerying &&
      !isPendingHistory &&
      setIsGenerated(true);
  }, [
    isGenerated,
    isIdeaPersonalization,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.idea_personalizations,
  ]);

  const sectionList: SectionList = {
    title: 'Idea Personalization',
    value: 'personalization',
    section: 'idea',
  };

  const handleTabClicked = (
    title: string,
    ideaPersonalizations: IdeaPersonalization[],
  ) => {
    triggerGTMEvent({
      event: `Tab Idea Personalization`,
      eventCategory: `Tab Idea Personalization Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Personalization',
      userId: user.email,
      data: { title, ideaPersonalizations },
    });
  };

  return (
    <>
      <title title="Idea Personalization " />
      {isGenerated ? (
        <GenerateLoading
          estimateTimeFrom={2}
          estimateTimeTo={6}
          progress={progressPortion({
            progress: Number(
              (state.ideaPersonalization.progress / 2).toFixed(0),
            ),
            isQuerying: isIdeaPersonalization.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isIdeaPersonalization.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex items-center justify-between bg-white py-16">
            <div className="flex w-2/3 flex-col">
              <div className="mb-8 inline-flex items-center gap-12">
                <h1 className="text-25 font-bold leading-none">
                  Idea Personalization
                </h1>

                <SectionInformation
                  description="Ainstein will tailor ideas according to the chosen archetype and the alignment of previous idea, ensuring they fit seamlessly with your strategic framework."
                  title="What did Ainstein do with Idea Personalization?"
                />
              </div>
              <span className="text-15 font-normal text-grey-redx">
                Please{' '}
                <span className="font-bold text-black-redx">
                  select one idea for each archetype
                </span>
                , edit, or add by clicking &apos;Add Idea Manually&apos;.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="idea-personalization"
              />
            )}
          </div>

          <TabGroup
            className="pb-80"
            onChange={setSelectedIndex}
            selectedIndex={selectedIndex}
          >
            <div className="relative flex items-center">
              {!isLoading &&
                !isIdeaPersonalization.isQuerying &&
                !isRefetching &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={selectedIndex === 0}
                    onClick={handlePrev}
                  >
                    <Icon className="text-25" icon="mingcute:left-line" />
                  </button>
                )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isLoading &&
                !isIdeaPersonalization.isQuerying &&
                !isRefetching
                  ? parsedData.map((section, index) => (
                      <Tab
                        key={index}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                        disabled={isEditing || isAdding}
                        onClick={() =>
                          handleTabClicked(
                            section.archetype_content.archetype_name,
                            section.idea_personalizations,
                          )
                        }
                      >
                        <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                          <div className="flex flex-col gap-8">
                            {`Archetype ${index + 1}`}
                            <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              className="text-25 text-error-redx"
                              icon="mingcute:information-line"
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                      >
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isIdeaPersonalization.isQuerying &&
                !isRefetching &&
                parsedData.length > 3 && (
                  <button
                    className="p-2 text-gray-500 hover:text-black"
                    disabled={selectedIndex === parsedData.length - 1}
                    onClick={handleNext}
                  >
                    <Icon className="text-25" icon="mingcute:right-line" />
                  </button>
                )}
            </div>
            {!isLoading ? (
              <TabPanels className="py-24">
                {parsedData.map((section, index) => (
                  <TabPanel key={index}>
                    <div key={index} className="pt-5">
                      <SelectedCard
                        data={[
                          {
                            title: 'Archetype Name',
                            content: section.archetype_content.archetype_name,
                          },
                          {
                            title: 'Demographic',
                            content: section.archetype_content.demographics,
                          },
                          {
                            title: 'Occupation',
                            content: section.archetype_content.occupation,
                          },
                          {
                            title: 'Lifestyle',
                            content: section.archetype_content.lifestyle,
                          },
                          {
                            title: 'Behavior',
                            content: section.archetype_content.behavior,
                          },
                          {
                            title: 'Need & Challenges',
                            content:
                              section.archetype_content.needs_and_challenges,
                          },
                          {
                            title: 'Potential Buying Motives',
                            content:
                              section.archetype_content
                                .potential_buying_motives,
                          },
                          {
                            title: 'Current Trends',
                            content: section.archetype_content.current_trends,
                          },
                          {
                            title: 'Source of Information',
                            content:
                              section.archetype_content.source_of_information,
                          },
                          {
                            title: 'Online Search Behavior',
                            content:
                              section.archetype_content.online_search_behavior,
                          },
                          {
                            title: 'Purchase Frequency',
                            content:
                              section.archetype_content.purchase_frequency,
                          },
                          {
                            title: 'Preferred Sales Channels',
                            content:
                              section.archetype_content
                                .preferred_sales_channels,
                          },
                        ]}
                        title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                      />
                      <div className="mt-20" />
                      <SliderContainer
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        isEditing={isEditing}
                        length={section.idea_personalizations?.length}
                        onActiveIndex={(index) => {
                          isAdding ? setActiveIndex(0) : setActiveIndex(index);
                        }}
                        selectedIndexes={selectedIdeaIndex}
                      >
                        {section.idea_personalizations?.map((item, index) => (
                          <Card
                            key={index}
                            ref={(el) => (cardRefs.current[index] = el)}
                            archetypeId={section.id}
                            index={index}
                            isCanEdit={isEditor}
                            isEditing={isEditing}
                            isFetching={isRefetching}
                            item={item}
                            onSaveEditedData={handleSaveEditedData}
                            onSuccessSelected={() => {
                              refetch();
                              // todo :  recheck req payload to show alert if there is any changed setShowAlertGenerateModal(true)
                            }}
                            totalItem={
                              section.idea_personalizations?.length ?? 0
                            }
                            user={user}
                          />
                        ))}
                        {isAdding && (
                          <Card
                            ref={(el) => (cardRefs.current[0] = el)}
                            archetypeId={section.id}
                            index={section.idea_personalizations?.length}
                            isAdding={isAdding}
                            isCanEdit={isEditor}
                            onSaveEditedData={handleSaveEditedData}
                            totalItem={
                              (section.idea_personalizations?.length ?? 0) + 1
                            }
                            user={user}
                          />
                        )}
                      </SliderContainer>
                      <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                              onClick={toggleIsAdding}
                            >
                              Add Idea Personalization manually
                              <Icon className="size-20" icon="lucide:plus" />
                            </button>
                          ) : (
                            <div />
                          ))}
                        <div className="flex items-center justify-end gap-x-15">
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <>
                                <button
                                  className={`inline-flex cursor-pointer items-center gap-x-4 ${
                                    isSubmitting
                                      ? 'text-gray-redx'
                                      : 'text-red-500'
                                  }`}
                                  disabled={isSubmitting}
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }
                                >
                                  <span className="text-15 font-semibold">
                                    Cancel
                                  </span>
                                  <Icon className="size-20" icon="lucide:x" />
                                </button>
                                <button
                                  className={`inline-flex cursor-pointer items-center gap-x-4 ${
                                    isSubmitting
                                      ? 'text-gray-redx'
                                      : 'text-blue-redx'
                                  }`}
                                  disabled={isSubmitting}
                                  id="btn-edit-idea-personalization"
                                  onClick={handleSubmit}
                                >
                                  <span className="text-15 font-semibold">
                                    Save
                                  </span>
                                  <Icon
                                    className="size-20"
                                    icon="lucide:save"
                                  />
                                </button>
                              </>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className="flex w-full pb-60">
                        {sources && <SourcesList sources={sources} />}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className="block">
                <div className="h-55 w-full animate-pulse rounded-8 bg-soft-purple-redx" />
                <div className="mt-20" />
                <SliderContainer
                  goToIndex={goToIndex}
                  isAdding={isAdding}
                  isEditing={isEditing}
                  length={1}
                  onActiveIndex={undefined}
                >
                  <Card
                    ref={(el) => (cardRefs.current[-1] = el)}
                    index={-1}
                    isEditing={isAdding}
                    isFetching
                    totalItem={0}
                    user={user}
                  />
                </SliderContainer>
                <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                  {isEditor &&
                    (!isAdding && !isEditing ? (
                      <button
                        className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                        onClick={undefined}
                      >
                        Add Idea Personalization Manually
                        <Icon className="size-20" icon="lucide:plus" />
                      </button>
                    ) : (
                      <div />
                    ))}
                  <div className="flex items-center justify-end gap-x-15">
                    {isEditor &&
                      (isEditing || isAdding ? (
                        <>
                          <button
                            className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">
                              Cancel
                            </span>
                            <Icon className="size-20" icon="lucide:x" />
                          </button>
                          <button
                            className={`inline-flex items-center gap-x-4 ${
                              false ? 'text-gray-600' : 'text-blue-redx'
                            } cursor-pointer`}
                            disabled={false}
                            id="btn-edit-idea-personalization"
                            onClick={undefined}
                          >
                            <span className="text-15 font-semibold">Save</span>
                            <Icon className="size-20" icon="lucide:save" />
                          </button>
                        </>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className="flex w-full pb-60">
                  <div className="inline-flex gap-10">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="idea-personalization"
      />

      <RegenerateModal
        handleSubmit={handleRegenerateNextProcess}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="idea-personalization"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
