import { useEffect } from 'react';

interface SaveProgressParams {
  activeArchetype: { id: number } | null;
  progressStates: Record<number, number>;
  projectSlug: string;
  section: string;
  saveFunction: (
    slug: string,
    section: string,
    archetypeId: number,
    progress: number,
  ) => void;
}

export const useSaveProgressToLocalStorage = ({
  activeArchetype,
  progressStates,
  projectSlug,
  section,
  saveFunction,
}: SaveProgressParams) => {
  useEffect(() => {
    if (!activeArchetype) return;

    const activeArchetypeId = activeArchetype?.id;

    if (
      activeArchetypeId &&
      progressStates[activeArchetypeId] !== undefined // Only save if progressStates value exists
    ) {
      saveFunction(
        projectSlug,
        section,
        activeArchetypeId,
        progressStates[activeArchetypeId],
      );
    }
  }, [activeArchetype, progressStates, projectSlug, section, saveFunction]);
};
