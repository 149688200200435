import Wrapper from '@/Pages/SearchabilityContent/Wrapper';

const SearchabilityContentRoutes = [
  {
    path: '/:project_slug/searchability-content',
    element: <Wrapper />,
  },
];

export default SearchabilityContentRoutes;
