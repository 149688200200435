import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import ProjectMembers from '@/Components/ProjectMembers';
import useApprovedUsers from '@/Hooks/react-query/useApprovedUsers';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { User } from '@/Types';
import type { ProjectProps } from '@/Types/projects';

const ProjectHead: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const { data: user } = useUser();
  const [initialUsers, setInitialUsers] = useState<User[]>([]);
  const { Badge } = useRolesBadge(project, user);
  const { data, isLoading } = useApprovedUsers();

  useEffect(() => {
    if (data) {
      setInitialUsers(data.users);
    }
  }, [data]);

  return (
    <div className="flex w-full flex-col border-b-2 border-gray-200 pb-24">
      <div className="mb-10 flex w-full items-center text-32 font-bold">
        <h1>{project.name}</h1>
      </div>
      {!isLoading && (
        <div className="flex w-full items-center gap-x-30">
          <ProjectMembers initialUsers={initialUsers} project={project} />
          <div className="inline-flex gap-x-8 text-grey-redx">
            <Icon className="size-24" icon="uil:clock" />
            <p>{moment(project.updated_at).format('DD MMMM YYYY - HH:mm')}</p>
          </div>
          <Badge />
        </div>
      )}
    </div>
  );
};

export default ProjectHead;
