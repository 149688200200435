import React, { useContext } from 'react';

import { ProjectContext } from '@/Context/ProjectContext';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { mockUserData } from '@/Mocks/usersMocks';
import type { ProjectProps } from '@/Types/projects';
import { formatInformation } from '@/Utils/helper';

import PencilGenerate from '../Icon/pencil-generate';

const ProjectFooter: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [state] = useContext(ProjectContext);
  const user = mockUserData;
  const { roles } = useRolesBadge(project, user);
  const isViewOnly = roles.includes('View Only');

  const formattedInformation = formatInformation(state.information);
  const formattedSubInformation = formatInformation(state.subInformation ?? '');

  const buttonNextGradient = state.routeNext.isGenerate
    ? 'bg-button-gradient-90 bg-200% animate-gradient-animation'
    : 'bg-blue-redx border-blue-redx';

  const buttonNextDisabledClass =
    state.routeNext.isDisabled || state.routeNext.isInactive
      ? `bg-placeholder-redx border-placeholder-redx ${
          state.routeNext.isDisabled && 'cursor-not-allowed'
        } text-white`
      : `${buttonNextGradient} cursor-pointer`;

  return (
    !isViewOnly &&
    (state.routeNext.isActive || state.routePrev.isActive) && (
      <div
        className="fixed inset-x-0 bottom-0 z-20 bg-white py-10 pl-20 pr-30 shadow-lg lg:pl-[26%]"
        style={{
          boxShadow: '0px -4px 20px 0px #0000001F',
        }}
      >
        <div className="flex w-full flex-col items-center justify-between gap-8 lg:flex-row">
          <div className="w-full flex-1">
            <p className={formattedInformation.className}>
              {formattedInformation.content}
            </p>
            <p className={formattedSubInformation.className}>
              {formattedSubInformation.content}
            </p>
          </div>
          <div className="flex w-full flex-1 flex-row justify-end gap-10 gap-x-24 md:flex-col">
            {state.routePrev.isActive && (
              <button
                className="w-fit cursor-pointer whitespace-nowrap rounded-lg border-2 border-blue-redx bg-transparent px-24 py-12 text-20 font-semibold leading-18 text-blue-redx"
                id={
                  state.routePrev?.id ??
                  `btn-${state.routePrev.label.toLowerCase().replace(/\s+/g, '-')}`
                }
                onClick={state.routePrev.onClick}
              >
                {state.routePrev.label}
              </button>
            )}
            {state.routeNext.isActive && (
              <button
                className={`flex flex-row items-center justify-center gap-8 whitespace-nowrap rounded-lg border-2 px-24 py-12 text-20 font-semibold text-white md:whitespace-break-spaces ${buttonNextDisabledClass}`}
                data-button="button-cta"
                disabled={state.routeNext.isDisabled ?? false}
                id={
                  state.routeNext?.id ??
                  `btn-${state.routeNext.label.toLowerCase().replace(/\s+/g, '-')}`
                }
                onClick={state.routeNext.onClick}
              >
                {state.routeNext.label}
                {state.routeNext.isGenerate && <PencilGenerate />}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ProjectFooter;
