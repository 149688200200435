import React, { createContext, useContext, useMemo, useState } from 'react';

interface ModalState {
  section: string;
  show: boolean;
  setModalState: (state: { section: string; show: boolean }) => void;
}

const ModalRegenerateContext = createContext<ModalState | undefined>(undefined);

export const ModalRegenerateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [modalState, setModalState] = useState<{
    section: string;
    show: boolean;
  }>({
    section: '',
    show: true, // Default to true
  });

  const value = useMemo(
    () => ({
      ...modalState,
      setModalState,
    }),
    [modalState], // Only re-compute if modalState changes
  );

  return (
    <ModalRegenerateContext.Provider value={value}>
      {children}
    </ModalRegenerateContext.Provider>
  );
};

export const useModalRegenerateContext = () => {
  const context = useContext(ModalRegenerateContext);
  if (!context) {
    throw new Error(
      'useModalRegenerateContext must be used within a ModalRegenerateProvider',
    );
  }
  return context;
};
