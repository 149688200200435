import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ChallengeSchema } from '@/Types/challenge_and_task/schema';

const updateChallengeAndTask = async ({
  payload,
  id,
  archetypeId,
  projectSlug,
}: {
  payload: ChallengeSchema;
  id: number;
  archetypeId: number;
  projectSlug: string;
}) => {
  try {
    const url = `/projects/${projectSlug}/challenge-and-task/submit/${id}`;

    const { data } = await goFetcher.post(url, {
      content: payload,
      archetypeId,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateChallengeAndTask = () => {
  return useMutation<
    any,
    Error,
    {
      payload: ChallengeSchema;
      id: number;
      archetypeId: number;
      projectSlug: string;
    }
  >({
    mutationFn: updateChallengeAndTask,
  });
};

export { useUpdateChallengeAndTask };
