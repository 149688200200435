import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { KeyInsightUpdateSelectedResponse } from '@/Types/key_insight';

const updateSelectedKeyInsight = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  const url = `/projects/${projectSlug}/key-insight/update-selected/${id}`;

  try {
    const response = await goFetcher.put(url, {});
    return response.data as KeyInsightUpdateSelectedResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedKeyInsight = () => {
  return useMutation<
    KeyInsightUpdateSelectedResponse,
    Error,
    { id: number; projectSlug: string }
  >({
    mutationFn: updateSelectedKeyInsight,
  });
};

export { useUpdateSelectedKeyInsight };
