/* eslint-disable no-useless-catch */
export const stringToArray = (variable: string | string[]) => {
  return typeof variable === 'string' ? variable.split(',') : variable;
};

export const jsonPurify = (jsonString: string) => {
  try {
    // Trim leading and trailing whitespaces from the JSON string
    const trimmedString = jsonString.trim();

    // Determine if the JSON is an object or an array
    const isArray = trimmedString.startsWith('[');
    const isObject = trimmedString.startsWith('{');

    if (!isArray && !isObject) {
      throw new Error('Invalid JSON string');
    }

    // Remove potential trailing commas in JSON objects and arrays
    const sanitizedString = isArray
      ? /\[.*\]/s.exec(jsonString)
      : /\{.*\}/s.exec(jsonString);

    if (sanitizedString?.[0]) {
      return JSON.parse(sanitizedString[0]);
    }
    throw new Error('Invalid JSON string');
  } catch (error) {
    throw error;
  }
};

export const capitalizeWords = (text: string): string => {
  return text
    .toLowerCase() // Pastikan semua huruf kecil dulu
    .split(' ') // Pisahkan berdasarkan spasi
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Kapitalisasi huruf pertama tiap kata
    .join(' '); // Gabungkan kembali menjadi string
};
